import * as React from "react";
import "./Step2.css";
import { Field } from "@progress/kendo-react-form";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import {
  getVehicleByPlate,
  getVehicleByReference,
} from "../../../../../Utils/getVehicle";
import { VehicleSelect } from "../../../../Cards/VehicleSelect";
import RingLoader from "react-spinners/RingLoader";
import { ContextData } from "../../../../../Context/Context";

function Step(props) {
  const { formState, setFormState } = props;
  const [vehicles, setVehicles] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const { context } = React.useContext(ContextData);

  React.useEffect(() => {
    !formState.in_agency &&
      context.searchByReference === undefined &&
      getVehicleByPlate(formState.plate)
        .then((vehicles) => {
          vehicles = Array.isArray(vehicles) ? vehicles : [];
          setVehicles(vehicles);
          setIsLoading(false);
          setFormState({
            ...formState,
            plate_type: formState.plate_type || "",
            price_vehicle: formState.price_vehicle || "",
            use_type: formState.use_type || "",
          });
        })
        .catch((e) => {
          console.log(e);
          setIsError(true);
        });

    formState.in_agency &&
      context.searchByReference === undefined &&
      getVehicleByReference(
        formState.brand,
        formState.model,
        formState.line,
        formState.in_agency
      )
        .then((vehicles) => {
          vehicles = Array.isArray(vehicles) ? vehicles : [];
          setVehicles(vehicles);
          setIsLoading(false);
          setFormState({
            ...formState,
            plate_type: formState.plate_type || "",
            price_vehicle: formState.price_vehicle || "",
            use_type: formState.use_type || "",
          });
        })
        .catch((e) => {
          console.log(e);
          setIsError(true);
        });

    formState.in_agency &&
      context.searchByReference === true &&
      getVehicleByReference(
        formState.brand,
        formState.model,
        formState.line,
        formState.in_agency
      )
        .then((vehicles) => {
          vehicles = Array.isArray(vehicles) ? vehicles : [];
          setVehicles(vehicles);
          setIsLoading(false);
          setFormState({
            ...formState,
            plate_type: formState.plate_type || "",
            price_vehicle: formState.price_vehicle || "",
            use_type: formState.use_type || "",
          });
        })
        .catch((e) => {
          console.log(e);
          setIsError(true);
        });

    context.searchByReference &&
      !formState.in_agency &&
      getVehicleByPlate(formState.plate)
        .then((vehiclesPlate) => {
          getVehicleByReference(
            formState.brand,
            formState.model,
            formState.line,
            formState.in_agency
          ).then((vehiclesReference) => {
            vehiclesPlate = Array.isArray(vehiclesPlate) ? vehiclesPlate : [];
            vehiclesReference = Array.isArray(vehiclesReference)
              ? vehiclesReference
              : [];
            setVehicles([...vehiclesPlate, ...vehiclesReference]);
            setIsLoading(false);
            setFormState({
              ...formState,
              plate_type: formState.plate_type || "",
              price_vehicle: formState.price_vehicle || "",
              use_type: formState.use_type || "",
            });
          });
        })
        .catch((e) => {
          console.log(e);
          setIsError(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <h3 className="text-center">Selecciona tu vehículo</h3>
      </Row>
      {isLoading ? (
        <Row className="justify-content-center mt-3" id="LoadingQuoteMsg">
          <RingLoader color="var(--company-color)"></RingLoader>
        </Row>
      ) : isError ? (
        <Row className="justify-content-center mt-3">
          <Alert variant="danger" className="text-center" id="LoadingErrorMsg">
            <span
              style={{ fontSize: "32px" }}
              class="k-icon k-i-information"
            ></span>
            <span
              style={{ fontWeight: "500", display: "block", fontSize: "1.3em" }}
            >
              ¡Oops!
            </span>
            <span>
              Parece que ha ocurrido un error. Puedes informar a soporte técnico
            </span>
          </Alert>
        </Row>
      ) : (
        <Field
          key={"vehicle"}
          name={"vehicle"}
          onPrevStep={props.onPrevStep}
          component={VehicleSelect}
          vehicles={vehicles}
          onNextStep={props.onNextStep}
          formState={formState}
        />
      )}
    </Container>
  );
}

const defualtData = {};

const config = {
  showPrevButton: true,
  showNextButton: false,
};

const header = {
  label: "Elegir",
  icon: "k-i-search",
  isValid: undefined,
};

const PropertiesStep2 = {
  component: Step,
  header: header,
  config: config,
  defualtData: defualtData,
};

export { PropertiesStep2 };
