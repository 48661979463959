import * as React from "react";
import {
  FieldWrapper,
  Field,
  FieldArray,
  Form,
} from "@progress/kendo-react-form";
import { default as FormBootstrap } from "react-bootstrap/Form";
import {
  Input,
  MaskedTextBox,
  NumericTextBox,
  Checkbox,
  ColorPicker,
  Switch,
  RadioGroup,
  Slider,
  SliderLabel,
  RangeSlider,
  TextArea,
  Rating,
} from "@progress/kendo-react-inputs";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  DateRangePicker,
  DateInput,
} from "@progress/kendo-react-dateinputs";
import {
  Label,
  Error,
  Hint,
  FloatingLabel,
} from "@progress/kendo-react-labels";
import { Upload } from "@progress/kendo-react-upload";
import {
  DropDownList,
  AutoComplete,
  MultiSelect,
  ComboBox,
  MultiColumnComboBox,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import { processTreeData, expandedState } from "./treeDataOperations";
import CitiesRes from "./staticCities";
import { Col, Row } from "react-bootstrap";
import { ComponentsConfig } from "./componentsConfig";
import { getCities } from "../cities";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { FetchInfo } from "../FetchInfo";
import jsonata from "jsonata";

import {
  IntlProvider,
  load,
  loadMessages,
  LocalizationProvider,
} from "@progress/kendo-react-intl";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/es/numbers.json";
import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/es/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/es/timeZoneNames.json";
import { citiesParser } from "../Parsers/citiesParser";
import { FORM_ORIGIN } from "../variables";
load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  caGregorian,
  dateFields,
  timeZoneNames
);
const esMessages = require("./es.json");
loadMessages(esMessages, "es-ES");

const sources = {
  ubications: getCities,
};

export const FormInput = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    visited,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    ...others
  } = fieldRenderProps;

  React.useEffect(() => {
    fieldRenderProps.defaultValue &&
      fieldRenderProps.onChange({
        target: { value: fieldRenderProps.defaultValue },
      });
  }, []);
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  return (
    <Col>
      <FieldWrapper>
        <Label
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          {label}
        </Label>
        <div className={"k-form-field-wrap"}>
          <Input
            valid={valid}
            type={type}
            id={id}
            disabled={disabled}
            ariaDescribedBy={`${hintId} ${errorId}`}
            {...others}
          />
          {showHint && <Hint id={hintId}>{hint}</Hint>}
          {showValidationMessage && (
            <Error id={errorId}>{validationMessage}</Error>
          )}
        </div>
      </FieldWrapper>
    </Col>
  );
};
export const FormRadioGroup = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    id,
    label,
    valid,
    disabled,
    hint,
    visited,
    modified,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  return (
    <Col>
      <FieldWrapper>
        <Label
          id={labelId}
          editorRef={editorRef}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
        >
          {label}
        </Label>
        <RadioGroup
          dataItemKey="id"
          textField="text"
          ariaDescribedBy={`${hintId} ${errorId}`}
          ariaLabelledBy={labelId}
          valid={valid}
          disabled={disabled}
          ref={editorRef}
          {...others}
          data={others.data}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </FieldWrapper>
    </Col>
  );
};
export const FormNumericTextBox = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    visited,
    hint,
    ...others
  } = fieldRenderProps;

  React.useEffect(() => {
    fieldRenderProps.defaultValue &&
      fieldRenderProps.onChange({
        target: { value: fieldRenderProps.defaultValue },
      });
  }, []);

  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  return (
    <Col>
      <FieldWrapper>
        <Label editorId={id} editorValid={valid} editorDisabled={disabled}>
          {label}
        </Label>
        <NumericTextBox
          ariaDescribedBy={`${hintId} ${errorId}`}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </FieldWrapper>
    </Col>
  );
};

export const FormNumericTextBoxString = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    visited,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    ...others
  } = fieldRenderProps;

  React.useEffect(() => {
    fieldRenderProps.defaultValue &&
      fieldRenderProps.onChange({
        target: { value: fieldRenderProps.defaultValue },
      });
  }, []);
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  const onChange = (e) => {
    const re = /^[0-9]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      fieldRenderProps.onChange({ target: { value: e.target.value } });
    } else {
      if (e.target.value.length === 1) {
        fieldRenderProps.onChange({ target: { value: "" } });
      }
    }
  };
  return (
    <Col>
      <FieldWrapper>
        <Label
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          {label}
        </Label>
        <div className={"k-form-field-wrap"}>
          <Input
            {...others}
            valid={valid}
            type={type}
            id={id}
            disabled={disabled}
            ariaDescribedBy={`${hintId} ${errorId}`}
            onChange={onChange}
          />
          {showHint && <Hint id={hintId}>{hint}</Hint>}
          {showValidationMessage && (
            <Error id={errorId}>{validationMessage}</Error>
          )}
        </div>
      </FieldWrapper>
    </Col>
  );
};

export const FormPhoneInput = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    visited,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    ...others
  } = fieldRenderProps;

  React.useEffect(() => {
    fieldRenderProps.defaultValue &&
      fieldRenderProps.onChange({
        target: { value: fieldRenderProps.defaultValue },
      });
  }, []);
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  const onChange = (e) => {
    const re = /^[\+]?([0-9]+)?$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      fieldRenderProps.onChange({ target: { value: e.target.value } });
    } else {
      if (e.target.value.length === 1) {
        fieldRenderProps.onChange({ target: { value: "" } });
      }
    }
  };
  return (
    <Col>
      <FieldWrapper>
        <Label
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          {label}
        </Label>
        <div className={"k-form-field-wrap"}>
          <Input
            {...others}
            valid={valid}
            type={type}
            id={id}
            disabled={disabled}
            ariaDescribedBy={`${hintId} ${errorId}`}
            onChange={onChange}
          />
          {showHint && <Hint id={hintId}>{hint}</Hint>}
          {showValidationMessage && (
            <Error id={errorId}>{validationMessage}</Error>
          )}
        </div>
      </FieldWrapper>
    </Col>
  );
};

export function AgmUbication(fieldRenderProps) {
  const [initialState, setInitialState] = React.useState([]);
  const [list, setList] = React.useState([]);
  const [defaultValue, setDefaultValue] = React.useState(
    fieldRenderProps.value || ""
  );
  const [selected, setSelected] = React.useState(fieldRenderProps.value || "");

  const onFilterChange = (e) => {
    let filterer = initialState.filter((city) => {
      try {
        return city.city_name.toLowerCase().match(e.filter.value.toLowerCase());
      } catch {
        return false;
      }
    });
    setList(filterer);
  };

  React.useEffect(() => {
    if (fieldRenderProps.defaultValue) {
      const ubicationFound = list.find((ubication) => {
        return ubication.code == fieldRenderProps.defaultValue.code;
      });
      setDefaultValue(ubicationFound);
    }
  }, [list]);

  React.useEffect(() => {
    const res = citiesParser(CitiesRes);
    res.pop();
    setInitialState(res);
    setList(res);
  }, []);

  const itemRender = (li, itemProps) => {
    const index = itemProps.index;
    const itemChildren = (
      <span>
        {itemProps.dataItem.city_name} ({itemProps.dataItem.state_name})
      </span>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const onChange = (e) => {
    if (e.syntheticEvent?.type == "click" && !e.target.value) {
      setSelected("");
    } else {
      e.target.value && setSelected(e.target.value);
    }
  };

  React.useEffect(() => {
    fieldRenderProps.onChange({ target: { value: selected } });
  }, [selected]);

  const valueRender = (itemProps) => {
    if (selected && selected.city_name) {
      const value = selected ? selected : {};
      const props = {
        value: `${value.city_name} (${value.state_name})`,
        id: "place",
        role: "combobox",
        type: "text",
      };
      return React.cloneElement(itemProps, props);
    } else {
      return React.cloneElement(itemProps, itemProps.props);
    }
  };

  return (
    <FormComboBox
      {...fieldRenderProps}
      data={list}
      defaultValue={defaultValue}
      textField="city_name"
      value={selected}
      onChange={onChange}
      valueRender={valueRender}
      filterable={true}
      onFilterChange={onFilterChange}
      itemRender={itemRender}
    />
  );
}

// export function AgmUbication(fieldRenderProps) {
// 	const { data,validator } = fieldRenderProps;
// 	const [initialState, setInitialState] = React.useState([]);
// 	const [list, setList] = React.useState([]);
// 	const [defaultValue, setDefaultValue] = React.useState(null);

// 	const onFilterChange = (e) => {
// 		let filterer = initialState.filter(city => {
// 			return city.city_name.toLowerCase().match(e.filter.value.toLowerCase());
// 		})
// 		setList(filterer);
// 	}

// 	React.useEffect(() => {
// 		if (fieldRenderProps.defaultValue) {
// 			const ubicationFound = list.find((ubication) => {
// 				return ubication.code == fieldRenderProps.defaultValue.code
// 			});
// 			setDefaultValue(ubicationFound)
// 		}

// 	}, [list])

// 	React.useEffect(() => {
// 		sources.ubications().then((res) => {
// 			setInitialState(res);
// 			setList(res);
// 		})
// 	}, [])

// 	const itemRender = (li, itemProps) => {
// 		const index = itemProps.index;
// 		const itemChildren = <span>{itemProps.dataItem.city_name} ({itemProps.dataItem.state_name})</span>;

// 		return React.cloneElement(li, li.props, itemChildren);
// 	}

// 	const valueRender = (itemProps) => {
// 		if (selected && selected.city_name){
// 		  const value = selected ? selected : {}
// 		  const props = {
// 			value: `${value.city_name} (${value.state_name})`,
// 			id: "place",
// 			role: "combobox",
// 			type: "text"
// 		  }
// 		  return React.cloneElement(itemProps, props);
// 		} else {
// 		  return React.cloneElement(itemProps, itemProps.props);
// 		}
// 	  }

// 	return (
// 		<FormComboBox
// 			{...fieldRenderProps}
// 			data={list}
// 			defaultValue={defaultValue}
// 			textField="city_name"
// 			filterable={true}
// 			valueRender={valueRender}
// 			onFilterChange={onFilterChange}
// 			itemRender={itemRender}
// 		/>
// 	)
// }

export function FormArray(props) {
  const { config, uiSchema, schema } = props;

  const [formState, setFormState] = React.useState(props.formState);

  const getValue = (cadena) => {
    if (!cadena) return "";
    if (cadena[0] === "$") {
      let expression = jsonata(cadena);
      return expression.evaluate(formState);
    } else {
      return cadena;
    }
  };

  const { show, hide } = config;

  const fieldsJson = schema.items.properties;

  let fields = Object.keys(fieldsJson);

  if (show) {
    fields = Object.keys(show);
  }

  if (hide) {
    fields = fields.filter((field) => !show.includes(field));
  }

  return (
    <React.Fragment>
      <h6 className="mb-0 mt-3">{schema.title}</h6>
      {fields.map((field) => {
        if (uiSchema[field].component === "object") {
          return (
            <FormObject
              key={field}
              formState={formState}
              schema={fieldsJson[field]}
              config={{ show: show[field] }}
              uiSchema={uiSchema[field].properties} /* show={show} */
            />
          );
        }
        if (uiSchema[field].component === "array") {
          return (
            <FormArray
              key={field}
              formState={formState}
              schema={fieldsJson[field]}
              config={{ show: show[field] }}
              uiSchema={uiSchema[field].properties} /* show={show} */
            />
          );
        }
        let fieldName =
          show && typeof show[field] === "string" ? show[field] : field;
        return (
          <Field
            key={field}
            id={field}
            name={show[field]?.name || field}
            defaultValue={getValue(show[field]?.defaultValue)}
            readOnly={show[field]?.readOnly}
            label={show[field]?.label || fieldsJson[field].title}
            component={ComponentsConfig[uiSchema[field].component]}
            data={{
              enum: fieldsJson[field].enum,
              schema: fieldsJson[field],
              uiSchema: uiSchema[field],
            }}
          />
        );
      })}
    </React.Fragment>
  );
}

export function FormObject(props) {
  const { config, schema, uiSchema } = props;

  const [formState, setFormState] = React.useState(props.formState);

  const getValue = (cadena) => {
    if (!cadena) return "";
    if (cadena[0] === "$") {
      let expression = jsonata(cadena);
      return expression.evaluate(formState);
    } else {
      return cadena;
    }
  };

  const { show, hide } = config;

  const fieldsJson = schema.properties;

  let fields = Object.keys(fieldsJson);

  if (show) {
    fields = Object.keys(show);
  }

  if (hide) {
    fields = fields.filter((field) => !show.includes(field));
  }

  return (
    <React.Fragment>
      <h6 className="mb-0 mt-3">{schema.title}</h6>
      {fields.map((field) => {
        if (uiSchema[field].component === "object") {
          return (
            <FormObject
              key={field}
              formState={formState}
              schema={fieldsJson[field]}
              config={{ show: show[field] }}
              uiSchema={uiSchema[field].properties} /* show={show} */
            />
          );
        }
        if (uiSchema[field].component === "array") {
          return (
            <FormArray
              key={field}
              formState={formState}
              schema={fieldsJson[field]}
              config={{ show: show[field] }}
              uiSchema={uiSchema[field].properties} /* show={show} */
            />
          );
        }
        let fieldName =
          show && typeof show[field] === "string" ? show[field] : field;

        return (
          <Field
            key={field}
            id={field}
            name={show[field]?.name || field}
            defaultValue={getValue(show[field]?.defaultValue)}
            readOnly={show[field]?.readOnly}
            label={show[field]?.label || fieldsJson[field].title}
            component={ComponentsConfig[uiSchema[field].component]}
            data={{
              enum: fieldsJson[field].enum,
              schema: fieldsJson[field],
              uiSchema: uiSchema[field],
            }}
          />
        );
      })}
    </React.Fragment>
  );
}

export const FormCheckbox = (fieldRenderProps) => {
  //console.log(fieldRenderProps);
  const {
    validationMessage,
    touched,
    id,
    valid,
    disabled,
    hint,
    optional,
    label,
    modified,
    visited,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  React.useEffect(() => {
    fieldRenderProps.defaultValue &&
      fieldRenderProps.onChange({
        target: { value: fieldRenderProps.defaultValue },
      });
  }, []);

  return (
    <Col>
      <FieldWrapper>
        <Checkbox
          ariaDescribedBy={`${hintId} ${errorId}`}
          label={label}
          labelOptional={optional}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </FieldWrapper>
    </Col>
  );
};
export const FormSwitch = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    optional,
    id,
    valid,
    visited,
    disabled,
    hint,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  return (
    <Col xs={6}>
      <FieldWrapper>
        <Label
          id={labelId}
          editorRef={editorRef}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          {label}
        </Label>
        <Switch
          ref={editorRef}
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </FieldWrapper>
    </Col>
  );
};
export const FormMaskedTextBox = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    visited,
    valid,
    hint,
    optional,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid} optional={optional}>
        {label}
      </Label>
      <div className={"k-form-field-wrap"}>
        <MaskedTextBox
          ariaDescribedBy={`${hintId} ${errorId}`}
          valid={valid}
          id={id}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};
export const FormTextArea = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    visited,
    valid,
    hint,
    disabled,
    optional,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  return (
    <Col xs={6}>
      <FieldWrapper>
        <Label editorId={id} editorValid={valid} optional={optional}>
          {label}
        </Label>
        <TextArea
          valid={valid}
          id={id}
          disabled={disabled}
          ariaDescribedBy={`${hintId} ${errorId}`}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </FieldWrapper>
    </Col>
  );
};
export const FormColorPicker = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    visited,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <ColorPicker
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        id={id}
        disabled={disabled}
        {...others}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormSlider = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    data,
    visited,
    min,
    max,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  return (
    <FieldWrapper>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <Slider
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        id={id}
        disabled={disabled}
        min={min}
        max={max}
        {...others}
      >
        {data.map((value) => (
          <SliderLabel title={value} key={value} position={value}>
            {value.toString()}
          </SliderLabel>
        ))}
      </Slider>
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormRangeSlider = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    visited,
    id,
    valid,
    hint,
    disabled,
    data,
    min,
    max,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid}>
        {label}
      </Label>
      <RangeSlider
        valid={valid}
        id={id}
        disabled={disabled}
        ariaDescribedBy={`${hintId} ${errorId}`}
        min={min}
        max={max}
        {...others}
      >
        {data.map((value) => {
          return (
            <SliderLabel key={value} position={value}>
              {value.toString()}
            </SliderLabel>
          );
        })}
      </RangeSlider>
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormRating = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    visited,
    id,
    valid,
    hint,
    disabled,
    optional,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  return (
    <FieldWrapper>
      <Label editorId={id} editorValid={valid} optional={optional}>
        {label}
      </Label>
      <Rating
        valid={valid}
        id={id}
        disabled={disabled}
        ariaDescribedBy={`${hintId} ${errorId}`}
        {...others}
      />
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormUpload = (fieldRenderProps) => {
  const {
    value,
    id,
    visited,
    optional,
    label,
    hint,
    validationMessage,
    touched,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";

  const onChangeHandler = (event) => {
    fieldRenderProps.onChange({
      value: event.newState,
    });
  };

  const onRemoveHandler = (event) => {
    fieldRenderProps.onChange({
      value: event.newState,
    });
  };

  return (
    <FieldWrapper>
      <Label id={labelId} editorId={id} optional={optional}>
        {label}
      </Label>
      <Upload
        id={id}
        autoUpload={false}
        showActionButtons={false}
        multiple={false}
        files={value}
        onAdd={onChangeHandler}
        onRemove={onRemoveHandler}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ariaLabelledBy={labelId}
        {...others}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormDropDownList = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    visited,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;

  React.useEffect(() => {
    fieldRenderProps.defaultValue &&
      fieldRenderProps.onChange({
        target: { value: fieldRenderProps.defaultValue },
      });
  }, []);

  const editorRef = React.useRef(null);
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  return (
    <Col>
      <FieldWrapper style={wrapperStyle}>
        <Label
          id={labelId}
          editorRef={editorRef}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
        >
          {label}
        </Label>
        <DropDownList
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          dataItemKey="id"
          textField="text"
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={others.readOnly == "1" ? true : disabled}
          {...others}
          data={others.data}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </FieldWrapper>
    </Col>
  );
};

export const FormAutoComplete = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    visited,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  return (
    <Col xs={6}>
      <FieldWrapper style={wrapperStyle}>
        <Label
          id={labelId}
          editorRef={editorRef}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
        >
          {label}
        </Label>
        <AutoComplete
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </FieldWrapper>
    </Col>
  );
};

export const AgmReferenceFields = (fieldRenderProps) => {
  const urlFetch =
    "https://apipro.agentemotor.com/seguros/co/vehiculos/consultar/v2";

  const [initialBrands, setInitialBrands] = React.useState(["Auteco"]);
  const [brands, setBrands] = React.useState(["Auteco"]);

  const [initialModels, setInitialModels] = React.useState([]);
  const [models, setModels] = React.useState([]);

  const [initialLines, setInitialLines] = React.useState([]);
  const [lines, setLines] = React.useState([]);

  const [brand, setBrand] = React.useState(fieldRenderProps.values.brand || "");
  const [model, setModel] = React.useState(fieldRenderProps.values.model || "");

  const inAgency = fieldRenderProps.inAgency || false;

  const onFilterChangeLines = (e) => {
    let filterer = initialLines
      .filter((line) => {
        return line.toLowerCase().match(e.filter.value.toLowerCase());
      })
      .sort();
    setLines(filterer);
  };

  const onFilterChangeBrands = (e) => {
    let filterer = initialBrands
      .filter((brand) => {
        return brand.toLowerCase().match(e.filter.value.toLowerCase());
      })
      .sort();
    setBrands(filterer);
  };

  const onFilterChangeModels = (e) => {
    let filterer = initialModels.filter((model) => {
      return model.match(e.filter.value);
    });
    setModels(filterer);
  };

  React.useEffect(() => {
    const date = new Date();
    const modelsLength = inAgency ? 3 : 20;
    setModels(
      [...Array(modelsLength).keys()].map((index) =>
        (date.getFullYear() + 1 - index).toString()
      )
    );
    setInitialModels(
      [...Array(modelsLength).keys()].map((index) =>
        (date.getFullYear() + 1 - index).toString()
      )
    );
  }, [inAgency]);

  React.useEffect(() => {
    FetchInfo(urlFetch, {
      name: "event-insurable-get-list-brands",
      data: { vehicle: { code: "0" } },
      timestamp: "30072020",
      origin: FORM_ORIGIN,
    }).then((response) => {
      setInitialBrands(response.sort());
      setBrands(response.sort());
    });
  }, []);

  const handleBrand = (event) => {
    //verify if event is of type pointer
    if (event.syntheticEvent._reactName === "onClick") {
      setBrand(event.target.value);
    }

    if (event.syntheticEvent._reactName === "onKeyDown" && event.target.value) {
      setBrand(event.target.value);
    }
    if (event.syntheticEvent._reactName === "onBlur") {
      setBrand(event.target.value);
    }
  };

  const handleModel = (event) => {
    if (event.syntheticEvent._reactName === "onClick") {
      setModel(event.target.value);
    }
    if (event.syntheticEvent._reactName === "onKeyDown" && event.target.value) {
      setModel(event.target.value);
    }
    if (event.syntheticEvent._reactName === "onBlur") {
      setModel(event.target.value);
    }
  };

  React.useEffect(() => {
    if (brand && model) {
      FetchInfo(urlFetch, {
        name: "event-insurable-object-by-ref",
        data: {
          vehicle: {
            return: "part-first-line",
            brand: brand,
            model: parseInt(model),
          },
        },
        timestamp: "30072020",
        origin: FORM_ORIGIN,
      }).then((response) => {
        setLines(response.sort());
        setInitialLines(response.sort());
      });
    } else {
      setLines([]);
      setInitialLines([]);
    }
  }, [brand, model]);

  return (
    <React.Fragment>
      <Col lg={4}>
        <Field
          validator={fieldRenderProps.validation}
          name="brand"
          label="*Marca"
          key="brand"
          id="brand"
          component={FormComboBox}
          filterable={true}
          onFilterChange={onFilterChangeBrands}
          onChange={handleBrand}
          data={brands}
        />
      </Col>
      <Col lg={4}>
        <Field
          validator={fieldRenderProps.validation}
          name="model"
          label="*Modelo"
          key="model"
          id="model"
          component={FormComboBox}
          data={models}
          onChange={handleModel}
          filterable={true}
          onFilterChange={onFilterChangeModels}
        />
      </Col>
      <Col lg={4}>
        <Field
          validator={fieldRenderProps.validation}
          name="line"
          label="*Línea"
          key="line"
          id="line"
          component={FormComboBox}
          data={lines}
          filterable={true}
          onFilterChange={onFilterChangeLines}
        />
      </Col>
    </React.Fragment>
  );
};

export const FormComboBox = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    visited,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";

  React.useEffect(() => {
    fieldRenderProps.defaultValue &&
      fieldRenderProps.onChange({
        target: { value: fieldRenderProps.defaultValue },
      });
  }, [fieldRenderProps.defaultValue]);

  return (
    <Col>
      <FieldWrapper style={wrapperStyle}>
        <Label
          id={labelId}
          editorRef={editorRef}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
        >
          {label}
        </Label>
        <ComboBox
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          ref={editorRef}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </FieldWrapper>
    </Col>
  );
};
export const FormMultiColumnComboBox = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    visited,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  const columns = [
    {
      field: "id",
      header: <span>header</span>,
      width: "100px",
    },
    {
      field: "name",
      header: "Name",
      width: "300px",
    },
    {
      field: "position",
      header: "Position",
      width: "300px",
    },
  ];
  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <MultiColumnComboBox
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        id={id}
        disabled={disabled}
        columns={columns}
        textField={"name"}
        {...others}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormMultiSelect = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    visited,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <MultiSelect
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        id={id}
        disabled={disabled}
        {...others}
        data={others.data.enum}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormDropDownTree = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    visited,
    wrapperStyle,
    data,
    ...others
  } = fieldRenderProps;
  const { value, selectField, expandField, dataItemKey, filter } = others;
  const [expanded, setExpanded] = React.useState([data[0][dataItemKey]]);
  const treeData = React.useMemo(
    () =>
      processTreeData(
        data,
        {
          expanded,
          value,
          filter,
        },
        {
          selectField,
          expandField,
          dataItemKey,
          subItemsField: "items",
        }
      ),
    [data, expanded, value, filter, selectField, expandField, dataItemKey]
  );
  const onExpandChange = React.useCallback(
    (event) => setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded, dataItemKey]
  );
  const editorRef = React.useRef(null);
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <DropDownTree
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        id={id}
        disabled={disabled}
        /* data={treeData} */
        onExpandChange={onExpandChange}
        dataItemKey={others.dataItemKey}
        textField={others.textField}
        {...others}
        data={others.data.enum}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormDatePicker = (fieldRenderProps) => {
  const locale = {
    language: "es-ES",
    locale: "es",
  };

  const {
    validationMessage,
    touched,
    label,
    visited,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    hintDirection,
    ...others
  } = fieldRenderProps;
  React.useEffect(() => {
    fieldRenderProps.defaultValue &&
      fieldRenderProps.onChange({
        target: { value: fieldRenderProps.defaultValue },
      });
  }, []);
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  return (
    <LocalizationProvider language={locale.language}>
      <IntlProvider locale={locale.locale}>
        <Col>
          <FieldWrapper style={wrapperStyle}>
            <Label
              id={labelId}
              editorId={id}
              editorValid={valid}
              editorDisabled={disabled}
            >
              {label}
            </Label>
            <div className={"k-form-field-wrap"}>
              <DatePicker
                ariaLabelledBy={labelId}
                ariaDescribedBy={`${hintId} ${errorId}`}
                valid={valid}
                id={id}
                disabled={disabled}
                {...others}
              />
              {showHint && (
                <Hint id={hintId} direction={hintDirection}>
                  {hint}
                </Hint>
              )}
              {showValidationMessage && (
                <Error id={errorId}>{validationMessage}</Error>
              )}
            </div>
          </FieldWrapper>
        </Col>
      </IntlProvider>
    </LocalizationProvider>
  );
};
export const FormDateTimePicker = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    visited,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  return (
    <Col xs={6}>
      <FieldWrapper style={wrapperStyle}>
        <Label
          id={labelId}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
        >
          {label}
        </Label>
        <DateTimePicker
          ariaLabelledBy={labelId}
          ariaDescribedBy={`${hintId} ${errorId}`}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </FieldWrapper>
    </Col>
  );
};
export const FormTimePicker = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    visited,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <TimePicker
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        valid={valid}
        id={id}
        disabled={disabled}
        {...others}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormDateInput = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    visited,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <DateInput
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        valid={valid}
        id={id}
        disabled={disabled}
        {...others}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormDateRangePicker = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    visited,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
      >
        {label}
      </Label>
      <DateRangePicker
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        id={id}
        disabled={disabled}
        {...others}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};
export const FormFloatingNumericTextBox = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    visited,
    disabled,
    hint,
    optional,
    value,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  return (
    <FieldWrapper>
      <FloatingLabel
        optional={optional}
        editorValue={value}
        editorId={id}
        editorValid={valid}
        editorDisabled={disabled}
        label={label}
      >
        <NumericTextBox
          ariaDescribedBy={`${hintId} ${errorId}`}
          value={value}
          valid={valid}
          id={id}
          disabled={disabled}
          {...others}
        />
      </FloatingLabel>
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export const FormDatePickerAuto = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    visited,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    hintDirection,
    ...others
  } = fieldRenderProps;
  React.useEffect(() => {
    fieldRenderProps.defaultValue &&
      fieldRenderProps.onChange({
        target: { value: fieldRenderProps.defaultValue },
      });
  }, []);
  const [date, setDate] = React.useState(
    fieldRenderProps.value ? fieldRenderProps.value : ""
  );
  const showValidationMessage = (touched || visited) && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";

  React.useEffect(() => {
    fieldRenderProps.onChange({ target: { value: date } });
  }, [date]);

  return (
    <Col>
      <FieldWrapper style={wrapperStyle}>
        <Label
          id={labelId}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
        >
          {label}
        </Label>
        <div className={"k-form-field-wrap"}>
          <FormBootstrap.Control
            {...others}
            onBlur={(e) => e.preventDefault()}
            onMouseLeave={(e) => e.preventDefault()}
            onChange={(e) => {
              e.preventDefault();
              setDate(e.target.value);
            }}
            value={date}
            type="date"
            ariaLabelledBy={labelId}
            ariaDescribedBy={`${hintId} ${errorId}`}
            valid={valid}
            id={id}
            disabled={disabled}
          />
          {showHint && (
            <Hint id={hintId} direction={hintDirection}>
              {hint}
            </Hint>
          )}
          {showValidationMessage && (
            <Error id={errorId}>{validationMessage}</Error>
          )}
        </div>
      </FieldWrapper>
    </Col>
  );
};

export const AgmDropDownSearch = (fieldRenderProps) => {
  const [initialValues] = React.useState(fieldRenderProps.data);
  const [values, setValues] = React.useState(fieldRenderProps.data);

  const onFilterChangeValues = (e) => {
    let filterer = initialValues.filter((value) => {
      return value[fieldRenderProps.textField]
        .toLowerCase()
        .match(e.filter.value.toLowerCase());
    });
    setValues(filterer);
  };

  return (
    <React.Fragment>
      <Field
        {...fieldRenderProps}
        validator={fieldRenderProps.validation}
        name={fieldRenderProps.name}
        textField={fieldRenderProps.textField}
        label={fieldRenderProps.label}
        key={fieldRenderProps.id}
        id={fieldRenderProps.id}
        component={FormComboBox}
        filterable={true}
        onFilterChange={onFilterChangeValues}
        data={values}
      />
    </React.Fragment>
  );
};
