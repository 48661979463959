const COUNTRIES = [
  { text: "Afganistán", id: "AFG", "country-code": "004" },
  { text: "Islas Åland", id: "ALA", "country-code": "248" },
  { text: "Albania", id: "ALB", "country-code": "008" },
  { text: "Argelia", id: "DZA", "country-code": "012" },
  { text: "Samoa Americana", id: "ASM", "country-code": "016" },
  { text: "Andorra", id: "AND", "country-code": "020" },
  { text: "Angola", id: "AGO", "country-code": "024" },
  { text: "Anguila", id: "AIA", "country-code": "660" },
  { text: "Antártida", id: "ATA", "country-code": "010" },
  { text: "Antigua y Barbuda", id: "ATG", "country-code": "028" },
  { text: "Argentina", id: "ARG", "country-code": "032" },
  { text: "Armenia", id: "ARM", "country-code": "051" },
  { text: "Aruba", id: "ABW", "country-code": "533" },
  { text: "Australia", id: "AUS", "country-code": "036" },
  { text: "Austria", id: "AUT", "country-code": "040" },
  { text: "Azerbaiyán", id: "AZE", "country-code": "031" },
  { text: "Bahamas", id: "BHS", "country-code": "044" },
  { text: "Baréin", id: "BHR", "country-code": "048" },
  { text: "Bangladés", id: "BGD", "country-code": "050" },
  { text: "Barbados", id: "BRB", "country-code": "052" },
  { text: "Bielorrusia", id: "BLR", "country-code": "112" },
  { text: "Bélgica", id: "BEL", "country-code": "056" },
  { text: "Belice", id: "BLZ", "country-code": "084" },
  { text: "Benín", id: "BEN", "country-code": "204" },
  { text: "Bermudas", id: "BMU", "country-code": "060" },
  { text: "Bután", id: "BTN", "country-code": "064" },
  { text: "Bolivia", id: "BOL", "country-code": "068" },
  { text: "Bonaire, San Eustaquio y Saba", id: "BES", "country-code": "535" },
  { text: "Bosnia y Herzegovina", id: "BIH", "country-code": "070" },
  { text: "Botsuana", id: "BWA", "country-code": "072" },
  { text: "Isla Bouvet", id: "BVT", "country-code": "074" },
  { text: "Brasil", id: "BRA", "country-code": "076" },
  {
    text: "Territorio Británico del Océano Índico",
    id: "IOT",
    "country-code": "086",
  },
  { text: "Brunéi Darussalam", id: "BRN", "country-code": "096" },
  { text: "Bulgaria", id: "BGR", "country-code": "100" },
  { text: "Burkina Faso", id: "BFA", "country-code": "854" },
  { text: "Burundi", id: "BDI", "country-code": "108" },
  { text: "Cabo Verde", id: "CPV", "country-code": "132" },
  { text: "Camboya", id: "KHM", "country-code": "116" },
  { text: "Camerún", id: "CMR", "country-code": "120" },
  { text: "Canadá", id: "CAN", "country-code": "124" },
  { text: "Islas Caimán", id: "CYM", "country-code": "136" },
  { text: "República Centroafricana", id: "CAF", "country-code": "140" },
  { text: "Chad", id: "TCD", "country-code": "148" },
  { text: "Chile", id: "CHL", "country-code": "152" },
  { text: "China", id: "CHN", "country-code": "156" },
  { text: "Isla de Navidad", id: "CXR", "country-code": "162" },
  { text: "Islas Cocos (Keeling)", id: "CCK", "country-code": "166" },
  // { text: "Colombia", id: "COL", "country-code": "170" },
  { text: "Comoras", id: "COM", "country-code": "174" },
  { text: "Congo", id: "COG", "country-code": "178" },
  {
    text: "Congo, República Democrática del",
    id: "COD",
    "country-code": "180",
  },
  { text: "Islas Cook", id: "COK", "country-code": "184" },
  { text: "Costa Rica", id: "CRI", "country-code": "188" },
  { text: "Costa de Marfil", id: "CIV", "country-code": "384" },
  { text: "Croacia", id: "HRV", "country-code": "191" },
  { text: "Cuba", id: "CUB", "country-code": "192" },
  { text: "Curazao", id: "CUW", "country-code": "531" },
  { text: "Chipre", id: "CYP", "country-code": "196" },
  { text: "Chequia", id: "CZE", "country-code": "203" },
  { text: "Dinamarca", id: "DNK", "country-code": "208" },
  { text: "Yibuti", id: "DJI", "country-code": "262" },
  { text: "Dominica", id: "DMA", "country-code": "212" },
  { text: "República Dominicana", id: "DOM", "country-code": "214" },
  { text: "Ecuador", id: "ECU", "country-code": "218" },
  { text: "Egipto", id: "EGY", "country-code": "818" },
  { text: "El Salvador", id: "SLV", "country-code": "222" },
  { text: "Guinea Ecuatorial", id: "GNQ", "country-code": "226" },
  { text: "Eritrea", id: "ERI", "country-code": "232" },
  { text: "Estonia", id: "EST", "country-code": "233" },
  { text: "Esuatini", id: "SWZ", "country-code": "748" },
  { text: "Etiopía", id: "ETH", "country-code": "231" },
  { text: "Islas Malvinas", id: "FLK", "country-code": "238" },
  { text: "Islas Feroe", id: "FRO", "country-code": "234" },
  { text: "Fiyi", id: "FJI", "country-code": "242" },
  { text: "Finlandia", id: "FIN", "country-code": "246" },
  { text: "Francia", id: "FRA", "country-code": "250" },
  { text: "Guayana Francesa", id: "GUF", "country-code": "254" },
  { text: "Polinesia Francesa", id: "PYF", "country-code": "258" },
  { text: "Territorios Australes Franceses", id: "ATF", "country-code": "260" },
  { text: "Gabón", id: "GAB", "country-code": "266" },
  { text: "Gambia", id: "GMB", "country-code": "270" },
  { text: "Georgia", id: "GEO", "country-code": "268" },
  { text: "Alemania", id: "DEU", "country-code": "276" },
  { text: "Ghana", id: "GHA", "country-code": "288" },
  { text: "Gibraltar", id: "GIB", "country-code": "292" },
  { text: "Grecia", id: "GRC", "country-code": "300" },
  { text: "Groenlandia", id: "GRL", "country-code": "304" },
  { text: "Granada", id: "GRD", "country-code": "308" },
  { text: "Guadalupe", id: "GLP", "country-code": "312" },
  { text: "Guam", id: "GUM", "country-code": "316" },
  { text: "Guatemala", id: "GTM", "country-code": "320" },
  { text: "Guernesey", id: "GGY", "country-code": "831" },
  { text: "Guinea", id: "GIN", "country-code": "324" },
  { text: "Guinea-Bisáu", id: "GNB", "country-code": "624" },
  { text: "Guyana", id: "GUY", "country-code": "328" },
  { text: "Haití", id: "HTI", "country-code": "332" },
  { text: "Islas Heard y McDonald", id: "HMD", "country-code": "334" },
  { text: "Santa Sede", id: "VAT", "country-code": "336" },
  { text: "Honduras", id: "HND", "country-code": "340" },
  { text: "Hong Kong", id: "HKG", "country-code": "344" },
  { text: "Hungría", id: "HUN", "country-code": "348" },
  { text: "Islandia", id: "ISL", "country-code": "352" },
  { text: "India", id: "IND", "country-code": "356" },
  { text: "Indonesia", id: "IDN", "country-code": "360" },
  { text: "Irán", id: "IRN", "country-code": "364" },
  { text: "Irak", id: "IRQ", "country-code": "368" },
  { text: "Irlanda", id: "IRL", "country-code": "372" },
  { text: "Isla de Man", id: "IMN", "country-code": "833" },
  { text: "Israel", id: "ISR", "country-code": "376" },
  { text: "Italia", id: "ITA", "country-code": "380" },
  { text: "Jamaica", id: "JAM", "country-code": "388" },
  { text: "Japón", id: "JPN", "country-code": "392" },
  { text: "Jersey", id: "JEY", "country-code": "832" },
  { text: "Jordania", id: "JOR", "country-code": "400" },
  { text: "Kazajistán", id: "KAZ", "country-code": "398" },
  { text: "Kenia", id: "KEN", "country-code": "404" },
  { text: "Kiribati", id: "KIR", "country-code": "296" },
  { text: "Corea del Norte", id: "PRK", "country-code": "408" },
  { text: "Corea del Sur", id: "KOR", "country-code": "410" },
  { text: "Kuwait", id: "KWT", "country-code": "414" },
  { text: "Kirguistán", id: "KGZ", "country-code": "417" },
  { text: "Laos", id: "LAO", "country-code": "418" },
  { text: "Letonia", id: "LVA", "country-code": "428" },
  { text: "Líbano", id: "LBN", "country-code": "422" },
  { text: "Lesoto", id: "LSO", "country-code": "426" },
  { text: "Liberia", id: "LBR", "country-code": "430" },
  { text: "Libia", id: "LBY", "country-code": "434" },
  { text: "Liechtenstein", id: "LIE", "country-code": "438" },
  { text: "Lituania", id: "LTU", "country-code": "440" },
  { text: "Luxemburgo", id: "LUX", "country-code": "442" },
  { text: "Macao", id: "MAC", "country-code": "446" },
  { text: "Madagascar", id: "MDG", "country-code": "450" },
  { text: "Malaui", id: "MWI", "country-code": "454" },
  { text: "Malasia", id: "MYS", "country-code": "458" },
  { text: "Maldivas", id: "MDV", "country-code": "462" },
  { text: "Malí", id: "MLI", "country-code": "466" },
  { text: "Malta", id: "MLT", "country-code": "470" },
  { text: "Islas Marshall", id: "MHL", "country-code": "584" },
  { text: "Martinica", id: "MTQ", "country-code": "474" },
  { text: "Mauritania", id: "MRT", "country-code": "478" },
  { text: "Mauricio", id: "MUS", "country-code": "480" },
  { text: "Mayotte", id: "MYT", "country-code": "175" },
  { text: "México", id: "MEX", "country-code": "484" },
  { text: "Micronesia", id: "FSM", "country-code": "583" },
  { text: "Moldavia", id: "MDA", "country-code": "498" },
  { text: "Mónaco", id: "MCO", "country-code": "492" },
  { text: "Mongolia", id: "MNG", "country-code": "496" },
  { text: "Montenegro", id: "MNE", "country-code": "499" },
  { text: "Montserrat", id: "MSR", "country-code": "500" },
  { text: "Marruecos", id: "MAR", "country-code": "504" },
  { text: "Mozambique", id: "MOZ", "country-code": "508" },
  { text: "Myanmar", id: "MMR", "country-code": "104" },
  { text: "Namibia", id: "NAM", "country-code": "516" },
  { text: "Nauru", id: "NRU", "country-code": "520" },
  { text: "Nepal", id: "NPL", "country-code": "524" },
  { text: "Países Bajos", id: "NLD", "country-code": "528" },
  { text: "Nueva Caledonia", id: "NCL", "country-code": "540" },
  { text: "Nueva Zelanda", id: "NZL", "country-code": "554" },
  { text: "Nicaragua", id: "NIC", "country-code": "558" },
  { text: "Níger", id: "NER", "country-code": "562" },
  { text: "Nigeria", id: "NGA", "country-code": "566" },
  { text: "Niue", id: "NIU", "country-code": "570" },
  { text: "Isla Norfolk", id: "NFK", "country-code": "574" },
  { text: "Macedonia del Norte", id: "MKD", "country-code": "807" },
  { text: "Islas Marianas del Norte", id: "MNP", "country-code": "580" },
  { text: "Noruega", id: "NOR", "country-code": "578" },
  { text: "Omán", id: "OMN", "country-code": "512" },
  { text: "Pakistán", id: "PAK", "country-code": "586" },
  { text: "Palaos", id: "PLW", "country-code": "585" },
  { text: "Palestina", id: "PSE", "country-code": "275" },
  { text: "Panamá", id: "PAN", "country-code": "591" },
  { text: "Papúa Nueva Guinea", id: "PNG", "country-code": "598" },
  { text: "Paraguay", id: "PRY", "country-code": "600" },
  { text: "Perú", id: "PER", "country-code": "604" },
  { text: "Filipinas", id: "PHL", "country-code": "608" },
  { text: "Islas Pitcairn", id: "PCN", "country-code": "612" },
  { text: "Polonia", id: "POL", "country-code": "616" },
  { text: "Portugal", id: "PRT", "country-code": "620" },
  { text: "Puerto Rico", id: "PRI", "country-code": "630" },
  { text: "Qatar", id: "QAT", "country-code": "634" },
  { text: "Reunión", id: "REU", "country-code": "638" },
  { text: "Rumania", id: "ROU", "country-code": "642" },
  { text: "Federación Rusa", id: "RUS", "country-code": "643" },
  { text: "Ruanda", id: "RWA", "country-code": "646" },
  { text: "San Bartolomé", id: "BLM", "country-code": "652" },
  {
    text: "Santa Elena, Ascensión y Tristán de Acuña",
    id: "SHN",
    "country-code": "654",
  },
  { text: "San Cristóbal y Nieves", id: "KNA", "country-code": "659" },
  { text: "Santa Lucía", id: "LCA", "country-code": "662" },
  { text: "San Martín (parte francesa)", id: "MAF", "country-code": "663" },
  { text: "San Pedro y Miquelón", id: "SPM", "country-code": "666" },
  { text: "San Vicente y las Granadinas", id: "VCT", "country-code": "670" },
  { text: "Samoa", id: "WSM", "country-code": "882" },
  { text: "San Marino", id: "SMR", "country-code": "674" },
  { text: "Santo Tomé y Príncipe", id: "STP", "country-code": "678" },
  { text: "Arabia Saudita", id: "SAU", "country-code": "682" },
  { text: "Senegal", id: "SEN", "country-code": "686" },
  { text: "Serbia", id: "SRB", "country-code": "688" },
  { text: "Seychelles", id: "SYC", "country-code": "690" },
  { text: "Sierra Leona", id: "SLE", "country-code": "694" },
  { text: "Singapur", id: "SGP", "country-code": "702" },
  { text: "Sint Maarten (parte holandesa)", id: "SXM", "country-code": "534" },
  { text: "Eslovaquia", id: "SVK", "country-code": "703" },
  { text: "Eslovenia", id: "SVN", "country-code": "705" },
  { text: "Islas Salomón", id: "SLB", "country-code": "090" },
  { text: "Somalia", id: "SOM", "country-code": "706" },
  { text: "Sudáfrica", id: "ZAF", "country-code": "710" },
  {
    text: "Georgia del Sur y las Islas Sandwich del Sur",
    id: "SGS",
    "country-code": "239",
  },
  { text: "Sudán del Sur", id: "SSD", "country-code": "728" },
  { text: "España", id: "ESP", "country-code": "724" },
  { text: "Sri Lanka", id: "LKA", "country-code": "144" },
  { text: "Sudán", id: "SDN", "country-code": "729" },
  { text: "Surinam", id: "SUR", "country-code": "740" },
  { text: "Svalbard y Jan Mayen", id: "SJM", "country-code": "744" },
  { text: "Suecia", id: "SWE", "country-code": "752" },
  { text: "Suiza", id: "CHE", "country-code": "756" },
  { text: "República Árabe Siria", id: "SYR", "country-code": "760" },
  { text: "Taiwán", id: "TWN", "country-code": "158" },
  { text: "Tayikistán", id: "TJK", "country-code": "762" },
  { text: "Tanzania", id: "TZA", "country-code": "834" },
  { text: "Tailandia", id: "THA", "country-code": "764" },
  { text: "Timor-Leste", id: "TLS", "country-code": "626" },
  { text: "Togo", id: "TGO", "country-code": "768" },
  { text: "Tokelau", id: "TKL", "country-code": "772" },
  { text: "Tonga", id: "TON", "country-code": "776" },
  { text: "Trinidad y Tobago", id: "TTO", "country-code": "780" },
  { text: "Túnez", id: "TUN", "country-code": "788" },
  { text: "Turquía", id: "TUR", "country-code": "792" },
  { text: "Turkmenistán", id: "TKM", "country-code": "795" },
  { text: "Islas Turcas y Caicos", id: "TCA", "country-code": "796" },
  { text: "Tuvalu", id: "TUV", "country-code": "798" },
  { text: "Uganda", id: "UGA", "country-code": "800" },
  { text: "Ucrania", id: "UKR", "country-code": "804" },
  { text: "Emiratos Árabes Unidos", id: "ARE", "country-code": "784" },
  { text: "Reino Unido", id: "GBR", "country-code": "826" },
  { text: "Estados Unidos", id: "USA", "country-code": "840" },
  {
    text: "Islas Ultramarinas Menores de Estados Unidos",
    id: "UMI",
    "country-code": "581",
  },
  { text: "Uruguay", id: "URY", "country-code": "858" },
  { text: "Uzbekistán", id: "UZB", "country-code": "860" },
  { text: "Vanuatu", id: "VUT", "country-code": "548" },
  { text: "Venezuela", id: "VEN", "country-code": "862" },
  { text: "Vietnam", id: "VNM", "country-code": "704" },
  { text: "Islas Vírgenes Británicas", id: "VGB", "country-code": "092" },
  {
    text: "Islas Vírgenes de los Estados Unidos",
    id: "VIR",
    "country-code": "850",
  },
  { text: "Wallis y Futuna", id: "WLF", "country-code": "876" },
  { text: "Sahara Occidental", id: "ESH", "country-code": "732" },
  { text: "Yemen", id: "YEM", "country-code": "887" },
  { text: "Zambia", id: "ZMB", "country-code": "894" },
  { text: "Zimbabue", id: "ZWE", "country-code": "716" },
];
export default COUNTRIES;
