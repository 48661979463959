import { FetchInfo } from "./FetchInfo";
import { FORM_ORIGIN } from "./variables";

export const getVehicleByPlate = async (plate) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append(
    "Cookie",
    "XSRF-TOKEN=eyJpdiI6IjNPaWRDSUZVeDdpSzJIVzdhVDR1N2c9PSIsInZhbHVlIjoiSmNyUHNKWUJvZWEyN1hsRWhUQWZYTTcxbmtSVVVMbXdyYXg1Ulp1bG9NaW8rS2xkMkpOTHhHVmRVVnBxaVdrWiIsIm1hYyI6IjZjMDU5YmM4ZWM3ODgwYjg0MTZmZmY2NTExNjhhY2IwMTg0M2I1OTEyMDU2Nzg4NTliNDAyZGEwZWZlMDgxZGEifQ%3D%3D; agentemotor_session=eyJpdiI6ImZcL0MwVkxDK3kxOUtxTWZxdU1iWENnPT0iLCJ2YWx1ZSI6IlBJWGpwYVdzUXYrTER0RG1PbkN3VEk1XC9cLzJ1SFJnUWQrS2t0T3pcL0FhZjBzNTd3NUh0RDJlZ3lLa0o1K284VGkiLCJtYWMiOiJkOWNlMjQzY2UzYWFlOGM0ZTkyZjAzYjk2MjgwOTZkMjIzZWVhZjI5NmU0YThiM2M4NTdlZjU2ZjRjYTQ2MzhhIn0%3D"
  );

  var raw = JSON.stringify({
    name: "event-insurable-object-by-plate",
    data: {
      vehicle: {
        plate: plate,
      },
    },
    origin: FORM_ORIGIN,
  });

  var requestOptions = {
    method: "POST",
    headers,
    body: raw,
    redirect: "follow",
  };

  return await fetch(
    "https://apipro.agentemotor.com/seguros/co/vehiculos/consultar/v2",
    requestOptions
  )
    .then((response) => response.json())
    .catch((e) => e);
};

export const getVehicleByReference = (
  brand,
  model,
  line,
  in_agency = false
) => {
  return FetchInfo(
    "https://apipro.agentemotor.com/seguros/co/vehiculos/consultar/v2",
    {
      name: "event-insurable-object-by-ref",
      data: {
        vehicle: {
          line: line,
          brand: brand,
          model: parseInt(model),
          vehicle_risk: {
            in_agency: in_agency,
          },
        },
      },
      timestamp: "30072020",
      origin: FORM_ORIGIN,
    }
  );
};
