// Tipos de uso

const PASSENGERS_TRANSPORT = [
  {
    id: "transporte_pasajeros_escolar",
    text: "Transporte escolar",
  },
  {
    id: "transporte_pasajeros_interdepartamental",
    text: "Transporte interdepartamental",
  },
  {
    id: "transporte_pasajeros_intermunicipal",
    text: "Transporte intermunicipal",
  },
  {
    id: "transporte_pasajeros_tripulaciones",
    text: "Transporte de tripulaciones",
  },
  {
    id: "transporte_pasajeros_urbano",
    text: "Transporte urbano",
  },
];

const FREIGHT_TRANSPORT = [
  {
    id: "carga_carroceria_especial",
    text: "Carga carroceria especial",
  },
  {
    id: "carga_mercancia_propia",
    text: "Carga mercancia propia",
  },
  {
    id: "carga_mercancia_terceros",
    text: "Carga mercancia terceros",
  },
  // {
  //   id: "carga_transporte_combustible",
  //   text: "Carga transporte combustible",
  // },
];

const PARTICULAR = [
  {
    id: "particular",
    text: "Particular",
  },
];

const WORK = [
  {
    id: "trabajo",
    text: "Trabajo",
  },
];

const TAXIS = [
  {
    id: "taxi_hotelero",
    text: "Taxi hotelero",
  },
  {
    id: "taxi_urbano",
    text: "Taxi urbano",
  },
];

const UTILITY = [
  {
    id: "utilitario_alquiler_empresa_pasajeros_mercancia",
    text: "Utilitario alquiler empresa pasajeros mercancia",
  },
  {
    id: "utilitario_transporte_urbana_mercancia_propia",
    text: "Utilitario transporte urbano mercancia propia",
  },
  {
    id: "utilitario_transporte_urbana_mercancia_terceros",
    text: "Utilitario transporte urbano mercancia terceros",
  },
];

const UTILITY_PASS = UTILITY[0];

const TRAILER = [
  {
    id: "trailer_remolque",
    text: "Trailer de remolque",
  },
];

const USE_TYPES_BY_VEHICLE_TYPE = {
  "BUS / BUSETA / MICROBUS": [...PASSENGERS_TRANSPORT],
  MICROBUS: [...PASSENGERS_TRANSPORT],
  "CAMIONETA PASAJ.": [
    ...PARTICULAR,
    ...PASSENGERS_TRANSPORT,
    ...TAXIS,
    ...UTILITY,
  ],
  CAMION: [...FREIGHT_TRANSPORT],
  FURGON: [...FREIGHT_TRANSPORT],
  VOLQUETA: [...FREIGHT_TRANSPORT],
  REMOLCADOR: [...FREIGHT_TRANSPORT],
  CARROTANQUE: [...FREIGHT_TRANSPORT],
  AUTOMOVIL: [...PARTICULAR, ...TAXIS, ...UTILITY],
  CAMPERO: [...PARTICULAR, ...TAXIS, ...UTILITY],
  "PICKUP SENCILLA": [...PARTICULAR, ...TAXIS, ...UTILITY],
  "PICKUP DOBLE CAB": [...PARTICULAR, ...TAXIS, ...UTILITY],
  MOTOCARRO: [...PARTICULAR],
  MOTOCICLETA: [...PARTICULAR],
  CUATRIMOTO: [...PARTICULAR],
  "CAMIONETA REPAR": [...UTILITY],
  FURGONETA: [...UTILITY],
  REMOLQUE: [...TRAILER],
};

const USE_TYPES_BY_PLATE_PUBLIC = {
  "BUS / BUSETA / MICROBUS": [...PASSENGERS_TRANSPORT],
  MICROBUS: [...PASSENGERS_TRANSPORT],
  "CAMIONETA PASAJ.": [...TAXIS, UTILITY_PASS, ...PASSENGERS_TRANSPORT],
  CAMION: [...FREIGHT_TRANSPORT],
  FURGON: [...FREIGHT_TRANSPORT],
  VOLQUETA: [...FREIGHT_TRANSPORT],
  REMOLCADOR: [...FREIGHT_TRANSPORT],
  CARROTANQUE: [...FREIGHT_TRANSPORT],
  AUTOMOVIL: [...TAXIS, ...UTILITY],
  CAMPERO: [...TAXIS, ...UTILITY],
  "PICKUP SENCILLA": [...UTILITY],
  "PICKUP DOBLE CAB": [...UTILITY],
  MOTOCARRO: [],
  MOTOCICLETA: [],
  CUATRIMOTO: [],
  "CAMIONETA REPAR": [...UTILITY],
  FURGONETA: [...UTILITY],
  REMOLQUE: [...TRAILER],
};
const USE_TYPES_BY_PLATE_PARTICULAR = {
  "BUS / BUSETA / MICROBUS": [],
  MICROBUS: [],
  "CAMIONETA PASAJ.": [...PARTICULAR, UTILITY_PASS],
  CAMION: [],
  FURGON: [],
  VOLQUETA: [],
  REMOLCADOR: [],
  CARROTANQUE: [],
  AUTOMOVIL: [...PARTICULAR, ...UTILITY],
  CAMPERO: [...PARTICULAR, ...UTILITY],
  "PICKUP SENCILLA": [...PARTICULAR, ...UTILITY],
  "PICKUP DOBLE CAB": [...PARTICULAR, ...UTILITY],
  MOTOCARRO: [...PARTICULAR],
  MOTOCICLETA: [...PARTICULAR],
  CUATRIMOTO: [...PARTICULAR],
  "CAMIONETA REPAR": [...UTILITY],
  FURGONETA: [...UTILITY],
  REMOLQUE: [...TRAILER],
};

const ALL_USE_TYPES = [
  {
    id: "particular",
    text: "Particular",
  },
  {
    id: "ambulancia",
    text: "Ambulancia",
  },
  {
    id: "transporte_pasajeros_escolar",
    text: "Transporte escolar",
  },
  {
    id: "transporte_pasajeros_interdepartamental",
    text: "Transporte interdepartamental",
  },
  {
    id: "transporte_pasajeros_intermunicipal",
    text: "Transporte intermunicipal",
  },
  {
    id: "transporte_pasajeros_tripulaciones",
    text: "Transporte de tripulaciones",
  },
  {
    id: "transporte_pasajeros_urbano",
    text: "Transporte urbano",
  },
  {
    id: "carga_carroceria_especial",
    text: "Carga carroceria especial",
  },
  {
    id: "carga_mercancia_propia",
    text: "Carga mercancia propia",
  },
  {
    id: "carga_mercancia_terceros",
    text: "Carga mercancia terceros",
  },
  {
    id: "carga_trailer_remolque",
    text: "Carga trailer remolque",
  },
  {
    id: "carga_transporte_combustible",
    text: "Carga transporte combustible",
  },
  {
    id: "taxi_hotelero",
    text: "Taxi hotelero",
  },
  {
    id: "taxi_urbano",
    text: "Taxi urbano",
  },
  {
    id: "utilitario_alquiler_empresas_pasajeros_mercancia",
    text: "Utilitario alquiler empresa pasajeros mercancia",
  },
  {
    id: "utilitario_transporte_urbana_mercancia_propia",
    text: "Utilitario transporte urbano mercancia propia",
  },
  {
    id: "utilitario_transporte_urbana_mercancia_terceros",
    text: "Utilitario transporte urbano mercancia terceros",
  },
  {
    id: "trabajo",
    text: "Trabajo",
  },
  {
    id: "trailer_remolque",
    text: "Trailer de remolque",
  },
];

// Generos select
const GENDERS_SELECT = [
  {
    text: "Masculino",
    id: "M",
  },
  {
    text: "Femenino",
    id: "F",
  },
];

// Generos Radio button
const GENDERS = [
  {
    label: "Masculino",
    value: "M",
  },
  {
    label: "Femenino",
    value: "F",
  },
];

// Tipos de identificacion
const IDENTIFICATIONS_TYPES = [
  {
    text: "Cédula de ciudadania",
    id: "CC",
  },
  // {
  //     text: "Tarjeta de identidad",
  //     id: "TI"
  // },
  {
    text: "Cédula de extranjería",
    id: "CE",
  },
  {
    text: "Pasaporte",
    id: "PA",
  },
  // {
  //     text: "Registro civil",
  //     id: "RC"
  // },
  {
    text: "NIT",
    id: "NIT",
  },
];

// Ocupaciones
const OCUPATIONS = [
  // {
  //     text: "No definida",
  //     id: "No definida"
  // },
  {
    text: "Profesional Independiente",
    id: "Profesional Independiente",
  },
  {
    text: "Profesional Dependiente",
    id: "Profesional Dependiente",
  },
  {
    text: "Ama de casa",
    id: "Ama de casa",
  },
  {
    text: "Comerciante",
    id: "Comerciante",
  },
  {
    text: "Estudiante",
    id: "Estudiante",
  },
  {
    text: "No Profesional Dependiente",
    id: "No Profesional Dependiente",
  },
  {
    text: "Pensionado",
    id: "Pensionado",
  },
];

// Tipos de placa
const PARTICULAR_PLATE_TYPE = [
  {
    text: "Particular",
    id: "particular",
  },
];

const PUBLIC_PLATE_TYPE = [
  {
    text: "Público",
    id: "publico",
  },
];

const ALL_PLATE_TYPES = [...PUBLIC_PLATE_TYPE, ...PARTICULAR_PLATE_TYPE];

const PLATE_TYPES_BY_VEHICLE_TYPE = {
  "BUS / BUSETA / MICROBUS": [...PUBLIC_PLATE_TYPE],
  MICROBUS: [...PUBLIC_PLATE_TYPE],
  "CAMIONETA PASAJ.": [...PARTICULAR_PLATE_TYPE, ...PUBLIC_PLATE_TYPE],
  CAMION: [...PUBLIC_PLATE_TYPE],
  FURGON: [...PUBLIC_PLATE_TYPE],
  VOLQUETA: [...PUBLIC_PLATE_TYPE],
  REMOLCADOR: [...PUBLIC_PLATE_TYPE],
  CARROTANQUE: [...PUBLIC_PLATE_TYPE],
  AUTOMOVIL: [...PUBLIC_PLATE_TYPE, ...PARTICULAR_PLATE_TYPE],
  CAMPERO: [...PUBLIC_PLATE_TYPE, ...PARTICULAR_PLATE_TYPE],
  "PICKUP SENCILLA": [...PUBLIC_PLATE_TYPE, ...PARTICULAR_PLATE_TYPE],
  "PICKUP DOBLE CAB": [...PUBLIC_PLATE_TYPE, ...PARTICULAR_PLATE_TYPE],
  MOTOCARRO: [...PARTICULAR_PLATE_TYPE],
  MOTOCICLETA: [...PARTICULAR_PLATE_TYPE],
  CUATRIMOTO: [...PARTICULAR_PLATE_TYPE],
  "CAMIONETA REPAR": [...PUBLIC_PLATE_TYPE, ...PARTICULAR_PLATE_TYPE],
  FURGONETA: [...PUBLIC_PLATE_TYPE, ...PARTICULAR_PLATE_TYPE],
  REMOLQUE: [...PUBLIC_PLATE_TYPE],
};

const checkTypeInKeys = (type, Object) => {
  return Object.hasOwnProperty(type);
};

const MARITAL_STATUS = [
  {
    text: "Casado",
    id: "married",
  },
  {
    text: "Separado",
    id: "separated",
  },
  {
    text: "Soltero",
    id: "single",
  },
  {
    text: "Union",
    id: "union",
  },
  {
    text: "Viudo",
    id: "widowed",
  },
];

// Tipos de identificacion que una persona puede tener
const IDENTIFICATIONS_TYPES_PERSON = IDENTIFICATIONS_TYPES.slice(
  0,
  IDENTIFICATIONS_TYPES.length - 1
);

function searchDefinitionById(id, definitions) {
  return definitions.find((definition) => definition.id === id);
}

export {
  GENDERS,
  IDENTIFICATIONS_TYPES,
  OCUPATIONS,
  ALL_PLATE_TYPES,
  PLATE_TYPES_BY_VEHICLE_TYPE,
  checkTypeInKeys,
  PUBLIC_PLATE_TYPE,
  PARTICULAR_PLATE_TYPE,
  USE_TYPES_BY_VEHICLE_TYPE,
  USE_TYPES_BY_PLATE_PARTICULAR,
  USE_TYPES_BY_PLATE_PUBLIC,
  IDENTIFICATIONS_TYPES_PERSON,
  MARITAL_STATUS,
  searchDefinitionById,
};
