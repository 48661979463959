const CitiesRes = [
  {
    id: 1,
    country_name: "CO-ANT-MED1",
    country_code: "CO-ANT-MED1",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Medellín",
    city_code: "1",
    code: "05001",
  },
  {
    id: 2,
    country_name: "CO-ANT-ABE2",
    country_code: "CO-ANT-ABE2",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Abejorral",
    city_code: "2",
    code: "05002",
  },
  {
    id: 3,
    country_name: "CO-ANT-ABR4",
    country_code: "CO-ANT-ABR4",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Abriaquí",
    city_code: "4",
    code: "05004",
  },
  {
    id: 4,
    country_name: "CO-ANT-ALE21",
    country_code: "CO-ANT-ALE21",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Alejandría",
    city_code: "21",
    code: "05021",
  },
  {
    id: 5,
    country_name: "CO-ANT-AMA30",
    country_code: "CO-ANT-AMA30",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Amagá",
    city_code: "30",
    code: "05030",
  },
  {
    id: 6,
    country_name: "CO-ANT-AMA31",
    country_code: "CO-ANT-AMA31",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Amalfi",
    city_code: "31",
    code: "05031",
  },
  {
    id: 7,
    country_name: "CO-ANT-AND34",
    country_code: "CO-ANT-AND34",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Andes",
    city_code: "34",
    code: "05034",
  },
  {
    id: 8,
    country_name: "CO-ANT-ANG36",
    country_code: "CO-ANT-ANG36",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Angelópolis",
    city_code: "36",
    code: "05036",
  },
  {
    id: 9,
    country_name: "CO-ANT-ANG38",
    country_code: "CO-ANT-ANG38",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Angostura",
    city_code: "38",
    code: "05038",
  },
  {
    id: 10,
    country_name: "CO-ANT-ANO40",
    country_code: "CO-ANT-ANO40",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Anorí",
    city_code: "40",
    code: "05040",
  },
  {
    id: 11,
    country_name: "CO-ANT-SAN42",
    country_code: "CO-ANT-SAN42",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Santa Fé De Antioquia",
    city_code: "42",
    code: "05042",
  },
  {
    id: 12,
    country_name: "CO-ANT-ANZ44",
    country_code: "CO-ANT-ANZ44",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Anzá",
    city_code: "44",
    code: "05044",
  },
  {
    id: 13,
    country_name: "CO-ANT-APA45",
    country_code: "CO-ANT-APA45",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Apartadó",
    city_code: "45",
    code: "05045",
  },
  {
    id: 14,
    country_name: "CO-ANT-ARB51",
    country_code: "CO-ANT-ARB51",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Arboletes",
    city_code: "51",
    code: "05051",
  },
  {
    id: 15,
    country_name: "CO-ANT-ARG55",
    country_code: "CO-ANT-ARG55",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Argelia",
    city_code: "55",
    code: "05055",
  },
  {
    id: 16,
    country_name: "CO-ANT-ARM59",
    country_code: "CO-ANT-ARM59",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Armenia",
    city_code: "59",
    code: "05059",
  },
  {
    id: 17,
    country_name: "CO-ANT-BAR79",
    country_code: "CO-ANT-BAR79",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Barbosa",
    city_code: "79",
    code: "05079",
  },
  {
    id: 18,
    country_name: "CO-ANT-BEL86",
    country_code: "CO-ANT-BEL86",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Belmira",
    city_code: "86",
    code: "05086",
  },
  {
    id: 19,
    country_name: "CO-ANT-BEL88",
    country_code: "CO-ANT-BEL88",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Bello",
    city_code: "88",
    code: "05088",
  },
  {
    id: 20,
    country_name: "CO-ANT-BET91",
    country_code: "CO-ANT-BET91",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Betania",
    city_code: "91",
    code: "05091",
  },
  {
    id: 21,
    country_name: "CO-ANT-BET93",
    country_code: "CO-ANT-BET93",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Betulia",
    city_code: "93",
    code: "05093",
  },
  {
    id: 22,
    country_name: "CO-ANT-CIU101",
    country_code: "CO-ANT-CIU101",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Ciudad Bolívar",
    city_code: "101",
    code: "05101",
  },
  {
    id: 23,
    country_name: "CO-ANT-BRI107",
    country_code: "CO-ANT-BRI107",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Briceño",
    city_code: "107",
    code: "05107",
  },
  {
    id: 24,
    country_name: "CO-ANT-BUR113",
    country_code: "CO-ANT-BUR113",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Buriticá",
    city_code: "113",
    code: "05113",
  },
  {
    id: 25,
    country_name: "CO-ANT-CAC120",
    country_code: "CO-ANT-CAC120",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Cáceres",
    city_code: "120",
    code: "05120",
  },
  {
    id: 26,
    country_name: "CO-ANT-CAI125",
    country_code: "CO-ANT-CAI125",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Caicedo",
    city_code: "125",
    code: "05125",
  },
  {
    id: 27,
    country_name: "CO-ANT-CAL129",
    country_code: "CO-ANT-CAL129",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Caldas",
    city_code: "129",
    code: "05129",
  },
  {
    id: 28,
    country_name: "CO-ANT-CAM134",
    country_code: "CO-ANT-CAM134",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Campamento",
    city_code: "134",
    code: "05134",
  },
  {
    id: 29,
    country_name: "CO-ANT-CA?138",
    country_code: "CO-ANT-CA?138",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Cañasgordas",
    city_code: "138",
    code: "05138",
  },
  {
    id: 30,
    country_name: "CO-ANT-CAR142",
    country_code: "CO-ANT-CAR142",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Caracolí",
    city_code: "142",
    code: "05142",
  },
  {
    id: 31,
    country_name: "CO-ANT-CAR145",
    country_code: "CO-ANT-CAR145",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Caramanta",
    city_code: "145",
    code: "05145",
  },
  {
    id: 32,
    country_name: "CO-ANT-CAR147",
    country_code: "CO-ANT-CAR147",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Carepa",
    city_code: "147",
    code: "05147",
  },
  {
    id: 33,
    country_name: "CO-ANT-EL 148",
    country_code: "CO-ANT-EL 148",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "El Carmen De Viboral",
    city_code: "148",
    code: "05148",
  },
  {
    id: 34,
    country_name: "CO-ANT-CAR150",
    country_code: "CO-ANT-CAR150",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Carolina",
    city_code: "150",
    code: "05150",
  },
  {
    id: 35,
    country_name: "CO-ANT-CAU154",
    country_code: "CO-ANT-CAU154",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Caucasia",
    city_code: "154",
    code: "05154",
  },
  {
    id: 36,
    country_name: "CO-ANT-CHI172",
    country_code: "CO-ANT-CHI172",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Chigorodó",
    city_code: "172",
    code: "05172",
  },
  {
    id: 37,
    country_name: "CO-ANT-CIS190",
    country_code: "CO-ANT-CIS190",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Cisneros",
    city_code: "190",
    code: "05190",
  },
  {
    id: 38,
    country_name: "CO-ANT-COC197",
    country_code: "CO-ANT-COC197",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Cocorná",
    city_code: "197",
    code: "05197",
  },
  {
    id: 39,
    country_name: "CO-ANT-CON206",
    country_code: "CO-ANT-CON206",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Concepción",
    city_code: "206",
    code: "05206",
  },
  {
    id: 40,
    country_name: "CO-ANT-CON209",
    country_code: "CO-ANT-CON209",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Concordia",
    city_code: "209",
    code: "05209",
  },
  {
    id: 41,
    country_name: "CO-ANT-COP212",
    country_code: "CO-ANT-COP212",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Copacabana",
    city_code: "212",
    code: "05212",
  },
  {
    id: 42,
    country_name: "CO-ANT-DAB234",
    country_code: "CO-ANT-DAB234",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Dabeiba",
    city_code: "234",
    code: "05234",
  },
  {
    id: 43,
    country_name: "CO-ANT-DON237",
    country_code: "CO-ANT-DON237",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Donmatías",
    city_code: "237",
    code: "05237",
  },
  {
    id: 44,
    country_name: "CO-ANT-EBE240",
    country_code: "CO-ANT-EBE240",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Ebéjico",
    city_code: "240",
    code: "05240",
  },
  {
    id: 45,
    country_name: "CO-ANT-EL 250",
    country_code: "CO-ANT-EL 250",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "El Bagre",
    city_code: "250",
    code: "05250",
  },
  {
    id: 46,
    country_name: "CO-ANT-ENT264",
    country_code: "CO-ANT-ENT264",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Entrerríos",
    city_code: "264",
    code: "05264",
  },
  {
    id: 47,
    country_name: "CO-ANT-ENV266",
    country_code: "CO-ANT-ENV266",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Envigado",
    city_code: "266",
    code: "05266",
  },
  {
    id: 48,
    country_name: "CO-ANT-FRE282",
    country_code: "CO-ANT-FRE282",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Fredonia",
    city_code: "282",
    code: "05282",
  },
  {
    id: 49,
    country_name: "CO-ANT-FRO284",
    country_code: "CO-ANT-FRO284",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Frontino",
    city_code: "284",
    code: "05284",
  },
  {
    id: 50,
    country_name: "CO-ANT-GIR306",
    country_code: "CO-ANT-GIR306",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Giraldo",
    city_code: "306",
    code: "05306",
  },
  {
    id: 51,
    country_name: "CO-ANT-GIR308",
    country_code: "CO-ANT-GIR308",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Girardota",
    city_code: "308",
    code: "05308",
  },
  {
    id: 52,
    country_name: "CO-ANT-GOM310",
    country_code: "CO-ANT-GOM310",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Gómez Plata",
    city_code: "310",
    code: "05310",
  },
  {
    id: 53,
    country_name: "CO-ANT-GRA313",
    country_code: "CO-ANT-GRA313",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Granada",
    city_code: "313",
    code: "05313",
  },
  {
    id: 54,
    country_name: "CO-ANT-GUA315",
    country_code: "CO-ANT-GUA315",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Guadalupe",
    city_code: "315",
    code: "05315",
  },
  {
    id: 55,
    country_name: "CO-ANT-GUA318",
    country_code: "CO-ANT-GUA318",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Guarne",
    city_code: "318",
    code: "05318",
  },
  {
    id: 56,
    country_name: "CO-ANT-GUA321",
    country_code: "CO-ANT-GUA321",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Guatapé",
    city_code: "321",
    code: "05321",
  },
  {
    id: 57,
    country_name: "CO-ANT-HEL347",
    country_code: "CO-ANT-HEL347",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Heliconia",
    city_code: "347",
    code: "05347",
  },
  {
    id: 58,
    country_name: "CO-ANT-HIS353",
    country_code: "CO-ANT-HIS353",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Hispania",
    city_code: "353",
    code: "05353",
  },
  {
    id: 59,
    country_name: "CO-ANT-ITA360",
    country_code: "CO-ANT-ITA360",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Itagüí",
    city_code: "360",
    code: "05360",
  },
  {
    id: 60,
    country_name: "CO-ANT-ITU361",
    country_code: "CO-ANT-ITU361",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Ituango",
    city_code: "361",
    code: "05361",
  },
  {
    id: 61,
    country_name: "CO-ANT-JAR364",
    country_code: "CO-ANT-JAR364",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Jardín",
    city_code: "364",
    code: "05364",
  },
  {
    id: 62,
    country_name: "CO-ANT-JER368",
    country_code: "CO-ANT-JER368",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Jericó",
    city_code: "368",
    code: "05368",
  },
  {
    id: 63,
    country_name: "CO-ANT-LA 376",
    country_code: "CO-ANT-LA 376",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "La Ceja",
    city_code: "376",
    code: "05376",
  },
  {
    id: 64,
    country_name: "CO-ANT-LA 380",
    country_code: "CO-ANT-LA 380",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "La Estrella",
    city_code: "380",
    code: "05380",
  },
  {
    id: 65,
    country_name: "CO-ANT-LA 390",
    country_code: "CO-ANT-LA 390",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "La Pintada",
    city_code: "390",
    code: "05390",
  },
  {
    id: 66,
    country_name: "CO-ANT-LA 400",
    country_code: "CO-ANT-LA 400",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "La Unión",
    city_code: "400",
    code: "05400",
  },
  {
    id: 67,
    country_name: "CO-ANT-LIB411",
    country_code: "CO-ANT-LIB411",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Liborina",
    city_code: "411",
    code: "05411",
  },
  {
    id: 68,
    country_name: "CO-ANT-MAC425",
    country_code: "CO-ANT-MAC425",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Maceo",
    city_code: "425",
    code: "05425",
  },
  {
    id: 69,
    country_name: "CO-ANT-MAR440",
    country_code: "CO-ANT-MAR440",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Marinilla",
    city_code: "440",
    code: "05440",
  },
  {
    id: 70,
    country_name: "CO-ANT-MON467",
    country_code: "CO-ANT-MON467",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Montebello",
    city_code: "467",
    code: "05467",
  },
  {
    id: 71,
    country_name: "CO-ANT-MUR475",
    country_code: "CO-ANT-MUR475",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Murindó",
    city_code: "475",
    code: "05475",
  },
  {
    id: 72,
    country_name: "CO-ANT-MUT480",
    country_code: "CO-ANT-MUT480",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Mutatá",
    city_code: "480",
    code: "05480",
  },
  {
    id: 73,
    country_name: "CO-ANT-NAR483",
    country_code: "CO-ANT-NAR483",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Nariño",
    city_code: "483",
    code: "05483",
  },
  {
    id: 74,
    country_name: "CO-ANT-NEC490",
    country_code: "CO-ANT-NEC490",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Necoclí",
    city_code: "490",
    code: "05490",
  },
  {
    id: 75,
    country_name: "CO-ANT-NEC495",
    country_code: "CO-ANT-NEC495",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Nechí",
    city_code: "495",
    code: "05495",
  },
  {
    id: 76,
    country_name: "CO-ANT-OLA501",
    country_code: "CO-ANT-OLA501",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Olaya",
    city_code: "501",
    code: "05501",
  },
  {
    id: 77,
    country_name: "CO-ANT-PE?541",
    country_code: "CO-ANT-PE?541",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Peñol",
    city_code: "541",
    code: "05541",
  },
  {
    id: 78,
    country_name: "CO-ANT-PEQ543",
    country_code: "CO-ANT-PEQ543",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Peque",
    city_code: "543",
    code: "05543",
  },
  {
    id: 79,
    country_name: "CO-ANT-PUE576",
    country_code: "CO-ANT-PUE576",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Pueblorrico",
    city_code: "576",
    code: "05576",
  },
  {
    id: 80,
    country_name: "CO-ANT-PUE579",
    country_code: "CO-ANT-PUE579",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Puerto Berrío",
    city_code: "579",
    code: "05579",
  },
  {
    id: 81,
    country_name: "CO-ANT-PUE585",
    country_code: "CO-ANT-PUE585",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Puerto Nare",
    city_code: "585",
    code: "05585",
  },
  {
    id: 82,
    country_name: "CO-ANT-PUE591",
    country_code: "CO-ANT-PUE591",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Puerto Triunfo",
    city_code: "591",
    code: "05591",
  },
  {
    id: 83,
    country_name: "CO-ANT-REM604",
    country_code: "CO-ANT-REM604",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Remedios",
    city_code: "604",
    code: "05604",
  },
  {
    id: 84,
    country_name: "CO-ANT-RET607",
    country_code: "CO-ANT-RET607",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Retiro",
    city_code: "607",
    code: "05607",
  },
  {
    id: 85,
    country_name: "CO-ANT-RIO615",
    country_code: "CO-ANT-RIO615",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Rionegro",
    city_code: "615",
    code: "05615",
  },
  {
    id: 86,
    country_name: "CO-ANT-SAB628",
    country_code: "CO-ANT-SAB628",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Sabanalarga",
    city_code: "628",
    code: "05628",
  },
  {
    id: 87,
    country_name: "CO-ANT-SAB631",
    country_code: "CO-ANT-SAB631",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Sabaneta",
    city_code: "631",
    code: "05631",
  },
  {
    id: 88,
    country_name: "CO-ANT-SAL642",
    country_code: "CO-ANT-SAL642",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Salgar",
    city_code: "642",
    code: "05642",
  },
  {
    id: 89,
    country_name: "CO-ANT-SAN647",
    country_code: "CO-ANT-SAN647",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "San Andrés De Cuerquía",
    city_code: "647",
    code: "05647",
  },
  {
    id: 90,
    country_name: "CO-ANT-SAN649",
    country_code: "CO-ANT-SAN649",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "San Carlos",
    city_code: "649",
    code: "05649",
  },
  {
    id: 91,
    country_name: "CO-ANT-SAN652",
    country_code: "CO-ANT-SAN652",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "San Francisco",
    city_code: "652",
    code: "05652",
  },
  {
    id: 92,
    country_name: "CO-ANT-SAN656",
    country_code: "CO-ANT-SAN656",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "San Jerónimo",
    city_code: "656",
    code: "05656",
  },
  {
    id: 93,
    country_name: "CO-ANT-SAN658",
    country_code: "CO-ANT-SAN658",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "San José De La Montaña",
    city_code: "658",
    code: "05658",
  },
  {
    id: 94,
    country_name: "CO-ANT-SAN659",
    country_code: "CO-ANT-SAN659",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "San Juan De Urabá",
    city_code: "659",
    code: "05659",
  },
  {
    id: 95,
    country_name: "CO-ANT-SAN660",
    country_code: "CO-ANT-SAN660",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "San Luis",
    city_code: "660",
    code: "05660",
  },
  {
    id: 96,
    country_name: "CO-ANT-SAN664",
    country_code: "CO-ANT-SAN664",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "San Pedro De Los Milagros",
    city_code: "664",
    code: "05664",
  },
  {
    id: 97,
    country_name: "CO-ANT-SAN665",
    country_code: "CO-ANT-SAN665",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "San Pedro De Urabá",
    city_code: "665",
    code: "05665",
  },
  {
    id: 98,
    country_name: "CO-ANT-SAN667",
    country_code: "CO-ANT-SAN667",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "San Rafael",
    city_code: "667",
    code: "05667",
  },
  {
    id: 99,
    country_name: "CO-ANT-SAN670",
    country_code: "CO-ANT-SAN670",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "San Roque",
    city_code: "670",
    code: "05670",
  },
  {
    id: 100,
    country_name: "CO-ANT-SAN674",
    country_code: "CO-ANT-SAN674",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "San Vicente Ferrer",
    city_code: "674",
    code: "05674",
  },
  {
    id: 101,
    country_name: "CO-ANT-SAN679",
    country_code: "CO-ANT-SAN679",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Santa Bárbara",
    city_code: "679",
    code: "05679",
  },
  {
    id: 102,
    country_name: "CO-ANT-SAN686",
    country_code: "CO-ANT-SAN686",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Santa Rosa De Osos",
    city_code: "686",
    code: "05686",
  },
  {
    id: 103,
    country_name: "CO-ANT-SAN690",
    country_code: "CO-ANT-SAN690",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Santo Domingo",
    city_code: "690",
    code: "05690",
  },
  {
    id: 104,
    country_name: "CO-ANT-EL 697",
    country_code: "CO-ANT-EL 697",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "El Santuario",
    city_code: "697",
    code: "05697",
  },
  {
    id: 105,
    country_name: "CO-ANT-SEG736",
    country_code: "CO-ANT-SEG736",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Segovia",
    city_code: "736",
    code: "05736",
  },
  {
    id: 106,
    country_name: "CO-ANT-SON756",
    country_code: "CO-ANT-SON756",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Sonsón",
    city_code: "756",
    code: "05756",
  },
  {
    id: 107,
    country_name: "CO-ANT-SOP761",
    country_code: "CO-ANT-SOP761",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Sopetrán",
    city_code: "761",
    code: "05761",
  },
  {
    id: 108,
    country_name: "CO-ANT-TAM789",
    country_code: "CO-ANT-TAM789",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Támesis",
    city_code: "789",
    code: "05789",
  },
  {
    id: 109,
    country_name: "CO-ANT-TAR790",
    country_code: "CO-ANT-TAR790",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Tarazá",
    city_code: "790",
    code: "05790",
  },
  {
    id: 110,
    country_name: "CO-ANT-TAR792",
    country_code: "CO-ANT-TAR792",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Tarso",
    city_code: "792",
    code: "05792",
  },
  {
    id: 111,
    country_name: "CO-ANT-TIT809",
    country_code: "CO-ANT-TIT809",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Titiribí",
    city_code: "809",
    code: "05809",
  },
  {
    id: 112,
    country_name: "CO-ANT-TOL819",
    country_code: "CO-ANT-TOL819",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Toledo",
    city_code: "819",
    code: "05819",
  },
  {
    id: 113,
    country_name: "CO-ANT-TUR837",
    country_code: "CO-ANT-TUR837",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Turbo",
    city_code: "837",
    code: "05837",
  },
  {
    id: 114,
    country_name: "CO-ANT-URA842",
    country_code: "CO-ANT-URA842",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Uramita",
    city_code: "842",
    code: "05842",
  },
  {
    id: 115,
    country_name: "CO-ANT-URR847",
    country_code: "CO-ANT-URR847",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Urrao",
    city_code: "847",
    code: "05847",
  },
  {
    id: 116,
    country_name: "CO-ANT-VAL854",
    country_code: "CO-ANT-VAL854",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Valdivia",
    city_code: "854",
    code: "05854",
  },
  {
    id: 117,
    country_name: "CO-ANT-VAL856",
    country_code: "CO-ANT-VAL856",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Valparaíso",
    city_code: "856",
    code: "05856",
  },
  {
    id: 118,
    country_name: "CO-ANT-VEG858",
    country_code: "CO-ANT-VEG858",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Vegachí",
    city_code: "858",
    code: "05858",
  },
  {
    id: 119,
    country_name: "CO-ANT-VEN861",
    country_code: "CO-ANT-VEN861",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Venecia",
    city_code: "861",
    code: "05861",
  },
  {
    id: 120,
    country_name: "CO-ANT-VIG873",
    country_code: "CO-ANT-VIG873",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Vigía Del Fuerte",
    city_code: "873",
    code: "05873",
  },
  {
    id: 121,
    country_name: "CO-ANT-YAL885",
    country_code: "CO-ANT-YAL885",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Yalí",
    city_code: "885",
    code: "05885",
  },
  {
    id: 122,
    country_name: "CO-ANT-YAR887",
    country_code: "CO-ANT-YAR887",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Yarumal",
    city_code: "887",
    code: "05887",
  },
  {
    id: 123,
    country_name: "CO-ANT-YOL890",
    country_code: "CO-ANT-YOL890",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Yolombó",
    city_code: "890",
    code: "05890",
  },
  {
    id: 124,
    country_name: "CO-ANT-YON893",
    country_code: "CO-ANT-YON893",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Yondó",
    city_code: "893",
    code: "05893",
  },
  {
    id: 125,
    country_name: "CO-ANT-ZAR895",
    country_code: "CO-ANT-ZAR895",
    state_name: "Antioquia",
    state_code: "5",
    city_name: "Zaragoza",
    city_code: "895",
    code: "05895",
  },
  {
    id: 126,
    country_name: "CO-ATL-BAR1",
    country_code: "CO-ATL-BAR1",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Barranquilla",
    city_code: "1",
    code: "08001",
  },
  {
    id: 127,
    country_name: "CO-ATL-BAR78",
    country_code: "CO-ATL-BAR78",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Baranoa",
    city_code: "78",
    code: "08078",
  },
  {
    id: 128,
    country_name: "CO-ATL-CAM137",
    country_code: "CO-ATL-CAM137",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Campo De La Cruz",
    city_code: "137",
    code: "08137",
  },
  {
    id: 129,
    country_name: "CO-ATL-CAN141",
    country_code: "CO-ATL-CAN141",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Candelaria",
    city_code: "141",
    code: "08141",
  },
  {
    id: 130,
    country_name: "CO-ATL-GAL296",
    country_code: "CO-ATL-GAL296",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Galapa",
    city_code: "296",
    code: "08296",
  },
  {
    id: 131,
    country_name: "CO-ATL-JUA372",
    country_code: "CO-ATL-JUA372",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Juan De Acosta",
    city_code: "372",
    code: "08372",
  },
  {
    id: 132,
    country_name: "CO-ATL-LUR421",
    country_code: "CO-ATL-LUR421",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Luruaco",
    city_code: "421",
    code: "08421",
  },
  {
    id: 133,
    country_name: "CO-ATL-MAL433",
    country_code: "CO-ATL-MAL433",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Malambo",
    city_code: "433",
    code: "08433",
  },
  {
    id: 134,
    country_name: "CO-ATL-MAN436",
    country_code: "CO-ATL-MAN436",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Manatí",
    city_code: "436",
    code: "08436",
  },
  {
    id: 135,
    country_name: "CO-ATL-PAL520",
    country_code: "CO-ATL-PAL520",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Palmar De Varela",
    city_code: "520",
    code: "08520",
  },
  {
    id: 136,
    country_name: "CO-ATL-PIO549",
    country_code: "CO-ATL-PIO549",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Piojó",
    city_code: "549",
    code: "08549",
  },
  {
    id: 137,
    country_name: "CO-ATL-POL558",
    country_code: "CO-ATL-POL558",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Polonuevo",
    city_code: "558",
    code: "08558",
  },
  {
    id: 138,
    country_name: "CO-ATL-PON560",
    country_code: "CO-ATL-PON560",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Ponedera",
    city_code: "560",
    code: "08560",
  },
  {
    id: 139,
    country_name: "CO-ATL-PUE573",
    country_code: "CO-ATL-PUE573",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Puerto Colombia",
    city_code: "573",
    code: "08573",
  },
  {
    id: 140,
    country_name: "CO-ATL-REP606",
    country_code: "CO-ATL-REP606",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Repelón",
    city_code: "606",
    code: "08606",
  },
  {
    id: 141,
    country_name: "CO-ATL-SAB634",
    country_code: "CO-ATL-SAB634",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Sabanagrande",
    city_code: "634",
    code: "08634",
  },
  {
    id: 142,
    country_name: "CO-ATL-SAB638",
    country_code: "CO-ATL-SAB638",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Sabanalarga",
    city_code: "638",
    code: "08638",
  },
  {
    id: 143,
    country_name: "CO-ATL-SAN675",
    country_code: "CO-ATL-SAN675",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Santa Lucía",
    city_code: "675",
    code: "08675",
  },
  {
    id: 144,
    country_name: "CO-ATL-SAN685",
    country_code: "CO-ATL-SAN685",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Santo Tomás",
    city_code: "685",
    code: "08685",
  },
  {
    id: 145,
    country_name: "CO-ATL-SOL758",
    country_code: "CO-ATL-SOL758",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Soledad",
    city_code: "758",
    code: "08758",
  },
  {
    id: 146,
    country_name: "CO-ATL-SUA770",
    country_code: "CO-ATL-SUA770",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Suan",
    city_code: "770",
    code: "08770",
  },
  {
    id: 147,
    country_name: "CO-ATL-TUB832",
    country_code: "CO-ATL-TUB832",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Tubará",
    city_code: "832",
    code: "08832",
  },
  {
    id: 148,
    country_name: "CO-ATL-USI849",
    country_code: "CO-ATL-USI849",
    state_name: "Atlántico",
    state_code: "8",
    city_name: "Usiacurí",
    city_code: "849",
    code: "08849",
  },
  {
    id: 149,
    country_name: "CO-BOG-BOG1",
    country_code: "CO-BOG-BOG1",
    state_name: "Bogotá",
    state_code: "11",
    city_name: "Bogotá D.C.",
    city_code: "1",
    code: "11001",
  },
  {
    id: 150,
    country_name: "CO-BOL-CAR1",
    country_code: "CO-BOL-CAR1",
    state_name: "Bolivar",
    state_code: "13",
    city_name: "Cartagena De Indias",
    city_code: "1",
    code: "13001",
  },
  {
    id: 151,
    country_name: "CO-BOL-ACH6",
    country_code: "CO-BOL-ACH6",
    state_name: "Bolivar",
    state_code: "13",
    city_name: "Achí",
    city_code: "6",
    code: "13006",
  },
  {
    id: 152,
    country_name: "CO-BOL-ALT30",
    country_code: "CO-BOL-ALT30",
    state_name: "Bolivar",
    state_code: "13",
    city_name: "Altos del rosario",
    city_code: "30",
    code: "13030",
  },
  {
    id: 153,
    country_name: "CO-BOL-ARE42",
    country_code: "CO-BOL-ARE42",
    state_name: "Bolivar",
    state_code: "13",
    city_name: "Arenal",
    city_code: "42",
    code: "13042",
  },
  {
    id: 154,
    country_name: "CO-BOL-ARJ52",
    country_code: "CO-BOL-ARJ52",
    state_name: "Bolivar",
    state_code: "13",
    city_name: "Arjona",
    city_code: "52",
    code: "13052",
  },
  {
    id: 155,
    country_name: "CO-BOL-ARR62",
    country_code: "CO-BOL-ARR62",
    state_name: "Bolivar",
    state_code: "13",
    city_name: "\t Arroyo hondo",
    city_code: "62",
    code: "13062",
  },
  {
    id: 156,
    country_name: "CO-BOL-BAR74",
    country_code: "CO-BOL-BAR74",
    state_name: "Bolivar",
    state_code: "13",
    city_name: "Barranco de loba",
    city_code: "74",
    code: "13074",
  },
  {
    id: 157,
    country_name: "CO-BOL-CAL140",
    country_code: "CO-BOL-CAL140",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Calamar",
    city_code: "140",
    code: "13140",
  },
  {
    id: 158,
    country_name: "CO-BOL-CAN160",
    country_code: "CO-BOL-CAN160",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Cantagallo",
    city_code: "160",
    code: "13160",
  },
  {
    id: 159,
    country_name: "CO-BOL-CIC188",
    country_code: "CO-BOL-CIC188",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Cicuco",
    city_code: "188",
    code: "13188",
  },
  {
    id: 160,
    country_name: "CO-BOL-COR212",
    country_code: "CO-BOL-COR212",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Córdoba",
    city_code: "212",
    code: "13212",
  },
  {
    id: 161,
    country_name: "CO-BOL-CLE222",
    country_code: "CO-BOL-CLE222",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Clemencia",
    city_code: "222",
    code: "13222",
  },
  {
    id: 162,
    country_name: "CO-BOL-EL 244",
    country_code: "CO-BOL-EL 244",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "El Carmen De Bolívar",
    city_code: "244",
    code: "13244",
  },
  {
    id: 163,
    country_name: "CO-BOL-EL 248",
    country_code: "CO-BOL-EL 248",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "El Guamo",
    city_code: "248",
    code: "13248",
  },
  {
    id: 164,
    country_name: "CO-BOL-EL 268",
    country_code: "CO-BOL-EL 268",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "El Peñón",
    city_code: "268",
    code: "13268",
  },
  {
    id: 165,
    country_name: "CO-BOL-HAT300",
    country_code: "CO-BOL-HAT300",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Hatillo De Loba",
    city_code: "300",
    code: "13300",
  },
  {
    id: 166,
    country_name: "CO-BOL-MAG430",
    country_code: "CO-BOL-MAG430",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Magangué",
    city_code: "430",
    code: "13430",
  },
  {
    id: 167,
    country_name: "CO-BOL-MAH433",
    country_code: "CO-BOL-MAH433",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Mahates",
    city_code: "433",
    code: "13433",
  },
  {
    id: 168,
    country_name: "CO-BOL-MAR440",
    country_code: "CO-BOL-MAR440",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Margarita",
    city_code: "440",
    code: "13440",
  },
  {
    id: 169,
    country_name: "CO-BOL-MAR442",
    country_code: "CO-BOL-MAR442",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "María La Baja",
    city_code: "442",
    code: "13442",
  },
  {
    id: 170,
    country_name: "CO-BOL-MON458",
    country_code: "CO-BOL-MON458",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Montecristo",
    city_code: "458",
    code: "13458",
  },
  {
    id: 171,
    country_name: "CO-BOL-MOM468",
    country_code: "CO-BOL-MOM468",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Mompós",
    city_code: "468",
    code: "13468",
  },
  {
    id: 172,
    country_name: "CO-BOL-NOR490",
    country_code: "CO-BOL-NOR490",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Norosí",
    city_code: "490",
    code: "13490",
  },
  {
    id: 173,
    country_name: "CO-BOL-MOR473",
    country_code: "CO-BOL-MOR473",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Morales",
    city_code: "473",
    code: "13473",
  },
  {
    id: 174,
    country_name: "CO-BOL-PIN549",
    country_code: "CO-BOL-PIN549",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Pinillos",
    city_code: "549",
    code: "13549",
  },
  {
    id: 175,
    country_name: "CO-BOL-REG580",
    country_code: "CO-BOL-REG580",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Regidor",
    city_code: "580",
    code: "13580",
  },
  {
    id: 176,
    country_name: "CO-BOL-RIO600",
    country_code: "CO-BOL-RIO600",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Río Viejo",
    city_code: "600",
    code: "13600",
  },
  {
    id: 177,
    country_name: "CO-BOL-SAN620",
    country_code: "CO-BOL-SAN620",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "San Cristóbal",
    city_code: "620",
    code: "13620",
  },
  {
    id: 178,
    country_name: "CO-BOL-SAN647",
    country_code: "CO-BOL-SAN647",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "San Estanislao",
    city_code: "647",
    code: "13647",
  },
  {
    id: 179,
    country_name: "CO-BOL-SAN650",
    country_code: "CO-BOL-SAN650",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "San Fernando",
    city_code: "650",
    code: "13650",
  },
  {
    id: 180,
    country_name: "CO-BOL-SAN654",
    country_code: "CO-BOL-SAN654",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "San Jacinto",
    city_code: "654",
    code: "13654",
  },
  {
    id: 181,
    country_name: "CO-BOL-SAN655",
    country_code: "CO-BOL-SAN655",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "San Jacinto Del Cauca",
    city_code: "655",
    code: "13655",
  },
  {
    id: 182,
    country_name: "CO-BOL-SAN657",
    country_code: "CO-BOL-SAN657",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "San Juan Nepomuceno",
    city_code: "657",
    code: "13657",
  },
  {
    id: 183,
    country_name: "CO-BOL-SAN667",
    country_code: "CO-BOL-SAN667",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "San Martín De Loba",
    city_code: "667",
    code: "13667",
  },
  {
    id: 184,
    country_name: "CO-BOL-SAN670",
    country_code: "CO-BOL-SAN670",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "San Pablo",
    city_code: "670",
    code: "13670",
  },
  {
    id: 185,
    country_name: "CO-BOL-SAN673",
    country_code: "CO-BOL-SAN673",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Santa Catalina",
    city_code: "673",
    code: "13673",
  },
  {
    id: 186,
    country_name: "CO-BOL-SAN683",
    country_code: "CO-BOL-SAN683",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Santa Rosa",
    city_code: "683",
    code: "13683",
  },
  {
    id: 187,
    country_name: "CO-BOL-SAN688",
    country_code: "CO-BOL-SAN688",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Santa Rosa Del Sur",
    city_code: "688",
    code: "13688",
  },
  {
    id: 188,
    country_name: "CO-BOL-SIM744",
    country_code: "CO-BOL-SIM744",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Simití",
    city_code: "744",
    code: "13744",
  },
  {
    id: 189,
    country_name: "CO-BOL-SOP760",
    country_code: "CO-BOL-SOP760",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Soplaviento",
    city_code: "760",
    code: "13760",
  },
  {
    id: 190,
    country_name: "CO-BOL-TAL780",
    country_code: "CO-BOL-TAL780",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Talaigua Nuevo",
    city_code: "780",
    code: "13780",
  },
  {
    id: 191,
    country_name: "CO-BOL-TIQ810",
    country_code: "CO-BOL-TIQ810",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Tiquisio",
    city_code: "810",
    code: "13810",
  },
  {
    id: 192,
    country_name: "CO-BOL-TUR836",
    country_code: "CO-BOL-TUR836",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Turbaco",
    city_code: "836",
    code: "13836",
  },
  {
    id: 193,
    country_name: "CO-BOL-TUR838",
    country_code: "CO-BOL-TUR838",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Turbaná",
    city_code: "838",
    code: "13838",
  },
  {
    id: 194,
    country_name: "CO-BOL-VIL873",
    country_code: "CO-BOL-VIL873",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Villanueva",
    city_code: "873",
    code: "13873",
  },
  {
    id: 195,
    country_name: "CO-BOL-ZAM894",
    country_code: "CO-BOL-ZAM894",
    state_name: "Bolívar",
    state_code: "13",
    city_name: "Zambrano",
    city_code: "894",
    code: "13894",
  },
  {
    id: 196,
    country_name: "CO-BOY-TUN1",
    country_code: "CO-BOY-TUN1",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Tunja",
    city_code: "1",
    code: "15001",
  },
  {
    id: 197,
    country_name: "CO-BOY-ALM22",
    country_code: "CO-BOY-ALM22",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Almeida",
    city_code: "22",
    code: "15022",
  },
  {
    id: 198,
    country_name: "CO-BOY-AQU47",
    country_code: "CO-BOY-AQU47",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Aquitania",
    city_code: "47",
    code: "15047",
  },
  {
    id: 199,
    country_name: "CO-BOY-ARC51",
    country_code: "CO-BOY-ARC51",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Arcabuco",
    city_code: "51",
    code: "15051",
  },
  {
    id: 200,
    country_name: "CO-BOY-BEL87",
    country_code: "CO-BOY-BEL87",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Belén",
    city_code: "87",
    code: "15087",
  },
  {
    id: 201,
    country_name: "CO-BOY-BER90",
    country_code: "CO-BOY-BER90",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Berbeo",
    city_code: "90",
    code: "15090",
  },
  {
    id: 202,
    country_name: "CO-BOY-BET92",
    country_code: "CO-BOY-BET92",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Beteitiva",
    city_code: "92",
    code: "15092",
  },
  {
    id: 203,
    country_name: "CO-BOY-BOA97",
    country_code: "CO-BOY-BOA97",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Boavita",
    city_code: "97",
    code: "15097",
  },
  {
    id: 204,
    country_name: "CO-BOY-BOY104",
    country_code: "CO-BOY-BOY104",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Boyacá",
    city_code: "104",
    code: "15104",
  },
  {
    id: 205,
    country_name: "CO-BOY-BRI106",
    country_code: "CO-BOY-BRI106",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Briceño",
    city_code: "106",
    code: "15106",
  },
  {
    id: 206,
    country_name: "CO-BOY-BUE109",
    country_code: "CO-BOY-BUE109",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Buenavista",
    city_code: "109",
    code: "15109",
  },
  {
    id: 207,
    country_name: "CO-BOY-BUS114",
    country_code: "CO-BOY-BUS114",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Busbanzá",
    city_code: "114",
    code: "15114",
  },
  {
    id: 208,
    country_name: "CO-BOY-CAL131",
    country_code: "CO-BOY-CAL131",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Caldas",
    city_code: "131",
    code: "15131",
  },
  {
    id: 209,
    country_name: "CO-BOY-CAM135",
    country_code: "CO-BOY-CAM135",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Campohermoso",
    city_code: "135",
    code: "15135",
  },
  {
    id: 210,
    country_name: "CO-BOY-CER162",
    country_code: "CO-BOY-CER162",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Cerinza",
    city_code: "162",
    code: "15162",
  },
  {
    id: 211,
    country_name: "CO-BOY-CHI172",
    country_code: "CO-BOY-CHI172",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Chinavita",
    city_code: "172",
    code: "15172",
  },
  {
    id: 212,
    country_name: "CO-BOY-CHI176",
    country_code: "CO-BOY-CHI176",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Chiquinquirá",
    city_code: "176",
    code: "15176",
  },
  {
    id: 213,
    country_name: "CO-BOY-CHI180",
    country_code: "CO-BOY-CHI180",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Chiscas",
    city_code: "180",
    code: "15180",
  },
  {
    id: 214,
    country_name: "CO-BOY-CHI183",
    country_code: "CO-BOY-CHI183",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Chita",
    city_code: "183",
    code: "15183",
  },
  {
    id: 215,
    country_name: "CO-BOY-CHI185",
    country_code: "CO-BOY-CHI185",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Chitaraque",
    city_code: "185",
    code: "15185",
  },
  {
    id: 216,
    country_name: "CO-BOY-CHI187",
    country_code: "CO-BOY-CHI187",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Chivatá",
    city_code: "187",
    code: "15187",
  },
  {
    id: 217,
    country_name: "CO-BOY-CIE189",
    country_code: "CO-BOY-CIE189",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Ciénega",
    city_code: "189",
    code: "15189",
  },
  {
    id: 218,
    country_name: "CO-BOY-COM204",
    country_code: "CO-BOY-COM204",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Cómbita",
    city_code: "204",
    code: "15204",
  },
  {
    id: 219,
    country_name: "CO-BOY-COP212",
    country_code: "CO-BOY-COP212",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Coper",
    city_code: "212",
    code: "15212",
  },
  {
    id: 220,
    country_name: "CO-BOY-COR215",
    country_code: "CO-BOY-COR215",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Corrales",
    city_code: "215",
    code: "15215",
  },
  {
    id: 221,
    country_name: "CO-BOY-COV218",
    country_code: "CO-BOY-COV218",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Covarachía",
    city_code: "218",
    code: "15218",
  },
  {
    id: 222,
    country_name: "CO-BOY-CUB223",
    country_code: "CO-BOY-CUB223",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Cubará",
    city_code: "223",
    code: "15223",
  },
  {
    id: 223,
    country_name: "CO-BOY-CUC224",
    country_code: "CO-BOY-CUC224",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Cucaita",
    city_code: "224",
    code: "15224",
  },
  {
    id: 224,
    country_name: "CO-BOY-CUI226",
    country_code: "CO-BOY-CUI226",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Cuítiva",
    city_code: "226",
    code: "15226",
  },
  {
    id: 225,
    country_name: "CO-BOY-CHI232",
    country_code: "CO-BOY-CHI232",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Chíquiza",
    city_code: "232",
    code: "15232",
  },
  {
    id: 226,
    country_name: "CO-BOY-CHI236",
    country_code: "CO-BOY-CHI236",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Chivor",
    city_code: "236",
    code: "15236",
  },
  {
    id: 227,
    country_name: "CO-BOY-DUI238",
    country_code: "CO-BOY-DUI238",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Duitama",
    city_code: "238",
    code: "15238",
  },
  {
    id: 228,
    country_name: "CO-BOY-EL 244",
    country_code: "CO-BOY-EL 244",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "El Cocuy",
    city_code: "244",
    code: "15244",
  },
  {
    id: 229,
    country_name: "CO-BOY-EL 248",
    country_code: "CO-BOY-EL 248",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "El Espino",
    city_code: "248",
    code: "15248",
  },
  {
    id: 230,
    country_name: "CO-BOY-FIR272",
    country_code: "CO-BOY-FIR272",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Firavitoba",
    city_code: "272",
    code: "15272",
  },
  {
    id: 231,
    country_name: "CO-BOY-FLO276",
    country_code: "CO-BOY-FLO276",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Floresta",
    city_code: "276",
    code: "15276",
  },
  {
    id: 232,
    country_name: "CO-BOY-GAC293",
    country_code: "CO-BOY-GAC293",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Gachantivá",
    city_code: "293",
    code: "15293",
  },
  {
    id: 233,
    country_name: "CO-BOY-GAM296",
    country_code: "CO-BOY-GAM296",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Gámeza",
    city_code: "296",
    code: "15296",
  },
  {
    id: 234,
    country_name: "CO-BOY-GAR299",
    country_code: "CO-BOY-GAR299",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Garagoa",
    city_code: "299",
    code: "15299",
  },
  {
    id: 235,
    country_name: "CO-BOY-GUA317",
    country_code: "CO-BOY-GUA317",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Guacamayas",
    city_code: "317",
    code: "15317",
  },
  {
    id: 236,
    country_name: "CO-BOY-GUA322",
    country_code: "CO-BOY-GUA322",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Guateque",
    city_code: "322",
    code: "15322",
  },
  {
    id: 237,
    country_name: "CO-BOY-GUA325",
    country_code: "CO-BOY-GUA325",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Guayatá",
    city_code: "325",
    code: "15325",
  },
  {
    id: 238,
    country_name: "CO-BOY-GSI332",
    country_code: "CO-BOY-GSI332",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Güicán",
    city_code: "332",
    code: "15332",
  },
  {
    id: 239,
    country_name: "CO-BOY-IZA362",
    country_code: "CO-BOY-IZA362",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Iza",
    city_code: "362",
    code: "15362",
  },
  {
    id: 240,
    country_name: "CO-BOY-JEN367",
    country_code: "CO-BOY-JEN367",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Jenesano",
    city_code: "367",
    code: "15367",
  },
  {
    id: 241,
    country_name: "CO-BOY-JER368",
    country_code: "CO-BOY-JER368",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Jericó",
    city_code: "368",
    code: "15368",
  },
  {
    id: 242,
    country_name: "CO-BOY-LAB377",
    country_code: "CO-BOY-LAB377",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Labranzagrande",
    city_code: "377",
    code: "15377",
  },
  {
    id: 243,
    country_name: "CO-BOY-LA 380",
    country_code: "CO-BOY-LA 380",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "La Capilla",
    city_code: "380",
    code: "15380",
  },
  {
    id: 244,
    country_name: "CO-BOY-LA 401",
    country_code: "CO-BOY-LA 401",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "La Victoria",
    city_code: "401",
    code: "15401",
  },
  {
    id: 245,
    country_name: "CO-BOY-LA 403",
    country_code: "CO-BOY-LA 403",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "La Uvita",
    city_code: "403",
    code: "15403",
  },
  {
    id: 246,
    country_name: "CO-BOY-VIL407",
    country_code: "CO-BOY-VIL407",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Villa De Leyva",
    city_code: "407",
    code: "15407",
  },
  {
    id: 247,
    country_name: "CO-BOY-MAC425",
    country_code: "CO-BOY-MAC425",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Macanal",
    city_code: "425",
    code: "15425",
  },
  {
    id: 248,
    country_name: "CO-BOY-MAR442",
    country_code: "CO-BOY-MAR442",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Maripí",
    city_code: "442",
    code: "15442",
  },
  {
    id: 249,
    country_name: "CO-BOY-MIR455",
    country_code: "CO-BOY-MIR455",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Miraflores",
    city_code: "455",
    code: "15455",
  },
  {
    id: 250,
    country_name: "CO-BOY-MON464",
    country_code: "CO-BOY-MON464",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Mongua",
    city_code: "464",
    code: "15464",
  },
  {
    id: 251,
    country_name: "CO-BOY-MON466",
    country_code: "CO-BOY-MON466",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Monguí",
    city_code: "466",
    code: "15466",
  },
  {
    id: 252,
    country_name: "CO-BOY-MON469",
    country_code: "CO-BOY-MON469",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Moniquirá",
    city_code: "469",
    code: "15469",
  },
  {
    id: 253,
    country_name: "CO-BOY-MOT476",
    country_code: "CO-BOY-MOT476",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Motavita",
    city_code: "476",
    code: "15476",
  },
  {
    id: 254,
    country_name: "CO-BOY-MUZ480",
    country_code: "CO-BOY-MUZ480",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Muzo",
    city_code: "480",
    code: "15480",
  },
  {
    id: 255,
    country_name: "CO-BOY-NOB491",
    country_code: "CO-BOY-NOB491",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Nobsa",
    city_code: "491",
    code: "15491",
  },
  {
    id: 256,
    country_name: "CO-BOY-NUE494",
    country_code: "CO-BOY-NUE494",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Nuevo Colón",
    city_code: "494",
    code: "15494",
  },
  {
    id: 257,
    country_name: "CO-BOY-OIC500",
    country_code: "CO-BOY-OIC500",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Oicatá",
    city_code: "500",
    code: "15500",
  },
  {
    id: 258,
    country_name: "CO-BOY-OTA507",
    country_code: "CO-BOY-OTA507",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Otanche",
    city_code: "507",
    code: "15507",
  },
  {
    id: 259,
    country_name: "CO-BOY-PAC511",
    country_code: "CO-BOY-PAC511",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Pachavita",
    city_code: "511",
    code: "15511",
  },
  {
    id: 260,
    country_name: "CO-BOY-PAE514",
    country_code: "CO-BOY-PAE514",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Páez",
    city_code: "514",
    code: "15514",
  },
  {
    id: 261,
    country_name: "CO-BOY-PAI516",
    country_code: "CO-BOY-PAI516",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Paipa",
    city_code: "516",
    code: "15516",
  },
  {
    id: 262,
    country_name: "CO-BOY-PAJ518",
    country_code: "CO-BOY-PAJ518",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Pajarito",
    city_code: "518",
    code: "15518",
  },
  {
    id: 263,
    country_name: "CO-BOY-PAN522",
    country_code: "CO-BOY-PAN522",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Panqueba",
    city_code: "522",
    code: "15522",
  },
  {
    id: 264,
    country_name: "CO-BOY-PAU531",
    country_code: "CO-BOY-PAU531",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Pauna",
    city_code: "531",
    code: "15531",
  },
  {
    id: 265,
    country_name: "CO-BOY-PAY533",
    country_code: "CO-BOY-PAY533",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Paya",
    city_code: "533",
    code: "15533",
  },
  {
    id: 266,
    country_name: "CO-BOY-PAZ537",
    country_code: "CO-BOY-PAZ537",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Paz De Río",
    city_code: "537",
    code: "15537",
  },
  {
    id: 267,
    country_name: "CO-BOY-PES542",
    country_code: "CO-BOY-PES542",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Pesca",
    city_code: "542",
    code: "15542",
  },
  {
    id: 268,
    country_name: "CO-BOY-PIS550",
    country_code: "CO-BOY-PIS550",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Pisba",
    city_code: "550",
    code: "15550",
  },
  {
    id: 269,
    country_name: "CO-BOY-PUE572",
    country_code: "CO-BOY-PUE572",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Puerto Boyacá",
    city_code: "572",
    code: "15572",
  },
  {
    id: 270,
    country_name: "CO-BOY-QUI580",
    country_code: "CO-BOY-QUI580",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Quípama",
    city_code: "580",
    code: "15580",
  },
  {
    id: 271,
    country_name: "CO-BOY-RAM599",
    country_code: "CO-BOY-RAM599",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Ramiriquí",
    city_code: "599",
    code: "15599",
  },
  {
    id: 272,
    country_name: "CO-BOY-RAQ600",
    country_code: "CO-BOY-RAQ600",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Ráquira",
    city_code: "600",
    code: "15600",
  },
  {
    id: 273,
    country_name: "CO-BOY-RON621",
    country_code: "CO-BOY-RON621",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Rondón",
    city_code: "621",
    code: "15621",
  },
  {
    id: 274,
    country_name: "CO-BOY-SAB632",
    country_code: "CO-BOY-SAB632",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Saboyá",
    city_code: "632",
    code: "15632",
  },
  {
    id: 275,
    country_name: "CO-BOY-SAC638",
    country_code: "CO-BOY-SAC638",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Sáchica",
    city_code: "638",
    code: "15638",
  },
  {
    id: 276,
    country_name: "CO-BOY-SAM646",
    country_code: "CO-BOY-SAM646",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Samacá",
    city_code: "646",
    code: "15646",
  },
  {
    id: 277,
    country_name: "CO-BOY-SAN660",
    country_code: "CO-BOY-SAN660",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "San Eduardo",
    city_code: "660",
    code: "15660",
  },
  {
    id: 278,
    country_name: "CO-BOY-SAN664",
    country_code: "CO-BOY-SAN664",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "San José De Pare",
    city_code: "664",
    code: "15664",
  },
  {
    id: 279,
    country_name: "CO-BOY-SAN667",
    country_code: "CO-BOY-SAN667",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "San Luis De Gaceno",
    city_code: "667",
    code: "15667",
  },
  {
    id: 280,
    country_name: "CO-BOY-SAN673",
    country_code: "CO-BOY-SAN673",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "San Mateo",
    city_code: "673",
    code: "15673",
  },
  {
    id: 281,
    country_name: "CO-BOY-SAN676",
    country_code: "CO-BOY-SAN676",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "San Miguel De Sema",
    city_code: "676",
    code: "15676",
  },
  {
    id: 282,
    country_name: "CO-BOY-SAN681",
    country_code: "CO-BOY-SAN681",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "San Pablo De Borbur",
    city_code: "681",
    code: "15681",
  },
  {
    id: 283,
    country_name: "CO-BOY-SAN686",
    country_code: "CO-BOY-SAN686",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Santana",
    city_code: "686",
    code: "15686",
  },
  {
    id: 284,
    country_name: "CO-BOY-SAN690",
    country_code: "CO-BOY-SAN690",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Santa María",
    city_code: "690",
    code: "15690",
  },
  {
    id: 285,
    country_name: "CO-BOY-SAN693",
    country_code: "CO-BOY-SAN693",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Santa Rosa De Viterbo",
    city_code: "693",
    code: "15693",
  },
  {
    id: 286,
    country_name: "CO-BOY-SAN696",
    country_code: "CO-BOY-SAN696",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Santa Sofía",
    city_code: "696",
    code: "15696",
  },
  {
    id: 287,
    country_name: "CO-BOY-SAT720",
    country_code: "CO-BOY-SAT720",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Sativanorte",
    city_code: "720",
    code: "15720",
  },
  {
    id: 288,
    country_name: "CO-BOY-SAT723",
    country_code: "CO-BOY-SAT723",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Sativasur",
    city_code: "723",
    code: "15723",
  },
  {
    id: 289,
    country_name: "CO-BOY-SIA740",
    country_code: "CO-BOY-SIA740",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Siachoque",
    city_code: "740",
    code: "15740",
  },
  {
    id: 290,
    country_name: "CO-BOY-SOA753",
    country_code: "CO-BOY-SOA753",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Soatá",
    city_code: "753",
    code: "15753",
  },
  {
    id: 291,
    country_name: "CO-BOY-SOC755",
    country_code: "CO-BOY-SOC755",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Socotá",
    city_code: "755",
    code: "15755",
  },
  {
    id: 292,
    country_name: "CO-BOY-SOC757",
    country_code: "CO-BOY-SOC757",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Socha",
    city_code: "757",
    code: "15757",
  },
  {
    id: 293,
    country_name: "CO-BOY-SOG759",
    country_code: "CO-BOY-SOG759",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Sogamoso",
    city_code: "759",
    code: "15759",
  },
  {
    id: 294,
    country_name: "CO-BOY-SOM761",
    country_code: "CO-BOY-SOM761",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Somondoco",
    city_code: "761",
    code: "15761",
  },
  {
    id: 295,
    country_name: "CO-BOY-SOR762",
    country_code: "CO-BOY-SOR762",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Sora",
    city_code: "762",
    code: "15762",
  },
  {
    id: 296,
    country_name: "CO-BOY-SOT763",
    country_code: "CO-BOY-SOT763",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Sotaquirá",
    city_code: "763",
    code: "15763",
  },
  {
    id: 297,
    country_name: "CO-BOY-SOR764",
    country_code: "CO-BOY-SOR764",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Soracá",
    city_code: "764",
    code: "15764",
  },
  {
    id: 298,
    country_name: "CO-BOY-SUS774",
    country_code: "CO-BOY-SUS774",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Susacón",
    city_code: "774",
    code: "15774",
  },
  {
    id: 299,
    country_name: "CO-BOY-SUT776",
    country_code: "CO-BOY-SUT776",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Sutamarchán",
    city_code: "776",
    code: "15776",
  },
  {
    id: 300,
    country_name: "CO-BOY-SUT778",
    country_code: "CO-BOY-SUT778",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Sutatenza",
    city_code: "778",
    code: "15778",
  },
  {
    id: 301,
    country_name: "CO-BOY-TAS790",
    country_code: "CO-BOY-TAS790",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Tasco",
    city_code: "790",
    code: "15790",
  },
  {
    id: 302,
    country_name: "CO-BOY-TEN798",
    country_code: "CO-BOY-TEN798",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Tenza",
    city_code: "798",
    code: "15798",
  },
  {
    id: 303,
    country_name: "CO-BOY-TIB804",
    country_code: "CO-BOY-TIB804",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Tibaná",
    city_code: "804",
    code: "15804",
  },
  {
    id: 304,
    country_name: "CO-BOY-TIB806",
    country_code: "CO-BOY-TIB806",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Tibasosa",
    city_code: "806",
    code: "15806",
  },
  {
    id: 305,
    country_name: "CO-BOY-TIN808",
    country_code: "CO-BOY-TIN808",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Tinjacá",
    city_code: "808",
    code: "15808",
  },
  {
    id: 306,
    country_name: "CO-BOY-TIP810",
    country_code: "CO-BOY-TIP810",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Tipacoque",
    city_code: "810",
    code: "15810",
  },
  {
    id: 307,
    country_name: "CO-BOY-TOC814",
    country_code: "CO-BOY-TOC814",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Toca",
    city_code: "814",
    code: "15814",
  },
  {
    id: 308,
    country_name: "CO-BOY-TOG816",
    country_code: "CO-BOY-TOG816",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Togüí",
    city_code: "816",
    code: "15816",
  },
  {
    id: 309,
    country_name: "CO-BOY-TOP820",
    country_code: "CO-BOY-TOP820",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Tópaga",
    city_code: "820",
    code: "15820",
  },
  {
    id: 310,
    country_name: "CO-BOY-TOT822",
    country_code: "CO-BOY-TOT822",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Tota",
    city_code: "822",
    code: "15822",
  },
  {
    id: 311,
    country_name: "CO-BOY-TUN832",
    country_code: "CO-BOY-TUN832",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Tununguá",
    city_code: "832",
    code: "15832",
  },
  {
    id: 312,
    country_name: "CO-BOY-TUR835",
    country_code: "CO-BOY-TUR835",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Turmequé",
    city_code: "835",
    code: "15835",
  },
  {
    id: 313,
    country_name: "CO-BOY-TUT837",
    country_code: "CO-BOY-TUT837",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Tuta",
    city_code: "837",
    code: "15837",
  },
  {
    id: 314,
    country_name: "CO-BOY-TUT839",
    country_code: "CO-BOY-TUT839",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Tutazá",
    city_code: "839",
    code: "15839",
  },
  {
    id: 315,
    country_name: "CO-BOY-UMB842",
    country_code: "CO-BOY-UMB842",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Úmbita",
    city_code: "842",
    code: "15842",
  },
  {
    id: 316,
    country_name: "CO-BOY-VEN861",
    country_code: "CO-BOY-VEN861",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Ventaquemada",
    city_code: "861",
    code: "15861",
  },
  {
    id: 317,
    country_name: "CO-BOY-VIR879",
    country_code: "CO-BOY-VIR879",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Viracachá",
    city_code: "879",
    code: "15879",
  },
  {
    id: 318,
    country_name: "CO-BOY-ZET897",
    country_code: "CO-BOY-ZET897",
    state_name: "Boyacá",
    state_code: "15",
    city_name: "Zetaquira",
    city_code: "897",
    code: "15897",
  },
  {
    id: 319,
    country_name: "CO-CAL-MAN1",
    country_code: "CO-CAL-MAN1",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Manizales",
    city_code: "1",
    code: "17001",
  },
  {
    id: 320,
    country_name: "CO-CAL-AGU13",
    country_code: "CO-CAL-AGU13",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Aguadas",
    city_code: "13",
    code: "17013",
  },
  {
    id: 321,
    country_name: "CO-CAL-ANS42",
    country_code: "CO-CAL-ANS42",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Anserma",
    city_code: "42",
    code: "17042",
  },
  {
    id: 322,
    country_name: "CO-CAL-ARA50",
    country_code: "CO-CAL-ARA50",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Aranzazu",
    city_code: "50",
    code: "17050",
  },
  {
    id: 323,
    country_name: "CO-CAL-BEL88",
    country_code: "CO-CAL-BEL88",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Belalcázar",
    city_code: "88",
    code: "17088",
  },
  {
    id: 324,
    country_name: "CO-CAL-CHI174",
    country_code: "CO-CAL-CHI174",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Chinchiná",
    city_code: "174",
    code: "17174",
  },
  {
    id: 325,
    country_name: "CO-CAL-FIL272",
    country_code: "CO-CAL-FIL272",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Filadelfia",
    city_code: "272",
    code: "17272",
  },
  {
    id: 326,
    country_name: "CO-CAL-LA 380",
    country_code: "CO-CAL-LA 380",
    state_name: "Caldas",
    state_code: "17",
    city_name: "La Dorada",
    city_code: "380",
    code: "17380",
  },
  {
    id: 327,
    country_name: "CO-CAL-LA 388",
    country_code: "CO-CAL-LA 388",
    state_name: "Caldas",
    state_code: "17",
    city_name: "La Merced",
    city_code: "388",
    code: "17388",
  },
  {
    id: 328,
    country_name: "CO-CAL-MAN433",
    country_code: "CO-CAL-MAN433",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Manzanares",
    city_code: "433",
    code: "17433",
  },
  {
    id: 329,
    country_name: "CO-CAL-MAR442",
    country_code: "CO-CAL-MAR442",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Marmato",
    city_code: "442",
    code: "17442",
  },
  {
    id: 330,
    country_name: "CO-CAL-MAR444",
    country_code: "CO-CAL-MAR444",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Marquetalia",
    city_code: "444",
    code: "17444",
  },
  {
    id: 331,
    country_name: "CO-CAL-MAR446",
    country_code: "CO-CAL-MAR446",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Marulanda",
    city_code: "446",
    code: "17446",
  },
  {
    id: 332,
    country_name: "CO-CAL-NEI486",
    country_code: "CO-CAL-NEI486",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Neira",
    city_code: "486",
    code: "17486",
  },
  {
    id: 333,
    country_name: "CO-CAL-NOR495",
    country_code: "CO-CAL-NOR495",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Norcasia",
    city_code: "495",
    code: "17495",
  },
  {
    id: 334,
    country_name: "CO-CAL-PAC513",
    country_code: "CO-CAL-PAC513",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Pácora",
    city_code: "513",
    code: "17513",
  },
  {
    id: 335,
    country_name: "CO-CAL-PAL524",
    country_code: "CO-CAL-PAL524",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Palestina",
    city_code: "524",
    code: "17524",
  },
  {
    id: 336,
    country_name: "CO-CAL-PEN541",
    country_code: "CO-CAL-PEN541",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Pensilvania",
    city_code: "541",
    code: "17541",
  },
  {
    id: 337,
    country_name: "CO-CAL-RIO614",
    country_code: "CO-CAL-RIO614",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Riosucio",
    city_code: "614",
    code: "17614",
  },
  {
    id: 338,
    country_name: "CO-CAL-RIS616",
    country_code: "CO-CAL-RIS616",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Risaralda",
    city_code: "616",
    code: "17616",
  },
  {
    id: 339,
    country_name: "CO-CAL-SAL653",
    country_code: "CO-CAL-SAL653",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Salamina",
    city_code: "653",
    code: "17653",
  },
  {
    id: 340,
    country_name: "CO-CAL-SAM662",
    country_code: "CO-CAL-SAM662",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Samaná",
    city_code: "662",
    code: "17662",
  },
  {
    id: 341,
    country_name: "CO-CAL-SAN665",
    country_code: "CO-CAL-SAN665",
    state_name: "Caldas",
    state_code: "17",
    city_name: "San José",
    city_code: "665",
    code: "17665",
  },
  {
    id: 342,
    country_name: "CO-CAL-SUP777",
    country_code: "CO-CAL-SUP777",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Supía",
    city_code: "777",
    code: "17777",
  },
  {
    id: 343,
    country_name: "CO-CAL-VIC867",
    country_code: "CO-CAL-VIC867",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Victoria",
    city_code: "867",
    code: "17867",
  },
  {
    id: 344,
    country_name: "CO-CAL-VIL873",
    country_code: "CO-CAL-VIL873",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Villamaría",
    city_code: "873",
    code: "17873",
  },
  {
    id: 345,
    country_name: "CO-CAL-VIT877",
    country_code: "CO-CAL-VIT877",
    state_name: "Caldas",
    state_code: "17",
    city_name: "Viterbo",
    city_code: "877",
    code: "17877",
  },
  {
    id: 346,
    country_name: "CO-CAQ-FLO1",
    country_code: "CO-CAQ-FLO1",
    state_name: "Caquetá",
    state_code: "18",
    city_name: "Florencia",
    city_code: "1",
    code: "18001",
  },
  {
    id: 347,
    country_name: "CO-CAQ-ALB29",
    country_code: "CO-CAQ-ALB29",
    state_name: "Caquetá",
    state_code: "18",
    city_name: "Albania",
    city_code: "29",
    code: "18029",
  },
  {
    id: 348,
    country_name: "CO-CAQ-BEL94",
    country_code: "CO-CAQ-BEL94",
    state_name: "Caquetá",
    state_code: "18",
    city_name: "Belén De Andaquíes",
    city_code: "94",
    code: "18094",
  },
  {
    id: 349,
    country_name: "CO-CAQ-CAR150",
    country_code: "CO-CAQ-CAR150",
    state_name: "Caquetá",
    state_code: "18",
    city_name: "Cartagena Del Chairá",
    city_code: "150",
    code: "18150",
  },
  {
    id: 350,
    country_name: "CO-CAQ-CUR205",
    country_code: "CO-CAQ-CUR205",
    state_name: "Caquetá",
    state_code: "18",
    city_name: "Curillo",
    city_code: "205",
    code: "18205",
  },
  {
    id: 351,
    country_name: "CO-CAQ-EL 247",
    country_code: "CO-CAQ-EL 247",
    state_name: "Caquetá",
    state_code: "18",
    city_name: "El Doncello",
    city_code: "247",
    code: "18247",
  },
  {
    id: 352,
    country_name: "CO-CAQ-EL 256",
    country_code: "CO-CAQ-EL 256",
    state_name: "Caquetá",
    state_code: "18",
    city_name: "El Paujíl",
    city_code: "256",
    code: "18256",
  },
  {
    id: 353,
    country_name: "CO-CAQ-LA 410",
    country_code: "CO-CAQ-LA 410",
    state_name: "Caquetá",
    state_code: "18",
    city_name: "La Montañita",
    city_code: "410",
    code: "18410",
  },
  {
    id: 354,
    country_name: "CO-CAQ-MIL460",
    country_code: "CO-CAQ-MIL460",
    state_name: "Caquetá",
    state_code: "18",
    city_name: "Milán",
    city_code: "460",
    code: "18460",
  },
  {
    id: 355,
    country_name: "CO-CAQ-MOR479",
    country_code: "CO-CAQ-MOR479",
    state_name: "Caquetá",
    state_code: "18",
    city_name: "Morelia",
    city_code: "479",
    code: "18479",
  },
  {
    id: 356,
    country_name: "CO-CAQ-PUE592",
    country_code: "CO-CAQ-PUE592",
    state_name: "Caquetá",
    state_code: "18",
    city_name: "Puerto Rico",
    city_code: "592",
    code: "18592",
  },
  {
    id: 357,
    country_name: "CO-CAQ-SAN610",
    country_code: "CO-CAQ-SAN610",
    state_name: "Caquetá",
    state_code: "18",
    city_name: "San José Del Fragua",
    city_code: "610",
    code: "18610",
  },
  {
    id: 358,
    country_name: "CO-CAQ-SAN753",
    country_code: "CO-CAQ-SAN753",
    state_name: "Caquetá",
    state_code: "18",
    city_name: "San Vicente Del Caguán",
    city_code: "753",
    code: "18753",
  },
  {
    id: 359,
    country_name: "CO-CAQ-SOL756",
    country_code: "CO-CAQ-SOL756",
    state_name: "Caquetá",
    state_code: "18",
    city_name: "Solano",
    city_code: "756",
    code: "18756",
  },
  {
    id: 360,
    country_name: "CO-CAQ-SOL785",
    country_code: "CO-CAQ-SOL785",
    state_name: "Caquetá",
    state_code: "18",
    city_name: "Solita",
    city_code: "785",
    code: "18785",
  },
  {
    id: 361,
    country_name: "CO-CAQ-VAL860",
    country_code: "CO-CAQ-VAL860",
    state_name: "Caquetá",
    state_code: "18",
    city_name: "Valparaíso",
    city_code: "860",
    code: "18860",
  },
  {
    id: 362,
    country_name: "CO-CAU-POP1",
    country_code: "CO-CAU-POP1",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Popayán",
    city_code: "1",
    code: "19001",
  },
  {
    id: 363,
    country_name: "CO-CAU-ALM22",
    country_code: "CO-CAU-ALM22",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Almaguer",
    city_code: "22",
    code: "19022",
  },
  {
    id: 364,
    country_name: "CO-CAU-ARG50",
    country_code: "CO-CAU-ARG50",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Argelia",
    city_code: "50",
    code: "19050",
  },
  {
    id: 365,
    country_name: "CO-CAU-BAL75",
    country_code: "CO-CAU-BAL75",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Balboa",
    city_code: "75",
    code: "19075",
  },
  {
    id: 366,
    country_name: "CO-CAU-BOL100",
    country_code: "CO-CAU-BOL100",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Bolívar",
    city_code: "100",
    code: "19100",
  },
  {
    id: 367,
    country_name: "CO-CAU-BUE110",
    country_code: "CO-CAU-BUE110",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Buenos Aires",
    city_code: "110",
    code: "19110",
  },
  {
    id: 368,
    country_name: "CO-CAU-CAJ130",
    country_code: "CO-CAU-CAJ130",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Cajibío",
    city_code: "130",
    code: "19130",
  },
  {
    id: 369,
    country_name: "CO-CAU-CAL137",
    country_code: "CO-CAU-CAL137",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Caldono",
    city_code: "137",
    code: "19137",
  },
  {
    id: 370,
    country_name: "CO-CAU-CAL142",
    country_code: "CO-CAU-CAL142",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Caloto",
    city_code: "142",
    code: "19142",
  },
  {
    id: 371,
    country_name: "CO-CAU-COR212",
    country_code: "CO-CAU-COR212",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Corinto",
    city_code: "212",
    code: "19212",
  },
  {
    id: 372,
    country_name: "CO-CAU-EL 256",
    country_code: "CO-CAU-EL 256",
    state_name: "Cauca",
    state_code: "19",
    city_name: "El Tambo",
    city_code: "256",
    code: "19256",
  },
  {
    id: 373,
    country_name: "CO-CAU-FLO290",
    country_code: "CO-CAU-FLO290",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Florencia",
    city_code: "290",
    code: "19290",
  },
  {
    id: 374,
    country_name: "CO-CAU-GUA300",
    country_code: "CO-CAU-GUA300",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Guachené",
    city_code: "300",
    code: "19300",
  },
  {
    id: 375,
    country_name: "CO-CAU-GUA318",
    country_code: "CO-CAU-GUA318",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Guapí",
    city_code: "318",
    code: "19318",
  },
  {
    id: 376,
    country_name: "CO-CAU-INZ355",
    country_code: "CO-CAU-INZ355",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Inzá",
    city_code: "355",
    code: "19355",
  },
  {
    id: 377,
    country_name: "CO-CAU-JAM364",
    country_code: "CO-CAU-JAM364",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Jambaló",
    city_code: "364",
    code: "19364",
  },
  {
    id: 378,
    country_name: "CO-CAU-LA 392",
    country_code: "CO-CAU-LA 392",
    state_name: "Cauca",
    state_code: "19",
    city_name: "La Sierra",
    city_code: "392",
    code: "19392",
  },
  {
    id: 379,
    country_name: "CO-CAU-LA 397",
    country_code: "CO-CAU-LA 397",
    state_name: "Cauca",
    state_code: "19",
    city_name: "La Vega",
    city_code: "397",
    code: "19397",
  },
  {
    id: 380,
    country_name: "CO-CAU-LOP418",
    country_code: "CO-CAU-LOP418",
    state_name: "Cauca",
    state_code: "19",
    city_name: "López De Micay",
    city_code: "418",
    code: "19418",
  },
  {
    id: 381,
    country_name: "CO-CAU-MER450",
    country_code: "CO-CAU-MER450",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Mercaderes",
    city_code: "450",
    code: "19450",
  },
  {
    id: 382,
    country_name: "CO-CAU-MIR455",
    country_code: "CO-CAU-MIR455",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Miranda",
    city_code: "455",
    code: "19455",
  },
  {
    id: 383,
    country_name: "CO-CAU-MOR473",
    country_code: "CO-CAU-MOR473",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Morales",
    city_code: "473",
    code: "19473",
  },
  {
    id: 384,
    country_name: "CO-CAU-PAD513",
    country_code: "CO-CAU-PAD513",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Padilla",
    city_code: "513",
    code: "19513",
  },
  {
    id: 385,
    country_name: "CO-CAU-PAE517",
    country_code: "CO-CAU-PAE517",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Paez",
    city_code: "517",
    code: "19517",
  },
  {
    id: 386,
    country_name: "CO-CAU-PAT532",
    country_code: "CO-CAU-PAT532",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Patía",
    city_code: "532",
    code: "19532",
  },
  {
    id: 387,
    country_name: "CO-CAU-PIA533",
    country_code: "CO-CAU-PIA533",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Piamonte",
    city_code: "533",
    code: "19533",
  },
  {
    id: 388,
    country_name: "CO-CAU-PIE548",
    country_code: "CO-CAU-PIE548",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Piendamó",
    city_code: "548",
    code: "19548",
  },
  {
    id: 389,
    country_name: "CO-CAU-PUE573",
    country_code: "CO-CAU-PUE573",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Puerto Tejada",
    city_code: "573",
    code: "19573",
  },
  {
    id: 390,
    country_name: "CO-CAU-PUR585",
    country_code: "CO-CAU-PUR585",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Puracé",
    city_code: "585",
    code: "19585",
  },
  {
    id: 391,
    country_name: "CO-CAU-ROS622",
    country_code: "CO-CAU-ROS622",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Rosas",
    city_code: "622",
    code: "19622",
  },
  {
    id: 392,
    country_name: "CO-CAU-SAN693",
    country_code: "CO-CAU-SAN693",
    state_name: "Cauca",
    state_code: "19",
    city_name: "San Sebastián",
    city_code: "693",
    code: "19693",
  },
  {
    id: 393,
    country_name: "CO-CAU-SAN698",
    country_code: "CO-CAU-SAN698",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Santander De Quilichao",
    city_code: "698",
    code: "19698",
  },
  {
    id: 394,
    country_name: "CO-CAU-SAN701",
    country_code: "CO-CAU-SAN701",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Santa Rosa",
    city_code: "701",
    code: "19701",
  },
  {
    id: 395,
    country_name: "CO-CAU-SIL743",
    country_code: "CO-CAU-SIL743",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Silvia",
    city_code: "743",
    code: "19743",
  },
  {
    id: 396,
    country_name: "CO-CAU-SOT760",
    country_code: "CO-CAU-SOT760",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Sotara",
    city_code: "760",
    code: "19760",
  },
  {
    id: 397,
    country_name: "CO-CAU-SUA780",
    country_code: "CO-CAU-SUA780",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Suárez",
    city_code: "780",
    code: "19780",
  },
  {
    id: 398,
    country_name: "CO-CAU-SUC785",
    country_code: "CO-CAU-SUC785",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Sucre",
    city_code: "785",
    code: "19785",
  },
  {
    id: 399,
    country_name: "CO-CAU-TIM807",
    country_code: "CO-CAU-TIM807",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Timbío",
    city_code: "807",
    code: "19807",
  },
  {
    id: 400,
    country_name: "CO-CAU-TIM809",
    country_code: "CO-CAU-TIM809",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Timbiquí",
    city_code: "809",
    code: "19809",
  },
  {
    id: 401,
    country_name: "CO-CAU-TOR821",
    country_code: "CO-CAU-TOR821",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Toribío",
    city_code: "821",
    code: "19821",
  },
  {
    id: 402,
    country_name: "CO-CAU-TOT824",
    country_code: "CO-CAU-TOT824",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Totoró",
    city_code: "824",
    code: "19824",
  },
  {
    id: 403,
    country_name: "CO-CAU-VIL845",
    country_code: "CO-CAU-VIL845",
    state_name: "Cauca",
    state_code: "19",
    city_name: "Villa Rica",
    city_code: "845",
    code: "19845",
  },
  {
    id: 404,
    country_name: "CO-CES-VAL1",
    country_code: "CO-CES-VAL1",
    state_name: "Cesar",
    state_code: "20",
    city_name: "Valledupar",
    city_code: "1",
    code: "20001",
  },
  {
    id: 405,
    country_name: "CO-CES-AGU11",
    country_code: "CO-CES-AGU11",
    state_name: "Cesar",
    state_code: "20",
    city_name: "Aguachica",
    city_code: "11",
    code: "20011",
  },
  {
    id: 406,
    country_name: "CO-CES-AGU13",
    country_code: "CO-CES-AGU13",
    state_name: "Cesar",
    state_code: "20",
    city_name: "Agustín Codazzi",
    city_code: "13",
    code: "20013",
  },
  {
    id: 407,
    country_name: "CO-CES-AST32",
    country_code: "CO-CES-AST32",
    state_name: "Cesar",
    state_code: "20",
    city_name: "Astrea",
    city_code: "32",
    code: "20032",
  },
  {
    id: 408,
    country_name: "CO-CES-BEC45",
    country_code: "CO-CES-BEC45",
    state_name: "Cesar",
    state_code: "20",
    city_name: "Becerril",
    city_code: "45",
    code: "20045",
  },
  {
    id: 409,
    country_name: "CO-CES-BOS60",
    country_code: "CO-CES-BOS60",
    state_name: "Cesar",
    state_code: "20",
    city_name: "Bosconia",
    city_code: "60",
    code: "20060",
  },
  {
    id: 410,
    country_name: "CO-CES-CHI175",
    country_code: "CO-CES-CHI175",
    state_name: "Cesar",
    state_code: "20",
    city_name: "Chimichagua",
    city_code: "175",
    code: "20175",
  },
  {
    id: 411,
    country_name: "CO-CES-CHI178",
    country_code: "CO-CES-CHI178",
    state_name: "Cesar",
    state_code: "20",
    city_name: "Chiriguaná",
    city_code: "178",
    code: "20178",
  },
  {
    id: 412,
    country_name: "CO-CES-CUR228",
    country_code: "CO-CES-CUR228",
    state_name: "Cesar",
    state_code: "20",
    city_name: "Curumaní",
    city_code: "228",
    code: "20228",
  },
  {
    id: 413,
    country_name: "CO-CES-EL 238",
    country_code: "CO-CES-EL 238",
    state_name: "Cesar",
    state_code: "20",
    city_name: "El Copey",
    city_code: "238",
    code: "20238",
  },
  {
    id: 414,
    country_name: "CO-CES-EL 250",
    country_code: "CO-CES-EL 250",
    state_name: "Cesar",
    state_code: "20",
    city_name: "El Paso",
    city_code: "250",
    code: "20250",
  },
  {
    id: 415,
    country_name: "CO-CES-GAM295",
    country_code: "CO-CES-GAM295",
    state_name: "Cesar",
    state_code: "20",
    city_name: "Gamarra",
    city_code: "295",
    code: "20295",
  },
  {
    id: 416,
    country_name: "CO-CES-GON310",
    country_code: "CO-CES-GON310",
    state_name: "Cesar",
    state_code: "20",
    city_name: "González",
    city_code: "310",
    code: "20310",
  },
  {
    id: 417,
    country_name: "CO-CES-LA 383",
    country_code: "CO-CES-LA 383",
    state_name: "Cesar",
    state_code: "20",
    city_name: "La Gloria",
    city_code: "383",
    code: "20383",
  },
  {
    id: 418,
    country_name: "CO-CES-LA 400",
    country_code: "CO-CES-LA 400",
    state_name: "Cesar",
    state_code: "20",
    city_name: "La Jagua De Ibirico",
    city_code: "400",
    code: "20400",
  },
  {
    id: 419,
    country_name: "CO-CES-MAN443",
    country_code: "CO-CES-MAN443",
    state_name: "Cesar",
    state_code: "20",
    city_name: "Manaure Balcón Del Cesar",
    city_code: "443",
    code: "20443",
  },
  {
    id: 420,
    country_name: "CO-CES-PAI517",
    country_code: "CO-CES-PAI517",
    state_name: "Cesar",
    state_code: "20",
    city_name: "Pailitas",
    city_code: "517",
    code: "20517",
  },
  {
    id: 421,
    country_name: "CO-CES-PEL550",
    country_code: "CO-CES-PEL550",
    state_name: "Cesar",
    state_code: "20",
    city_name: "Pelaya",
    city_code: "550",
    code: "20550",
  },
  {
    id: 422,
    country_name: "CO-CES-PUE570",
    country_code: "CO-CES-PUE570",
    state_name: "Cesar",
    state_code: "20",
    city_name: "Pueblo Bello",
    city_code: "570",
    code: "20570",
  },
  {
    id: 423,
    country_name: "CO-CES-RIO614",
    country_code: "CO-CES-RIO614",
    state_name: "Cesar",
    state_code: "20",
    city_name: "Río De Oro",
    city_code: "614",
    code: "20614",
  },
  {
    id: 424,
    country_name: "CO-CES-LA 621",
    country_code: "CO-CES-LA 621",
    state_name: "Cesar",
    state_code: "20",
    city_name: "La Paz",
    city_code: "621",
    code: "20621",
  },
  {
    id: 425,
    country_name: "CO-CES-SAN710",
    country_code: "CO-CES-SAN710",
    state_name: "Cesar",
    state_code: "20",
    city_name: "San Alberto",
    city_code: "710",
    code: "20710",
  },
  {
    id: 426,
    country_name: "CO-CES-SAN750",
    country_code: "CO-CES-SAN750",
    state_name: "Cesar",
    state_code: "20",
    city_name: "San Diego",
    city_code: "750",
    code: "20750",
  },
  {
    id: 427,
    country_name: "CO-CES-SAN770",
    country_code: "CO-CES-SAN770",
    state_name: "Cesar",
    state_code: "20",
    city_name: "San Martín",
    city_code: "770",
    code: "20770",
  },
  {
    id: 428,
    country_name: "CO-CES-TAM787",
    country_code: "CO-CES-TAM787",
    state_name: "Cesar",
    state_code: "20",
    city_name: "Tamalameque",
    city_code: "787",
    code: "20787",
  },
  {
    id: 429,
    country_name: "CO-COR-MON1",
    country_code: "CO-COR-MON1",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Montería",
    city_code: "1",
    code: "23001",
  },
  {
    id: 430,
    country_name: "CO-COR-AYA68",
    country_code: "CO-COR-AYA68",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Ayapel",
    city_code: "68",
    code: "23068",
  },
  {
    id: 431,
    country_name: "CO-COR-BUE79",
    country_code: "CO-COR-BUE79",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Buenavista",
    city_code: "79",
    code: "23079",
  },
  {
    id: 432,
    country_name: "CO-COR-CAN90",
    country_code: "CO-COR-CAN90",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Canalete",
    city_code: "90",
    code: "23090",
  },
  {
    id: 433,
    country_name: "CO-COR-CER162",
    country_code: "CO-COR-CER162",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Cereté",
    city_code: "162",
    code: "23162",
  },
  {
    id: 434,
    country_name: "CO-COR-CHI168",
    country_code: "CO-COR-CHI168",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Chimá",
    city_code: "168",
    code: "23168",
  },
  {
    id: 435,
    country_name: "CO-COR-CHI182",
    country_code: "CO-COR-CHI182",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Chinú",
    city_code: "182",
    code: "23182",
  },
  {
    id: 436,
    country_name: "CO-COR-CIE189",
    country_code: "CO-COR-CIE189",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Ciénaga De Oro",
    city_code: "189",
    code: "23189",
  },
  {
    id: 437,
    country_name: "CO-COR-COT300",
    country_code: "CO-COR-COT300",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Cotorra",
    city_code: "300",
    code: "23300",
  },
  {
    id: 438,
    country_name: "CO-COR-LA 350",
    country_code: "CO-COR-LA 350",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "La Apartada",
    city_code: "350",
    code: "23350",
  },
  {
    id: 439,
    country_name: "CO-COR-LOR417",
    country_code: "CO-COR-LOR417",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Lorica",
    city_code: "417",
    code: "23417",
  },
  {
    id: 440,
    country_name: "CO-COR-LOS419",
    country_code: "CO-COR-LOS419",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Los Córdobas",
    city_code: "419",
    code: "23419",
  },
  {
    id: 441,
    country_name: "CO-COR-MOM464",
    country_code: "CO-COR-MOM464",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Momil",
    city_code: "464",
    code: "23464",
  },
  {
    id: 442,
    country_name: "CO-COR-MON466",
    country_code: "CO-COR-MON466",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Montelíbano",
    city_code: "466",
    code: "23466",
  },
  {
    id: 443,
    country_name: "CO-COR-MO?500",
    country_code: "CO-COR-MO?500",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Moñitos",
    city_code: "500",
    code: "23500",
  },
  {
    id: 444,
    country_name: "CO-COR-PLA555",
    country_code: "CO-COR-PLA555",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Planeta Rica",
    city_code: "555",
    code: "23555",
  },
  {
    id: 445,
    country_name: "CO-COR-PUE570",
    country_code: "CO-COR-PUE570",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Pueblo Nuevo",
    city_code: "570",
    code: "23570",
  },
  {
    id: 446,
    country_name: "CO-COR-PUE574",
    country_code: "CO-COR-PUE574",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Puerto Escondido",
    city_code: "574",
    code: "23574",
  },
  {
    id: 447,
    country_name: "CO-COR-PUE580",
    country_code: "CO-COR-PUE580",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Puerto Libertador",
    city_code: "580",
    code: "23580",
  },
  {
    id: 448,
    country_name: "CO-COR-PUR586",
    country_code: "CO-COR-PUR586",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Purísima De La Concepción",
    city_code: "586",
    code: "23586",
  },
  {
    id: 449,
    country_name: "CO-COR-SAH660",
    country_code: "CO-COR-SAH660",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Sahagún",
    city_code: "660",
    code: "23660",
  },
  {
    id: 450,
    country_name: "CO-COR-SAN670",
    country_code: "CO-COR-SAN670",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "San Andrés De Sotavento",
    city_code: "670",
    code: "23670",
  },
  {
    id: 451,
    country_name: "CO-COR-SAN672",
    country_code: "CO-COR-SAN672",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "San Antero",
    city_code: "672",
    code: "23672",
  },
  {
    id: 452,
    country_name: "CO-COR-SAN675",
    country_code: "CO-COR-SAN675",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "San Bernardo Del Viento",
    city_code: "675",
    code: "23675",
  },
  {
    id: 453,
    country_name: "CO-COR-SAN678",
    country_code: "CO-COR-SAN678",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "San Carlos",
    city_code: "678",
    code: "23678",
  },
  {
    id: 454,
    country_name: "CO-COR-SAN682",
    country_code: "CO-COR-SAN682",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "San José De Uré",
    city_code: "682",
    code: "23682",
  },
  {
    id: 455,
    country_name: "CO-COR-SAN686",
    country_code: "CO-COR-SAN686",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "San Pelayo",
    city_code: "686",
    code: "23686",
  },
  {
    id: 456,
    country_name: "CO-COR-TIE807",
    country_code: "CO-COR-TIE807",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Tierralta",
    city_code: "807",
    code: "23807",
  },
  {
    id: 457,
    country_name: "CO-COR-TUC815",
    country_code: "CO-COR-TUC815",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Tuchín",
    city_code: "815",
    code: "23815",
  },
  {
    id: 458,
    country_name: "CO-COR-VAL855",
    country_code: "CO-COR-VAL855",
    state_name: "Córdoba",
    state_code: "23",
    city_name: "Valencia",
    city_code: "855",
    code: "23855",
  },
  {
    id: 459,
    country_name: "CO-CUN-AGU1",
    country_code: "CO-CUN-AGU1",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Agua de Dios",
    city_code: "1",
    code: "25001",
  },
  {
    id: 460,
    country_name: "CO-CUN-ALB19",
    country_code: "CO-CUN-ALB19",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Albán",
    city_code: "19",
    code: "25019",
  },
  {
    id: 461,
    country_name: "CO-CUN-ANA35",
    country_code: "CO-CUN-ANA35",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Anapoima",
    city_code: "35",
    code: "25035",
  },
  {
    id: 462,
    country_name: "CO-CUN-ANO40",
    country_code: "CO-CUN-ANO40",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Anolaima",
    city_code: "40",
    code: "25040",
  },
  {
    id: 463,
    country_name: "CO-CUN-ARB53",
    country_code: "CO-CUN-ARB53",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Arbeláez",
    city_code: "53",
    code: "25053",
  },
  {
    id: 464,
    country_name: "CO-CUN-BEL86",
    country_code: "CO-CUN-BEL86",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Beltrán",
    city_code: "86",
    code: "25086",
  },
  {
    id: 465,
    country_name: "CO-CUN-BIT95",
    country_code: "CO-CUN-BIT95",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Bituima",
    city_code: "95",
    code: "25095",
  },
  {
    id: 466,
    country_name: "CO-CUN-BOJ99",
    country_code: "CO-CUN-BOJ99",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Bojacá",
    city_code: "99",
    code: "25099",
  },
  {
    id: 467,
    country_name: "CO-CUN-CAB120",
    country_code: "CO-CUN-CAB120",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Cabrera",
    city_code: "120",
    code: "25120",
  },
  {
    id: 468,
    country_name: "CO-CUN-CAC123",
    country_code: "CO-CUN-CAC123",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Cachipay",
    city_code: "123",
    code: "25123",
  },
  {
    id: 469,
    country_name: "CO-CUN-CAJ126",
    country_code: "CO-CUN-CAJ126",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Cajicá",
    city_code: "126",
    code: "25126",
  },
  {
    id: 470,
    country_name: "CO-CUN-CAP148",
    country_code: "CO-CUN-CAP148",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Caparrapí",
    city_code: "148",
    code: "25148",
  },
  {
    id: 471,
    country_name: "CO-CUN-CAQ151",
    country_code: "CO-CUN-CAQ151",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Cáqueza",
    city_code: "151",
    code: "25151",
  },
  {
    id: 472,
    country_name: "CO-CUN-CAR154",
    country_code: "CO-CUN-CAR154",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Carmen De Carupa",
    city_code: "154",
    code: "25154",
  },
  {
    id: 473,
    country_name: "CO-CUN-CHA168",
    country_code: "CO-CUN-CHA168",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Chaguaní",
    city_code: "168",
    code: "25168",
  },
  {
    id: 474,
    country_name: "CO-CUN-CHI175",
    country_code: "CO-CUN-CHI175",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Chia",
    city_code: "175",
    code: "25175",
  },
  {
    id: 475,
    country_name: "CO-CUN-CHI178",
    country_code: "CO-CUN-CHI178",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Chipaque",
    city_code: "178",
    code: "25178",
  },
  {
    id: 476,
    country_name: "CO-CUN-CHO181",
    country_code: "CO-CUN-CHO181",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Choachí",
    city_code: "181",
    code: "25181",
  },
  {
    id: 477,
    country_name: "CO-CUN-CHO183",
    country_code: "CO-CUN-CHO183",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Chocontá",
    city_code: "183",
    code: "25183",
  },
  {
    id: 478,
    country_name: "CO-CUN-COG200",
    country_code: "CO-CUN-COG200",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Cogua",
    city_code: "200",
    code: "25200",
  },
  {
    id: 479,
    country_name: "CO-CUN-COT214",
    country_code: "CO-CUN-COT214",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Cota",
    city_code: "214",
    code: "25214",
  },
  {
    id: 480,
    country_name: "CO-CUN-CUC224",
    country_code: "CO-CUN-CUC224",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Cucunubá",
    city_code: "224",
    code: "25224",
  },
  {
    id: 481,
    country_name: "CO-CUN-EL 245",
    country_code: "CO-CUN-EL 245",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "El Colegio",
    city_code: "245",
    code: "25245",
  },
  {
    id: 482,
    country_name: "CO-CUN-EL 258",
    country_code: "CO-CUN-EL 258",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "El Peñón",
    city_code: "258",
    code: "25258",
  },
  {
    id: 483,
    country_name: "CO-CUN-EL 260",
    country_code: "CO-CUN-EL 260",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "El Rosal",
    city_code: "260",
    code: "25260",
  },
  {
    id: 484,
    country_name: "CO-CUN-FAC269",
    country_code: "CO-CUN-FAC269",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Facatativá",
    city_code: "269",
    code: "25269",
  },
  {
    id: 485,
    country_name: "CO-CUN-FOM279",
    country_code: "CO-CUN-FOM279",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Fómeque",
    city_code: "279",
    code: "25279",
  },
  {
    id: 486,
    country_name: "CO-CUN-FOS281",
    country_code: "CO-CUN-FOS281",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Fosca",
    city_code: "281",
    code: "25281",
  },
  {
    id: 487,
    country_name: "CO-CUN-FUN286",
    country_code: "CO-CUN-FUN286",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Funza",
    city_code: "286",
    code: "25286",
  },
  {
    id: 488,
    country_name: "CO-CUN-FUQ288",
    country_code: "CO-CUN-FUQ288",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Fúquene",
    city_code: "288",
    code: "25288",
  },
  {
    id: 489,
    country_name: "CO-CUN-FUS290",
    country_code: "CO-CUN-FUS290",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Fusagasugá",
    city_code: "290",
    code: "25290",
  },
  {
    id: 490,
    country_name: "CO-CUN-GAC293",
    country_code: "CO-CUN-GAC293",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Gachalá",
    city_code: "293",
    code: "25293",
  },
  {
    id: 491,
    country_name: "CO-CUN-GAC295",
    country_code: "CO-CUN-GAC295",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Gachancipá",
    city_code: "295",
    code: "25295",
  },
  {
    id: 492,
    country_name: "CO-CUN-GAC297",
    country_code: "CO-CUN-GAC297",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Gachetá",
    city_code: "297",
    code: "25297",
  },
  {
    id: 493,
    country_name: "CO-CUN-GAM299",
    country_code: "CO-CUN-GAM299",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Gama",
    city_code: "299",
    code: "25299",
  },
  {
    id: 494,
    country_name: "CO-CUN-GIR307",
    country_code: "CO-CUN-GIR307",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Girardot",
    city_code: "307",
    code: "25307",
  },
  {
    id: 495,
    country_name: "CO-CUN-GRA312",
    country_code: "CO-CUN-GRA312",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Granada",
    city_code: "312",
    code: "25312",
  },
  {
    id: 496,
    country_name: "CO-CUN-GUA317",
    country_code: "CO-CUN-GUA317",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Guachetá",
    city_code: "317",
    code: "25317",
  },
  {
    id: 497,
    country_name: "CO-CUN-GUA320",
    country_code: "CO-CUN-GUA320",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Guaduas",
    city_code: "320",
    code: "25320",
  },
  {
    id: 498,
    country_name: "CO-CUN-GUA322",
    country_code: "CO-CUN-GUA322",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Guasca",
    city_code: "322",
    code: "25322",
  },
  {
    id: 499,
    country_name: "CO-CUN-GUA324",
    country_code: "CO-CUN-GUA324",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Guataquí",
    city_code: "324",
    code: "25324",
  },
  {
    id: 500,
    country_name: "CO-CUN-GUA326",
    country_code: "CO-CUN-GUA326",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Guatavita",
    city_code: "326",
    code: "25326",
  },
  {
    id: 501,
    country_name: "CO-CUN-GUA328",
    country_code: "CO-CUN-GUA328",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Guayabal De Síquima",
    city_code: "328",
    code: "25328",
  },
  {
    id: 502,
    country_name: "CO-CUN-GUA335",
    country_code: "CO-CUN-GUA335",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Guayabetal",
    city_code: "335",
    code: "25335",
  },
  {
    id: 503,
    country_name: "CO-CUN-GUT339",
    country_code: "CO-CUN-GUT339",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Gutiérrez",
    city_code: "339",
    code: "25339",
  },
  {
    id: 504,
    country_name: "CO-CUN-JER368",
    country_code: "CO-CUN-JER368",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Jerusalén",
    city_code: "368",
    code: "25368",
  },
  {
    id: 505,
    country_name: "CO-CUN-JUN372",
    country_code: "CO-CUN-JUN372",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Junín",
    city_code: "372",
    code: "25372",
  },
  {
    id: 506,
    country_name: "CO-CUN-LA 377",
    country_code: "CO-CUN-LA 377",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "La Calera",
    city_code: "377",
    code: "25377",
  },
  {
    id: 507,
    country_name: "CO-CUN-LA 386",
    country_code: "CO-CUN-LA 386",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "La Mesa",
    city_code: "386",
    code: "25386",
  },
  {
    id: 508,
    country_name: "CO-CUN-LA 394",
    country_code: "CO-CUN-LA 394",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "La Palma",
    city_code: "394",
    code: "25394",
  },
  {
    id: 509,
    country_name: "CO-CUN-LA 398",
    country_code: "CO-CUN-LA 398",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "La Peña",
    city_code: "398",
    code: "25398",
  },
  {
    id: 510,
    country_name: "CO-CUN-LA 402",
    country_code: "CO-CUN-LA 402",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "La Vega",
    city_code: "402",
    code: "25402",
  },
  {
    id: 511,
    country_name: "CO-CUN-LEN407",
    country_code: "CO-CUN-LEN407",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Lenguazaque",
    city_code: "407",
    code: "25407",
  },
  {
    id: 512,
    country_name: "CO-CUN-MAC426",
    country_code: "CO-CUN-MAC426",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Machetá",
    city_code: "426",
    code: "25426",
  },
  {
    id: 513,
    country_name: "CO-CUN-MAD430",
    country_code: "CO-CUN-MAD430",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Madrid",
    city_code: "430",
    code: "25430",
  },
  {
    id: 514,
    country_name: "CO-CUN-MAN436",
    country_code: "CO-CUN-MAN436",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Manta",
    city_code: "436",
    code: "25436",
  },
  {
    id: 515,
    country_name: "CO-CUN-MED438",
    country_code: "CO-CUN-MED438",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Medina",
    city_code: "438",
    code: "25438",
  },
  {
    id: 516,
    country_name: "CO-CUN-MOS473",
    country_code: "CO-CUN-MOS473",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Mosquera",
    city_code: "473",
    code: "25473",
  },
  {
    id: 517,
    country_name: "CO-CUN-NAR483",
    country_code: "CO-CUN-NAR483",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Nariño",
    city_code: "483",
    code: "25483",
  },
  {
    id: 518,
    country_name: "CO-CUN-NEM486",
    country_code: "CO-CUN-NEM486",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Nemocón",
    city_code: "486",
    code: "25486",
  },
  {
    id: 519,
    country_name: "CO-CUN-NIL488",
    country_code: "CO-CUN-NIL488",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Nilo",
    city_code: "488",
    code: "25488",
  },
  {
    id: 520,
    country_name: "CO-CUN-NIM489",
    country_code: "CO-CUN-NIM489",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Nimaima",
    city_code: "489",
    code: "25489",
  },
  {
    id: 521,
    country_name: "CO-CUN-NOC491",
    country_code: "CO-CUN-NOC491",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Nocaima",
    city_code: "491",
    code: "25491",
  },
  {
    id: 522,
    country_name: "CO-CUN-VEN506",
    country_code: "CO-CUN-VEN506",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Venecia",
    city_code: "506",
    code: "25506",
  },
  {
    id: 523,
    country_name: "CO-CUN-PAC513",
    country_code: "CO-CUN-PAC513",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Pacho",
    city_code: "513",
    code: "25513",
  },
  {
    id: 524,
    country_name: "CO-CUN-PAI518",
    country_code: "CO-CUN-PAI518",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Paime",
    city_code: "518",
    code: "25518",
  },
  {
    id: 525,
    country_name: "CO-CUN-PAN524",
    country_code: "CO-CUN-PAN524",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Pandi",
    city_code: "524",
    code: "25524",
  },
  {
    id: 526,
    country_name: "CO-CUN-PAR530",
    country_code: "CO-CUN-PAR530",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Paratebueno",
    city_code: "530",
    code: "25530",
  },
  {
    id: 527,
    country_name: "CO-CUN-PAS535",
    country_code: "CO-CUN-PAS535",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Pasca",
    city_code: "535",
    code: "25535",
  },
  {
    id: 528,
    country_name: "CO-CUN-PUE572",
    country_code: "CO-CUN-PUE572",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Puerto Salgar",
    city_code: "572",
    code: "25572",
  },
  {
    id: 529,
    country_name: "CO-CUN-PUL580",
    country_code: "CO-CUN-PUL580",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Pulí",
    city_code: "580",
    code: "25580",
  },
  {
    id: 530,
    country_name: "CO-CUN-QUE592",
    country_code: "CO-CUN-QUE592",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Quebradanegra",
    city_code: "592",
    code: "25592",
  },
  {
    id: 531,
    country_name: "CO-CUN-QUE594",
    country_code: "CO-CUN-QUE594",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Quetame",
    city_code: "594",
    code: "25594",
  },
  {
    id: 532,
    country_name: "CO-CUN-QUI596",
    country_code: "CO-CUN-QUI596",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Quipile",
    city_code: "596",
    code: "25596",
  },
  {
    id: 533,
    country_name: "CO-CUN-APU599",
    country_code: "CO-CUN-APU599",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Apulo",
    city_code: "599",
    code: "25599",
  },
  {
    id: 534,
    country_name: "CO-CUN-RIC612",
    country_code: "CO-CUN-RIC612",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Ricaurte",
    city_code: "612",
    code: "25612",
  },
  {
    id: 535,
    country_name: "CO-CUN-SAN645",
    country_code: "CO-CUN-SAN645",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "San Antonio Del Tequendama",
    city_code: "645",
    code: "25645",
  },
  {
    id: 536,
    country_name: "CO-CUN-SAN649",
    country_code: "CO-CUN-SAN649",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "San Bernardo",
    city_code: "649",
    code: "25649",
  },
  {
    id: 537,
    country_name: "CO-CUN-SAN653",
    country_code: "CO-CUN-SAN653",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "San Cayetano",
    city_code: "653",
    code: "25653",
  },
  {
    id: 538,
    country_name: "CO-CUN-SAN658",
    country_code: "CO-CUN-SAN658",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "San Francisco",
    city_code: "658",
    code: "25658",
  },
  {
    id: 539,
    country_name: "CO-CUN-SAN662",
    country_code: "CO-CUN-SAN662",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "San Juan De Rioseco",
    city_code: "662",
    code: "25662",
  },
  {
    id: 540,
    country_name: "CO-CUN-SAS718",
    country_code: "CO-CUN-SAS718",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Sasaima",
    city_code: "718",
    code: "25718",
  },
  {
    id: 541,
    country_name: "CO-CUN-SES736",
    country_code: "CO-CUN-SES736",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Sesquilé",
    city_code: "736",
    code: "25736",
  },
  {
    id: 542,
    country_name: "CO-CUN-SIB740",
    country_code: "CO-CUN-SIB740",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Sibaté",
    city_code: "740",
    code: "25740",
  },
  {
    id: 543,
    country_name: "CO-CUN-SIL743",
    country_code: "CO-CUN-SIL743",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Silvania",
    city_code: "743",
    code: "25743",
  },
  {
    id: 544,
    country_name: "CO-CUN-SIM745",
    country_code: "CO-CUN-SIM745",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Simijaca",
    city_code: "745",
    code: "25745",
  },
  {
    id: 545,
    country_name: "CO-CUN-SOA754",
    country_code: "CO-CUN-SOA754",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Soacha",
    city_code: "754",
    code: "25754",
  },
  {
    id: 546,
    country_name: "CO-CUN-SOP758",
    country_code: "CO-CUN-SOP758",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Sopó",
    city_code: "758",
    code: "25758",
  },
  {
    id: 547,
    country_name: "CO-CUN-SUB769",
    country_code: "CO-CUN-SUB769",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Subachoque",
    city_code: "769",
    code: "25769",
  },
  {
    id: 548,
    country_name: "CO-CUN-SUE772",
    country_code: "CO-CUN-SUE772",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Suesca",
    city_code: "772",
    code: "25772",
  },
  {
    id: 549,
    country_name: "CO-CUN-SUP777",
    country_code: "CO-CUN-SUP777",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Supatá",
    city_code: "777",
    code: "25777",
  },
  {
    id: 550,
    country_name: "CO-CUN-SUS779",
    country_code: "CO-CUN-SUS779",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Susa",
    city_code: "779",
    code: "25779",
  },
  {
    id: 551,
    country_name: "CO-CUN-SUT781",
    country_code: "CO-CUN-SUT781",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Sutatausa",
    city_code: "781",
    code: "25781",
  },
  {
    id: 552,
    country_name: "CO-CUN-TAB785",
    country_code: "CO-CUN-TAB785",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Tabio",
    city_code: "785",
    code: "25785",
  },
  {
    id: 553,
    country_name: "CO-CUN-TAU793",
    country_code: "CO-CUN-TAU793",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Tausa",
    city_code: "793",
    code: "25793",
  },
  {
    id: 554,
    country_name: "CO-CUN-TEN797",
    country_code: "CO-CUN-TEN797",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Tena",
    city_code: "797",
    code: "25797",
  },
  {
    id: 555,
    country_name: "CO-CUN-TEN799",
    country_code: "CO-CUN-TEN799",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Tenjo",
    city_code: "799",
    code: "25799",
  },
  {
    id: 556,
    country_name: "CO-CUN-TIB805",
    country_code: "CO-CUN-TIB805",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Tibacuy",
    city_code: "805",
    code: "25805",
  },
  {
    id: 557,
    country_name: "CO-CUN-TIB807",
    country_code: "CO-CUN-TIB807",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Tibirita",
    city_code: "807",
    code: "25807",
  },
  {
    id: 558,
    country_name: "CO-CUN-TOC815",
    country_code: "CO-CUN-TOC815",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Tocaima",
    city_code: "815",
    code: "25815",
  },
  {
    id: 559,
    country_name: "CO-CUN-TOC817",
    country_code: "CO-CUN-TOC817",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Tocancipá",
    city_code: "817",
    code: "25817",
  },
  {
    id: 560,
    country_name: "CO-CUN-TOP823",
    country_code: "CO-CUN-TOP823",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Topaipí",
    city_code: "823",
    code: "25823",
  },
  {
    id: 561,
    country_name: "CO-CUN-UBA839",
    country_code: "CO-CUN-UBA839",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Ubalá",
    city_code: "839",
    code: "25839",
  },
  {
    id: 562,
    country_name: "CO-CUN-UBA841",
    country_code: "CO-CUN-UBA841",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Ubaque",
    city_code: "841",
    code: "25841",
  },
  {
    id: 563,
    country_name: "CO-CUN-VIL843",
    country_code: "CO-CUN-VIL843",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Villa De San Diego De Ubaté",
    city_code: "843",
    code: "25843",
  },
  {
    id: 564,
    country_name: "CO-CUN-UNE845",
    country_code: "CO-CUN-UNE845",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Une",
    city_code: "845",
    code: "25845",
  },
  {
    id: 565,
    country_name: "CO-CUN-UTI851",
    country_code: "CO-CUN-UTI851",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Útica",
    city_code: "851",
    code: "25851",
  },
  {
    id: 566,
    country_name: "CO-CUN-VER862",
    country_code: "CO-CUN-VER862",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Vergara",
    city_code: "862",
    code: "25862",
  },
  {
    id: 567,
    country_name: "CO-CUN-VIA867",
    country_code: "CO-CUN-VIA867",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Vianí",
    city_code: "867",
    code: "25867",
  },
  {
    id: 568,
    country_name: "CO-CUN-VIL871",
    country_code: "CO-CUN-VIL871",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Villagómez",
    city_code: "871",
    code: "25871",
  },
  {
    id: 569,
    country_name: "CO-CUN-VIL873",
    country_code: "CO-CUN-VIL873",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Villapinzón",
    city_code: "873",
    code: "25873",
  },
  {
    id: 570,
    country_name: "CO-CUN-VIL875",
    country_code: "CO-CUN-VIL875",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Villeta",
    city_code: "875",
    code: "25875",
  },
  {
    id: 571,
    country_name: "CO-CUN-VIO878",
    country_code: "CO-CUN-VIO878",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Viotá",
    city_code: "878",
    code: "25878",
  },
  {
    id: 572,
    country_name: "CO-CUN-YAC885",
    country_code: "CO-CUN-YAC885",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Yacopí",
    city_code: "885",
    code: "25885",
  },
  {
    id: 573,
    country_name: "CO-CUN-ZIP898",
    country_code: "CO-CUN-ZIP898",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Zipacón",
    city_code: "898",
    code: "25898",
  },
  {
    id: 574,
    country_name: "CO-CUN-ZIP899",
    country_code: "CO-CUN-ZIP899",
    state_name: "Cundinamarca",
    state_code: "25",
    city_name: "Zipaquirá",
    city_code: "899",
    code: "25899",
  },
  {
    id: 575,
    country_name: "CO-CHO-QUI1",
    country_code: "CO-CHO-QUI1",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Quibdó",
    city_code: "1",
    code: "27001",
  },
  {
    id: 576,
    country_name: "CO-CHO-ACA6",
    country_code: "CO-CHO-ACA6",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Acandí",
    city_code: "6",
    code: "27006",
  },
  {
    id: 577,
    country_name: "CO-CHO-ALT25",
    country_code: "CO-CHO-ALT25",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Alto Baudó",
    city_code: "25",
    code: "27025",
  },
  {
    id: 578,
    country_name: "CO-CHO-ATR50",
    country_code: "CO-CHO-ATR50",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Atrato",
    city_code: "50",
    code: "27050",
  },
  {
    id: 579,
    country_name: "CO-CHO-BAG73",
    country_code: "CO-CHO-BAG73",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Bagadó",
    city_code: "73",
    code: "27073",
  },
  {
    id: 580,
    country_name: "CO-CHO-BAH75",
    country_code: "CO-CHO-BAH75",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Bahía Solano",
    city_code: "75",
    code: "27075",
  },
  {
    id: 581,
    country_name: "CO-CHO-BAJ77",
    country_code: "CO-CHO-BAJ77",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Bajo Baudó",
    city_code: "77",
    code: "27077",
  },
  {
    id: 582,
    country_name: "CO-CHO-BOJ99",
    country_code: "CO-CHO-BOJ99",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Bojayá",
    city_code: "99",
    code: "27099",
  },
  {
    id: 583,
    country_name: "CO-CHO-EL 135",
    country_code: "CO-CHO-EL 135",
    state_name: "Chocó",
    state_code: "27",
    city_name: "El Cantón Del San Pablo",
    city_code: "135",
    code: "27135",
  },
  {
    id: 584,
    country_name: "CO-CHO-CAR150",
    country_code: "CO-CHO-CAR150",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Carmen Del Darién",
    city_code: "150",
    code: "27150",
  },
  {
    id: 585,
    country_name: "CO-CHO-CER160",
    country_code: "CO-CHO-CER160",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Cértegui",
    city_code: "160",
    code: "27160",
  },
  {
    id: 586,
    country_name: "CO-CHO-CON205",
    country_code: "CO-CHO-CON205",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Condoto",
    city_code: "205",
    code: "27205",
  },
  {
    id: 587,
    country_name: "CO-CHO-EL 245",
    country_code: "CO-CHO-EL 245",
    state_name: "Chocó",
    state_code: "27",
    city_name: "El Carmen De Atrato",
    city_code: "245",
    code: "27245",
  },
  {
    id: 588,
    country_name: "CO-CHO-EL 250",
    country_code: "CO-CHO-EL 250",
    state_name: "Chocó",
    state_code: "27",
    city_name: "El Litoral Del San Juan",
    city_code: "250",
    code: "27250",
  },
  {
    id: 589,
    country_name: "CO-CHO-IST361",
    country_code: "CO-CHO-IST361",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Istmina",
    city_code: "361",
    code: "27361",
  },
  {
    id: 590,
    country_name: "CO-CHO-JUR372",
    country_code: "CO-CHO-JUR372",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Juradó",
    city_code: "372",
    code: "27372",
  },
  {
    id: 591,
    country_name: "CO-CHO-LLO413",
    country_code: "CO-CHO-LLO413",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Lloró",
    city_code: "413",
    code: "27413",
  },
  {
    id: 592,
    country_name: "CO-CHO-MED425",
    country_code: "CO-CHO-MED425",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Medio Atrato",
    city_code: "425",
    code: "27425",
  },
  {
    id: 593,
    country_name: "CO-CHO-MED430",
    country_code: "CO-CHO-MED430",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Medio Baudó",
    city_code: "430",
    code: "27430",
  },
  {
    id: 594,
    country_name: "CO-CHO-MED450",
    country_code: "CO-CHO-MED450",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Medio San Juan",
    city_code: "450",
    code: "27450",
  },
  {
    id: 595,
    country_name: "CO-CHO-NOV491",
    country_code: "CO-CHO-NOV491",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Nóvita",
    city_code: "491",
    code: "27491",
  },
  {
    id: 596,
    country_name: "CO-CHO-NUQ495",
    country_code: "CO-CHO-NUQ495",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Nuquí",
    city_code: "495",
    code: "27495",
  },
  {
    id: 597,
    country_name: "CO-CHO-RIO580",
    country_code: "CO-CHO-RIO580",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Río Iró",
    city_code: "580",
    code: "27580",
  },
  {
    id: 598,
    country_name: "CO-CHO-RIO600",
    country_code: "CO-CHO-RIO600",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Río Quito",
    city_code: "600",
    code: "27600",
  },
  {
    id: 599,
    country_name: "CO-CHO-RIO615",
    country_code: "CO-CHO-RIO615",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Riosucio",
    city_code: "615",
    code: "27615",
  },
  {
    id: 600,
    country_name: "CO-CHO-SAN660",
    country_code: "CO-CHO-SAN660",
    state_name: "Chocó",
    state_code: "27",
    city_name: "San José Del Palmar",
    city_code: "660",
    code: "27660",
  },
  {
    id: 601,
    country_name: "CO-CHO-SIP745",
    country_code: "CO-CHO-SIP745",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Sipí",
    city_code: "745",
    code: "27745",
  },
  {
    id: 602,
    country_name: "CO-CHO-TAD787",
    country_code: "CO-CHO-TAD787",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Tadó",
    city_code: "787",
    code: "27787",
  },
  {
    id: 603,
    country_name: "CO-CHO-UNG800",
    country_code: "CO-CHO-UNG800",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Unguía",
    city_code: "800",
    code: "27800",
  },
  {
    id: 604,
    country_name: "CO-CHO-UNI810",
    country_code: "CO-CHO-UNI810",
    state_name: "Chocó",
    state_code: "27",
    city_name: "Unión Panamericana",
    city_code: "810",
    code: "27810",
  },
  {
    id: 605,
    country_name: "CO-HUI-NEI1",
    country_code: "CO-HUI-NEI1",
    state_name: "Huila",
    state_code: "41",
    city_name: "Neiva",
    city_code: "1",
    code: "41001",
  },
  {
    id: 606,
    country_name: "CO-HUI-ACE6",
    country_code: "CO-HUI-ACE6",
    state_name: "Huila",
    state_code: "41",
    city_name: "Acevedo",
    city_code: "6",
    code: "41006",
  },
  {
    id: 607,
    country_name: "CO-HUI-AGR13",
    country_code: "CO-HUI-AGR13",
    state_name: "Huila",
    state_code: "41",
    city_name: "Agrado",
    city_code: "13",
    code: "41013",
  },
  {
    id: 608,
    country_name: "CO-HUI-AIP16",
    country_code: "CO-HUI-AIP16",
    state_name: "Huila",
    state_code: "41",
    city_name: "Aipe",
    city_code: "16",
    code: "41016",
  },
  {
    id: 609,
    country_name: "CO-HUI-ALG20",
    country_code: "CO-HUI-ALG20",
    state_name: "Huila",
    state_code: "41",
    city_name: "Algeciras",
    city_code: "20",
    code: "41020",
  },
  {
    id: 610,
    country_name: "CO-HUI-ALT26",
    country_code: "CO-HUI-ALT26",
    state_name: "Huila",
    state_code: "41",
    city_name: "Altamira",
    city_code: "26",
    code: "41026",
  },
  {
    id: 611,
    country_name: "CO-HUI-BAR78",
    country_code: "CO-HUI-BAR78",
    state_name: "Huila",
    state_code: "41",
    city_name: "Baraya",
    city_code: "78",
    code: "41078",
  },
  {
    id: 612,
    country_name: "CO-HUI-CAM132",
    country_code: "CO-HUI-CAM132",
    state_name: "Huila",
    state_code: "41",
    city_name: "Campoalegre",
    city_code: "132",
    code: "41132",
  },
  {
    id: 613,
    country_name: "CO-HUI-COL206",
    country_code: "CO-HUI-COL206",
    state_name: "Huila",
    state_code: "41",
    city_name: "Colombia",
    city_code: "206",
    code: "41206",
  },
  {
    id: 614,
    country_name: "CO-HUI-ELI244",
    country_code: "CO-HUI-ELI244",
    state_name: "Huila",
    state_code: "41",
    city_name: "Elías",
    city_code: "244",
    code: "41244",
  },
  {
    id: 615,
    country_name: "CO-HUI-GAR298",
    country_code: "CO-HUI-GAR298",
    state_name: "Huila",
    state_code: "41",
    city_name: "Garzón",
    city_code: "298",
    code: "41298",
  },
  {
    id: 616,
    country_name: "CO-HUI-GIG306",
    country_code: "CO-HUI-GIG306",
    state_name: "Huila",
    state_code: "41",
    city_name: "Gigante",
    city_code: "306",
    code: "41306",
  },
  {
    id: 617,
    country_name: "CO-HUI-GUA319",
    country_code: "CO-HUI-GUA319",
    state_name: "Huila",
    state_code: "41",
    city_name: "Guadalupe",
    city_code: "319",
    code: "41319",
  },
  {
    id: 618,
    country_name: "CO-HUI-HOB349",
    country_code: "CO-HUI-HOB349",
    state_name: "Huila",
    state_code: "41",
    city_name: "Hobo",
    city_code: "349",
    code: "41349",
  },
  {
    id: 619,
    country_name: "CO-HUI-IQU357",
    country_code: "CO-HUI-IQU357",
    state_name: "Huila",
    state_code: "41",
    city_name: "Íquira",
    city_code: "357",
    code: "41357",
  },
  {
    id: 620,
    country_name: "CO-HUI-ISN359",
    country_code: "CO-HUI-ISN359",
    state_name: "Huila",
    state_code: "41",
    city_name: "Isnos",
    city_code: "359",
    code: "41359",
  },
  {
    id: 621,
    country_name: "CO-HUI-LA 378",
    country_code: "CO-HUI-LA 378",
    state_name: "Huila",
    state_code: "41",
    city_name: "La Argentina",
    city_code: "378",
    code: "41378",
  },
  {
    id: 622,
    country_name: "CO-HUI-LA 396",
    country_code: "CO-HUI-LA 396",
    state_name: "Huila",
    state_code: "41",
    city_name: "La Plata",
    city_code: "396",
    code: "41396",
  },
  {
    id: 623,
    country_name: "CO-HUI-NAT483",
    country_code: "CO-HUI-NAT483",
    state_name: "Huila",
    state_code: "41",
    city_name: "Nátaga",
    city_code: "483",
    code: "41483",
  },
  {
    id: 624,
    country_name: "CO-HUI-OPO503",
    country_code: "CO-HUI-OPO503",
    state_name: "Huila",
    state_code: "41",
    city_name: "Oporapa",
    city_code: "503",
    code: "41503",
  },
  {
    id: 625,
    country_name: "CO-HUI-PAI518",
    country_code: "CO-HUI-PAI518",
    state_name: "Huila",
    state_code: "41",
    city_name: "Paicol",
    city_code: "518",
    code: "41518",
  },
  {
    id: 626,
    country_name: "CO-HUI-PAL524",
    country_code: "CO-HUI-PAL524",
    state_name: "Huila",
    state_code: "41",
    city_name: "Palermo",
    city_code: "524",
    code: "41524",
  },
  {
    id: 627,
    country_name: "CO-HUI-PAL530",
    country_code: "CO-HUI-PAL530",
    state_name: "Huila",
    state_code: "41",
    city_name: "Palestina",
    city_code: "530",
    code: "41530",
  },
  {
    id: 628,
    country_name: "CO-HUI-PIT548",
    country_code: "CO-HUI-PIT548",
    state_name: "Huila",
    state_code: "41",
    city_name: "Pital",
    city_code: "548",
    code: "41548",
  },
  {
    id: 629,
    country_name: "CO-HUI-PIT551",
    country_code: "CO-HUI-PIT551",
    state_name: "Huila",
    state_code: "41",
    city_name: "Pitalito",
    city_code: "551",
    code: "41551",
  },
  {
    id: 630,
    country_name: "CO-HUI-RIV615",
    country_code: "CO-HUI-RIV615",
    state_name: "Huila",
    state_code: "41",
    city_name: "Rivera",
    city_code: "615",
    code: "41615",
  },
  {
    id: 631,
    country_name: "CO-HUI-SAL660",
    country_code: "CO-HUI-SAL660",
    state_name: "Huila",
    state_code: "41",
    city_name: "Saladoblanco",
    city_code: "660",
    code: "41660",
  },
  {
    id: 632,
    country_name: "CO-HUI-SAN668",
    country_code: "CO-HUI-SAN668",
    state_name: "Huila",
    state_code: "41",
    city_name: "San Agustín",
    city_code: "668",
    code: "41668",
  },
  {
    id: 633,
    country_name: "CO-HUI-SAN676",
    country_code: "CO-HUI-SAN676",
    state_name: "Huila",
    state_code: "41",
    city_name: "Santa María",
    city_code: "676",
    code: "41676",
  },
  {
    id: 634,
    country_name: "CO-HUI-SUA770",
    country_code: "CO-HUI-SUA770",
    state_name: "Huila",
    state_code: "41",
    city_name: "Suaza",
    city_code: "770",
    code: "41770",
  },
  {
    id: 635,
    country_name: "CO-HUI-TAR791",
    country_code: "CO-HUI-TAR791",
    state_name: "Huila",
    state_code: "41",
    city_name: "Tarqui",
    city_code: "791",
    code: "41791",
  },
  {
    id: 636,
    country_name: "CO-HUI-TES797",
    country_code: "CO-HUI-TES797",
    state_name: "Huila",
    state_code: "41",
    city_name: "Tesalia",
    city_code: "797",
    code: "41797",
  },
  {
    id: 637,
    country_name: "CO-HUI-TEL799",
    country_code: "CO-HUI-TEL799",
    state_name: "Huila",
    state_code: "41",
    city_name: "Tello",
    city_code: "799",
    code: "41799",
  },
  {
    id: 638,
    country_name: "CO-HUI-TER801",
    country_code: "CO-HUI-TER801",
    state_name: "Huila",
    state_code: "41",
    city_name: "Teruel",
    city_code: "801",
    code: "41801",
  },
  {
    id: 639,
    country_name: "CO-HUI-TIM807",
    country_code: "CO-HUI-TIM807",
    state_name: "Huila",
    state_code: "41",
    city_name: "Timaná",
    city_code: "807",
    code: "41807",
  },
  {
    id: 640,
    country_name: "CO-HUI-VIL872",
    country_code: "CO-HUI-VIL872",
    state_name: "Huila",
    state_code: "41",
    city_name: "Villavieja",
    city_code: "872",
    code: "41872",
  },
  {
    id: 641,
    country_name: "CO-HUI-YAG885",
    country_code: "CO-HUI-YAG885",
    state_name: "Huila",
    state_code: "41",
    city_name: "Yaguará",
    city_code: "885",
    code: "41885",
  },
  {
    id: 642,
    country_name: "CO-LA -RIO1",
    country_code: "CO-LA -RIO1",
    state_name: "La Guajira",
    state_code: "44",
    city_name: "Riohacha",
    city_code: "1",
    code: "44001",
  },
  {
    id: 643,
    country_name: "CO-LA -ALB35",
    country_code: "CO-LA -ALB35",
    state_name: "La Guajira",
    state_code: "44",
    city_name: "Albania",
    city_code: "35",
    code: "44035",
  },
  {
    id: 644,
    country_name: "CO-LA -BAR78",
    country_code: "CO-LA -BAR78",
    state_name: "La Guajira",
    state_code: "44",
    city_name: "Barrancas",
    city_code: "78",
    code: "44078",
  },
  {
    id: 645,
    country_name: "CO-LA -DIB90",
    country_code: "CO-LA -DIB90",
    state_name: "La Guajira",
    state_code: "44",
    city_name: "Dibulla",
    city_code: "90",
    code: "44090",
  },
  {
    id: 646,
    country_name: "CO-LA -DIS98",
    country_code: "CO-LA -DIS98",
    state_name: "La Guajira",
    state_code: "44",
    city_name: "Distracción",
    city_code: "98",
    code: "44098",
  },
  {
    id: 647,
    country_name: "CO-LA -EL 110",
    country_code: "CO-LA -EL 110",
    state_name: "La Guajira",
    state_code: "44",
    city_name: "El Molino",
    city_code: "110",
    code: "44110",
  },
  {
    id: 648,
    country_name: "CO-LA -FON279",
    country_code: "CO-LA -FON279",
    state_name: "La Guajira",
    state_code: "44",
    city_name: "Fonseca",
    city_code: "279",
    code: "44279",
  },
  {
    id: 649,
    country_name: "CO-LA -HAT378",
    country_code: "CO-LA -HAT378",
    state_name: "La Guajira",
    state_code: "44",
    city_name: "Hatonuevo",
    city_code: "378",
    code: "44378",
  },
  {
    id: 650,
    country_name: "CO-LA -LA 420",
    country_code: "CO-LA -LA 420",
    state_name: "La Guajira",
    state_code: "44",
    city_name: "La Jagua Del Pilar",
    city_code: "420",
    code: "44420",
  },
  {
    id: 651,
    country_name: "CO-LA -MAI430",
    country_code: "CO-LA -MAI430",
    state_name: "La Guajira",
    state_code: "44",
    city_name: "Maicao",
    city_code: "430",
    code: "44430",
  },
  {
    id: 652,
    country_name: "CO-LA -MAN560",
    country_code: "CO-LA -MAN560",
    state_name: "La Guajira",
    state_code: "44",
    city_name: "Manaure",
    city_code: "560",
    code: "44560",
  },
  {
    id: 653,
    country_name: "CO-LA -SAN650",
    country_code: "CO-LA -SAN650",
    state_name: "La Guajira",
    state_code: "44",
    city_name: "San Juan Del Cesar",
    city_code: "650",
    code: "44650",
  },
  {
    id: 654,
    country_name: "CO-LA -URI847",
    country_code: "CO-LA -URI847",
    state_name: "La Guajira",
    state_code: "44",
    city_name: "Uribia",
    city_code: "847",
    code: "44847",
  },
  {
    id: 655,
    country_name: "CO-LA -URU855",
    country_code: "CO-LA -URU855",
    state_name: "La Guajira",
    state_code: "44",
    city_name: "Urumita",
    city_code: "855",
    code: "44855",
  },
  {
    id: 656,
    country_name: "CO-LA -VIL874",
    country_code: "CO-LA -VIL874",
    state_name: "La Guajira",
    state_code: "44",
    city_name: "Villanueva",
    city_code: "874",
    code: "44874",
  },
  {
    id: 657,
    country_name: "CO-MAG-SAN1",
    country_code: "CO-MAG-SAN1",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Santa Marta",
    city_code: "1",
    code: "47001",
  },
  {
    id: 658,
    country_name: "CO-MAG-ALG30",
    country_code: "CO-MAG-ALG30",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Algarrobo",
    city_code: "30",
    code: "47030",
  },
  {
    id: 659,
    country_name: "CO-MAG-ARA53",
    country_code: "CO-MAG-ARA53",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Aracataca",
    city_code: "53",
    code: "47053",
  },
  {
    id: 660,
    country_name: "CO-MAG-ARI58",
    country_code: "CO-MAG-ARI58",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Ariguaní",
    city_code: "58",
    code: "47058",
  },
  {
    id: 661,
    country_name: "CO-MAG-CER161",
    country_code: "CO-MAG-CER161",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Cerro De San Antonio",
    city_code: "161",
    code: "47161",
  },
  {
    id: 662,
    country_name: "CO-MAG-CHI170",
    country_code: "CO-MAG-CHI170",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Chivolo",
    city_code: "170",
    code: "47170",
  },
  {
    id: 663,
    country_name: "CO-MAG-CIE189",
    country_code: "CO-MAG-CIE189",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Ciénaga",
    city_code: "189",
    code: "47189",
  },
  {
    id: 664,
    country_name: "CO-MAG-CON205",
    country_code: "CO-MAG-CON205",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Concordia",
    city_code: "205",
    code: "47205",
  },
  {
    id: 665,
    country_name: "CO-MAG-EL 245",
    country_code: "CO-MAG-EL 245",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "El Banco",
    city_code: "245",
    code: "47245",
  },
  {
    id: 666,
    country_name: "CO-MAG-EL 258",
    country_code: "CO-MAG-EL 258",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "El Piñón",
    city_code: "258",
    code: "47258",
  },
  {
    id: 667,
    country_name: "CO-MAG-EL 268",
    country_code: "CO-MAG-EL 268",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "El Retén",
    city_code: "268",
    code: "47268",
  },
  {
    id: 668,
    country_name: "CO-MAG-FUN288",
    country_code: "CO-MAG-FUN288",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Fundación",
    city_code: "288",
    code: "47288",
  },
  {
    id: 669,
    country_name: "CO-MAG-GUA318",
    country_code: "CO-MAG-GUA318",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Guamal",
    city_code: "318",
    code: "47318",
  },
  {
    id: 670,
    country_name: "CO-MAG-NUE460",
    country_code: "CO-MAG-NUE460",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Nueva Granada",
    city_code: "460",
    code: "47460",
  },
  {
    id: 671,
    country_name: "CO-MAG-PED541",
    country_code: "CO-MAG-PED541",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Pedraza",
    city_code: "541",
    code: "47541",
  },
  {
    id: 672,
    country_name: "CO-MAG-PIJ545",
    country_code: "CO-MAG-PIJ545",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Pijiño Del Carmen",
    city_code: "545",
    code: "47545",
  },
  {
    id: 673,
    country_name: "CO-MAG-PIV551",
    country_code: "CO-MAG-PIV551",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Pivijay",
    city_code: "551",
    code: "47551",
  },
  {
    id: 674,
    country_name: "CO-MAG-PLA555",
    country_code: "CO-MAG-PLA555",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Plato",
    city_code: "555",
    code: "47555",
  },
  {
    id: 675,
    country_name: "CO-MAG-PUE570",
    country_code: "CO-MAG-PUE570",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Puebloviejo",
    city_code: "570",
    code: "47570",
  },
  {
    id: 676,
    country_name: "CO-MAG-REM605",
    country_code: "CO-MAG-REM605",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Remolino",
    city_code: "605",
    code: "47605",
  },
  {
    id: 677,
    country_name: "CO-MAG-SAB660",
    country_code: "CO-MAG-SAB660",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Sabanas De San Ángel",
    city_code: "660",
    code: "47660",
  },
  {
    id: 678,
    country_name: "CO-MAG-SAL675",
    country_code: "CO-MAG-SAL675",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Salamina",
    city_code: "675",
    code: "47675",
  },
  {
    id: 679,
    country_name: "CO-MAG-SAN692",
    country_code: "CO-MAG-SAN692",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "San Sebastián De Buenavista",
    city_code: "692",
    code: "47692",
  },
  {
    id: 680,
    country_name: "CO-MAG-SAN703",
    country_code: "CO-MAG-SAN703",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "San Zenón",
    city_code: "703",
    code: "47703",
  },
  {
    id: 681,
    country_name: "CO-MAG-SAN707",
    country_code: "CO-MAG-SAN707",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Santa Ana",
    city_code: "707",
    code: "47707",
  },
  {
    id: 682,
    country_name: "CO-MAG-SAN720",
    country_code: "CO-MAG-SAN720",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Santa Bárbara De Pinto",
    city_code: "720",
    code: "47720",
  },
  {
    id: 683,
    country_name: "CO-MAG-SIT745",
    country_code: "CO-MAG-SIT745",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Sitionuevo",
    city_code: "745",
    code: "47745",
  },
  {
    id: 684,
    country_name: "CO-MAG-TEN798",
    country_code: "CO-MAG-TEN798",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Tenerife",
    city_code: "798",
    code: "47798",
  },
  {
    id: 685,
    country_name: "CO-MAG-ZAP960",
    country_code: "CO-MAG-ZAP960",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Zapayán",
    city_code: "960",
    code: "47960",
  },
  {
    id: 686,
    country_name: "CO-MAG-ZON980",
    country_code: "CO-MAG-ZON980",
    state_name: "Magdalena",
    state_code: "47",
    city_name: "Zona Bananera",
    city_code: "980",
    code: "47980",
  },
  {
    id: 687,
    country_name: "CO-MET-VIL1",
    country_code: "CO-MET-VIL1",
    state_name: "Meta",
    state_code: "50",
    city_name: "Villavicencio",
    city_code: "1",
    code: "50001",
  },
  {
    id: 688,
    country_name: "CO-MET-ACA6",
    country_code: "CO-MET-ACA6",
    state_name: "Meta",
    state_code: "50",
    city_name: "Acacías",
    city_code: "6",
    code: "50006",
  },
  {
    id: 689,
    country_name: "CO-MET-BAR110",
    country_code: "CO-MET-BAR110",
    state_name: "Meta",
    state_code: "50",
    city_name: "Barranca De Upía",
    city_code: "110",
    code: "50110",
  },
  {
    id: 690,
    country_name: "CO-MET-CAB124",
    country_code: "CO-MET-CAB124",
    state_name: "Meta",
    state_code: "50",
    city_name: "Cabuyaro",
    city_code: "124",
    code: "50124",
  },
  {
    id: 691,
    country_name: "CO-MET-CAS150",
    country_code: "CO-MET-CAS150",
    state_name: "Meta",
    state_code: "50",
    city_name: "Castilla La Nueva",
    city_code: "150",
    code: "50150",
  },
  {
    id: 692,
    country_name: "CO-MET-CUB223",
    country_code: "CO-MET-CUB223",
    state_name: "Meta",
    state_code: "50",
    city_name: "San Luis De Cubarral",
    city_code: "223",
    code: "50223",
  },
  {
    id: 693,
    country_name: "CO-MET-CUM226",
    country_code: "CO-MET-CUM226",
    state_name: "Meta",
    state_code: "50",
    city_name: "Cumaral",
    city_code: "226",
    code: "50226",
  },
  {
    id: 694,
    country_name: "CO-MET-EL 245",
    country_code: "CO-MET-EL 245",
    state_name: "Meta",
    state_code: "50",
    city_name: "El Calvario",
    city_code: "245",
    code: "50245",
  },
  {
    id: 695,
    country_name: "CO-MET-EL 251",
    country_code: "CO-MET-EL 251",
    state_name: "Meta",
    state_code: "50",
    city_name: "El Castillo",
    city_code: "251",
    code: "50251",
  },
  {
    id: 696,
    country_name: "CO-MET-EL 270",
    country_code: "CO-MET-EL 270",
    state_name: "Meta",
    state_code: "50",
    city_name: "El Dorado",
    city_code: "270",
    code: "50270",
  },
  {
    id: 697,
    country_name: "CO-MET-FUE287",
    country_code: "CO-MET-FUE287",
    state_name: "Meta",
    state_code: "50",
    city_name: "Fuente De Oro",
    city_code: "287",
    code: "50287",
  },
  {
    id: 698,
    country_name: "CO-MET-GRA313",
    country_code: "CO-MET-GRA313",
    state_name: "Meta",
    state_code: "50",
    city_name: "Granada",
    city_code: "313",
    code: "50313",
  },
  {
    id: 699,
    country_name: "CO-MET-GUA318",
    country_code: "CO-MET-GUA318",
    state_name: "Meta",
    state_code: "50",
    city_name: "Guamal",
    city_code: "318",
    code: "50318",
  },
  {
    id: 700,
    country_name: "CO-MET-MAP325",
    country_code: "CO-MET-MAP325",
    state_name: "Meta",
    state_code: "50",
    city_name: "Mapiripán",
    city_code: "325",
    code: "50325",
  },
  {
    id: 701,
    country_name: "CO-MET-MES330",
    country_code: "CO-MET-MES330",
    state_name: "Meta",
    state_code: "50",
    city_name: "Mesetas",
    city_code: "330",
    code: "50330",
  },
  {
    id: 702,
    country_name: "CO-MET-LA 350",
    country_code: "CO-MET-LA 350",
    state_name: "Meta",
    state_code: "50",
    city_name: "La Macarena",
    city_code: "350",
    code: "50350",
  },
  {
    id: 703,
    country_name: "CO-MET-URI370",
    country_code: "CO-MET-URI370",
    state_name: "Meta",
    state_code: "50",
    city_name: "Uribe",
    city_code: "370",
    code: "50370",
  },
  {
    id: 704,
    country_name: "CO-MET-LEJ400",
    country_code: "CO-MET-LEJ400",
    state_name: "Meta",
    state_code: "50",
    city_name: "Lejanías",
    city_code: "400",
    code: "50400",
  },
  {
    id: 705,
    country_name: "CO-MET-PUE450",
    country_code: "CO-MET-PUE450",
    state_name: "Meta",
    state_code: "50",
    city_name: "Puerto Concordia",
    city_code: "450",
    code: "50450",
  },
  {
    id: 706,
    country_name: "CO-MET-PUE568",
    country_code: "CO-MET-PUE568",
    state_name: "Meta",
    state_code: "50",
    city_name: "Puerto Gaitán",
    city_code: "568",
    code: "50568",
  },
  {
    id: 707,
    country_name: "CO-MET-PUE573",
    country_code: "CO-MET-PUE573",
    state_name: "Meta",
    state_code: "50",
    city_name: "Puerto López",
    city_code: "573",
    code: "50573",
  },
  {
    id: 708,
    country_name: "CO-MET-PUE577",
    country_code: "CO-MET-PUE577",
    state_name: "Meta",
    state_code: "50",
    city_name: "Puerto Lleras",
    city_code: "577",
    code: "50577",
  },
  {
    id: 709,
    country_name: "CO-MET-PUE590",
    country_code: "CO-MET-PUE590",
    state_name: "Meta",
    state_code: "50",
    city_name: "Puerto Rico",
    city_code: "590",
    code: "50590",
  },
  {
    id: 710,
    country_name: "CO-MET-RES606",
    country_code: "CO-MET-RES606",
    state_name: "Meta",
    state_code: "50",
    city_name: "Restrepo",
    city_code: "606",
    code: "50606",
  },
  {
    id: 711,
    country_name: "CO-MET-SAN680",
    country_code: "CO-MET-SAN680",
    state_name: "Meta",
    state_code: "50",
    city_name: "San Carlos De Guaroa",
    city_code: "680",
    code: "50680",
  },
  {
    id: 712,
    country_name: "CO-MET-SAN683",
    country_code: "CO-MET-SAN683",
    state_name: "Meta",
    state_code: "50",
    city_name: "San Juan De Arama",
    city_code: "683",
    code: "50683",
  },
  {
    id: 713,
    country_name: "CO-MET-SAN686",
    country_code: "CO-MET-SAN686",
    state_name: "Meta",
    state_code: "50",
    city_name: "San Juanito",
    city_code: "686",
    code: "50686",
  },
  {
    id: 714,
    country_name: "CO-MET-SAN689",
    country_code: "CO-MET-SAN689",
    state_name: "Meta",
    state_code: "50",
    city_name: "San Martín",
    city_code: "689",
    code: "50689",
  },
  {
    id: 715,
    country_name: "CO-MET-VIS711",
    country_code: "CO-MET-VIS711",
    state_name: "Meta",
    state_code: "50",
    city_name: "Vistahermosa",
    city_code: "711",
    code: "50711",
  },
  {
    id: 716,
    country_name: "CO-NAR-PAS1",
    country_code: "CO-NAR-PAS1",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Pasto",
    city_code: "1",
    code: "52001",
  },
  {
    id: 717,
    country_name: "CO-NAR-ALB19",
    country_code: "CO-NAR-ALB19",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Albán",
    city_code: "19",
    code: "52019",
  },
  {
    id: 718,
    country_name: "CO-NAR-ALD22",
    country_code: "CO-NAR-ALD22",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Aldana",
    city_code: "22",
    code: "52022",
  },
  {
    id: 719,
    country_name: "CO-NAR-ANC36",
    country_code: "CO-NAR-ANC36",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Ancuyá",
    city_code: "36",
    code: "52036",
  },
  {
    id: 720,
    country_name: "CO-NAR-ARB51",
    country_code: "CO-NAR-ARB51",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Arboleda",
    city_code: "51",
    code: "52051",
  },
  {
    id: 721,
    country_name: "CO-NAR-BAR79",
    country_code: "CO-NAR-BAR79",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Barbacoas",
    city_code: "79",
    code: "52079",
  },
  {
    id: 722,
    country_name: "CO-NAR-BEL83",
    country_code: "CO-NAR-BEL83",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Belén",
    city_code: "83",
    code: "52083",
  },
  {
    id: 723,
    country_name: "CO-NAR-BUE110",
    country_code: "CO-NAR-BUE110",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Buesaco",
    city_code: "110",
    code: "52110",
  },
  {
    id: 724,
    country_name: "CO-NAR-COL203",
    country_code: "CO-NAR-COL203",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Colón",
    city_code: "203",
    code: "52203",
  },
  {
    id: 725,
    country_name: "CO-NAR-CON207",
    country_code: "CO-NAR-CON207",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Consacá",
    city_code: "207",
    code: "52207",
  },
  {
    id: 726,
    country_name: "CO-NAR-CON210",
    country_code: "CO-NAR-CON210",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Contadero",
    city_code: "210",
    code: "52210",
  },
  {
    id: 727,
    country_name: "CO-NAR-COR215",
    country_code: "CO-NAR-COR215",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Córdoba",
    city_code: "215",
    code: "52215",
  },
  {
    id: 728,
    country_name: "CO-NAR-CUA224",
    country_code: "CO-NAR-CUA224",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Cuaspúd",
    city_code: "224",
    code: "52224",
  },
  {
    id: 729,
    country_name: "CO-NAR-CUM227",
    country_code: "CO-NAR-CUM227",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Cumbal",
    city_code: "227",
    code: "52227",
  },
  {
    id: 730,
    country_name: "CO-NAR-CUM233",
    country_code: "CO-NAR-CUM233",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Cumbitara",
    city_code: "233",
    code: "52233",
  },
  {
    id: 731,
    country_name: "CO-NAR-CHA240",
    country_code: "CO-NAR-CHA240",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Chachagüí",
    city_code: "240",
    code: "52240",
  },
  {
    id: 732,
    country_name: "CO-NAR-EL 250",
    country_code: "CO-NAR-EL 250",
    state_name: "Nariño",
    state_code: "52",
    city_name: "El Charco",
    city_code: "250",
    code: "52250",
  },
  {
    id: 733,
    country_name: "CO-NAR-EL 254",
    country_code: "CO-NAR-EL 254",
    state_name: "Nariño",
    state_code: "52",
    city_name: "El Peñol",
    city_code: "254",
    code: "52254",
  },
  {
    id: 734,
    country_name: "CO-NAR-EL 256",
    country_code: "CO-NAR-EL 256",
    state_name: "Nariño",
    state_code: "52",
    city_name: "El Rosario",
    city_code: "256",
    code: "52256",
  },
  {
    id: 735,
    country_name: "CO-NAR-EL 258",
    country_code: "CO-NAR-EL 258",
    state_name: "Nariño",
    state_code: "52",
    city_name: "El Tablón De Gómez",
    city_code: "258",
    code: "52258",
  },
  {
    id: 736,
    country_name: "CO-NAR-EL 260",
    country_code: "CO-NAR-EL 260",
    state_name: "Nariño",
    state_code: "52",
    city_name: "El Tambo",
    city_code: "260",
    code: "52260",
  },
  {
    id: 737,
    country_name: "CO-NAR-FUN287",
    country_code: "CO-NAR-FUN287",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Funes",
    city_code: "287",
    code: "52287",
  },
  {
    id: 738,
    country_name: "CO-NAR-GUA317",
    country_code: "CO-NAR-GUA317",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Guachucal",
    city_code: "317",
    code: "52317",
  },
  {
    id: 739,
    country_name: "CO-NAR-GUA320",
    country_code: "CO-NAR-GUA320",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Guaitarilla",
    city_code: "320",
    code: "52320",
  },
  {
    id: 740,
    country_name: "CO-NAR-GUA323",
    country_code: "CO-NAR-GUA323",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Gualmatán",
    city_code: "323",
    code: "52323",
  },
  {
    id: 741,
    country_name: "CO-NAR-ILE352",
    country_code: "CO-NAR-ILE352",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Iles",
    city_code: "352",
    code: "52352",
  },
  {
    id: 742,
    country_name: "CO-NAR-IMU354",
    country_code: "CO-NAR-IMU354",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Imués",
    city_code: "354",
    code: "52354",
  },
  {
    id: 743,
    country_name: "CO-NAR-IPI356",
    country_code: "CO-NAR-IPI356",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Ipiales",
    city_code: "356",
    code: "52356",
  },
  {
    id: 744,
    country_name: "CO-NAR-LA 378",
    country_code: "CO-NAR-LA 378",
    state_name: "Nariño",
    state_code: "52",
    city_name: "La Cruz",
    city_code: "378",
    code: "52378",
  },
  {
    id: 745,
    country_name: "CO-NAR-LA 381",
    country_code: "CO-NAR-LA 381",
    state_name: "Nariño",
    state_code: "52",
    city_name: "La Florida",
    city_code: "381",
    code: "52381",
  },
  {
    id: 746,
    country_name: "CO-NAR-LA 385",
    country_code: "CO-NAR-LA 385",
    state_name: "Nariño",
    state_code: "52",
    city_name: "La Llanada",
    city_code: "385",
    code: "52385",
  },
  {
    id: 747,
    country_name: "CO-NAR-LA 390",
    country_code: "CO-NAR-LA 390",
    state_name: "Nariño",
    state_code: "52",
    city_name: "La Tola",
    city_code: "390",
    code: "52390",
  },
  {
    id: 748,
    country_name: "CO-NAR-LA 399",
    country_code: "CO-NAR-LA 399",
    state_name: "Nariño",
    state_code: "52",
    city_name: "La Unión",
    city_code: "399",
    code: "52399",
  },
  {
    id: 749,
    country_name: "CO-NAR-LEI405",
    country_code: "CO-NAR-LEI405",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Leiva",
    city_code: "405",
    code: "52405",
  },
  {
    id: 750,
    country_name: "CO-NAR-LIN411",
    country_code: "CO-NAR-LIN411",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Linares",
    city_code: "411",
    code: "52411",
  },
  {
    id: 751,
    country_name: "CO-NAR-LOS418",
    country_code: "CO-NAR-LOS418",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Los Andes",
    city_code: "418",
    code: "52418",
  },
  {
    id: 752,
    country_name: "CO-NAR-MAG427",
    country_code: "CO-NAR-MAG427",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Magüí",
    city_code: "427",
    code: "52427",
  },
  {
    id: 753,
    country_name: "CO-NAR-MAL435",
    country_code: "CO-NAR-MAL435",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Mallama",
    city_code: "435",
    code: "52435",
  },
  {
    id: 754,
    country_name: "CO-NAR-MOS473",
    country_code: "CO-NAR-MOS473",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Mosquera",
    city_code: "473",
    code: "52473",
  },
  {
    id: 755,
    country_name: "CO-NAR-NAR480",
    country_code: "CO-NAR-NAR480",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Nariño",
    city_code: "480",
    code: "52480",
  },
  {
    id: 756,
    country_name: "CO-NAR-OLA490",
    country_code: "CO-NAR-OLA490",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Olaya Herrera",
    city_code: "490",
    code: "52490",
  },
  {
    id: 757,
    country_name: "CO-NAR-OSP506",
    country_code: "CO-NAR-OSP506",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Ospina",
    city_code: "506",
    code: "52506",
  },
  {
    id: 758,
    country_name: "CO-NAR-FRA520",
    country_code: "CO-NAR-FRA520",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Francisco Pizarro",
    city_code: "520",
    code: "52520",
  },
  {
    id: 759,
    country_name: "CO-NAR-POL540",
    country_code: "CO-NAR-POL540",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Policarpa",
    city_code: "540",
    code: "52540",
  },
  {
    id: 760,
    country_name: "CO-NAR-POT560",
    country_code: "CO-NAR-POT560",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Potosí",
    city_code: "560",
    code: "52560",
  },
  {
    id: 761,
    country_name: "CO-NAR-PRO565",
    country_code: "CO-NAR-PRO565",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Providencia",
    city_code: "565",
    code: "52565",
  },
  {
    id: 762,
    country_name: "CO-NAR-PUE573",
    country_code: "CO-NAR-PUE573",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Puerres",
    city_code: "573",
    code: "52573",
  },
  {
    id: 763,
    country_name: "CO-NAR-PUP585",
    country_code: "CO-NAR-PUP585",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Pupiales",
    city_code: "585",
    code: "52585",
  },
  {
    id: 764,
    country_name: "CO-NAR-RIC612",
    country_code: "CO-NAR-RIC612",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Ricaurte",
    city_code: "612",
    code: "52612",
  },
  {
    id: 765,
    country_name: "CO-NAR-ROB621",
    country_code: "CO-NAR-ROB621",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Roberto Payán",
    city_code: "621",
    code: "52621",
  },
  {
    id: 766,
    country_name: "CO-NAR-SAM678",
    country_code: "CO-NAR-SAM678",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Samaniego",
    city_code: "678",
    code: "52678",
  },
  {
    id: 767,
    country_name: "CO-NAR-SAN683",
    country_code: "CO-NAR-SAN683",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Sandoná",
    city_code: "683",
    code: "52683",
  },
  {
    id: 768,
    country_name: "CO-NAR-SAN685",
    country_code: "CO-NAR-SAN685",
    state_name: "Nariño",
    state_code: "52",
    city_name: "San Bernardo",
    city_code: "685",
    code: "52685",
  },
  {
    id: 769,
    country_name: "CO-NAR-SAN687",
    country_code: "CO-NAR-SAN687",
    state_name: "Nariño",
    state_code: "52",
    city_name: "San Lorenzo",
    city_code: "687",
    code: "52687",
  },
  {
    id: 770,
    country_name: "CO-NAR-SAN693",
    country_code: "CO-NAR-SAN693",
    state_name: "Nariño",
    state_code: "52",
    city_name: "San Pablo",
    city_code: "693",
    code: "52693",
  },
  {
    id: 771,
    country_name: "CO-NAR-SAN694",
    country_code: "CO-NAR-SAN694",
    state_name: "Nariño",
    state_code: "52",
    city_name: "San Pedro De Cartago",
    city_code: "694",
    code: "52694",
  },
  {
    id: 772,
    country_name: "CO-NAR-SAN696",
    country_code: "CO-NAR-SAN696",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Santa Bárbara",
    city_code: "696",
    code: "52696",
  },
  {
    id: 773,
    country_name: "CO-NAR-SAN699",
    country_code: "CO-NAR-SAN699",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Santacruz",
    city_code: "699",
    code: "52699",
  },
  {
    id: 774,
    country_name: "CO-NAR-SAP720",
    country_code: "CO-NAR-SAP720",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Sapuyes",
    city_code: "720",
    code: "52720",
  },
  {
    id: 775,
    country_name: "CO-NAR-TAM786",
    country_code: "CO-NAR-TAM786",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Taminango",
    city_code: "786",
    code: "52786",
  },
  {
    id: 776,
    country_name: "CO-NAR-TAN788",
    country_code: "CO-NAR-TAN788",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Tangua",
    city_code: "788",
    code: "52788",
  },
  {
    id: 777,
    country_name: "CO-NAR-SAN835",
    country_code: "CO-NAR-SAN835",
    state_name: "Nariño",
    state_code: "52",
    city_name: "San Andrés De Tumaco",
    city_code: "835",
    code: "52835",
  },
  {
    id: 778,
    country_name: "CO-NAR-TUQ838",
    country_code: "CO-NAR-TUQ838",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Túquerres",
    city_code: "838",
    code: "52838",
  },
  {
    id: 779,
    country_name: "CO-NAR-YAC885",
    country_code: "CO-NAR-YAC885",
    state_name: "Nariño",
    state_code: "52",
    city_name: "Yacuanquer",
    city_code: "885",
    code: "52885",
  },
  {
    id: 780,
    country_name: "CO-N. -CUC1",
    country_code: "CO-N. -CUC1",
    state_name: "Norte de Santander",
    state_code: "54",
    city_name: "Cúcuta",
    city_code: "1",
    code: "54001",
  },
  {
    id: 781,
    country_name: "CO-N. -ABR3",
    country_code: "CO-N. -ABR3",
    state_name: "Norte de Santander",
    state_code: "54",
    city_name: "Ábrego",
    city_code: "3",
    code: "54003",
  },
  {
    id: 782,
    country_name: "CO-N. -ARB51",
    country_code: "CO-N. -ARB51",
    state_name: "Norte de Santander",
    state_code: "54",
    city_name: "Arboledas",
    city_code: "51",
    code: "54051",
  },
  {
    id: 783,
    country_name: "CO-N. -BOC99",
    country_code: "CO-N. -BOC99",
    state_name: "Norte de Santander",
    state_code: "54",
    city_name: "Bochalema",
    city_code: "99",
    code: "54099",
  },
  {
    id: 784,
    country_name: "CO-N. -BUC109",
    country_code: "CO-N. -BUC109",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Bucarasica",
    city_code: "109",
    code: "54109",
  },
  {
    id: 785,
    country_name: "CO-N. -CAC125",
    country_code: "CO-N. -CAC125",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Cácota",
    city_code: "125",
    code: "54125",
  },
  {
    id: 786,
    country_name: "CO-N. -CAC128",
    country_code: "CO-N. -CAC128",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Cáchira",
    city_code: "128",
    code: "54128",
  },
  {
    id: 787,
    country_name: "CO-N. -CHI172",
    country_code: "CO-N. -CHI172",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Chinácota",
    city_code: "172",
    code: "54172",
  },
  {
    id: 788,
    country_name: "CO-N. -CHI174",
    country_code: "CO-N. -CHI174",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Chitagá",
    city_code: "174",
    code: "54174",
  },
  {
    id: 789,
    country_name: "CO-N. -CON206",
    country_code: "CO-N. -CON206",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Convención",
    city_code: "206",
    code: "54206",
  },
  {
    id: 790,
    country_name: "CO-N. -CUC223",
    country_code: "CO-N. -CUC223",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Cucutilla",
    city_code: "223",
    code: "54223",
  },
  {
    id: 791,
    country_name: "CO-N. -DUR239",
    country_code: "CO-N. -DUR239",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Durania",
    city_code: "239",
    code: "54239",
  },
  {
    id: 792,
    country_name: "CO-N. -EL 245",
    country_code: "CO-N. -EL 245",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "El Carmen",
    city_code: "245",
    code: "54245",
  },
  {
    id: 793,
    country_name: "CO-N. -EL 250",
    country_code: "CO-N. -EL 250",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "El Tarra",
    city_code: "250",
    code: "54250",
  },
  {
    id: 794,
    country_name: "CO-N. -EL 261",
    country_code: "CO-N. -EL 261",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "El Zulia",
    city_code: "261",
    code: "54261",
  },
  {
    id: 795,
    country_name: "CO-N. -GRA313",
    country_code: "CO-N. -GRA313",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Gramalote",
    city_code: "313",
    code: "54313",
  },
  {
    id: 796,
    country_name: "CO-N. -HAC344",
    country_code: "CO-N. -HAC344",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Hacarí",
    city_code: "344",
    code: "54344",
  },
  {
    id: 797,
    country_name: "CO-N. -HER347",
    country_code: "CO-N. -HER347",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Herrán",
    city_code: "347",
    code: "54347",
  },
  {
    id: 798,
    country_name: "CO-N. -LAB377",
    country_code: "CO-N. -LAB377",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Labateca",
    city_code: "377",
    code: "54377",
  },
  {
    id: 799,
    country_name: "CO-N. -LA 385",
    country_code: "CO-N. -LA 385",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "La Esperanza",
    city_code: "385",
    code: "54385",
  },
  {
    id: 800,
    country_name: "CO-N. -LA 398",
    country_code: "CO-N. -LA 398",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "La Playa",
    city_code: "398",
    code: "54398",
  },
  {
    id: 801,
    country_name: "CO-N. -LOS405",
    country_code: "CO-N. -LOS405",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Los Patios",
    city_code: "405",
    code: "54405",
  },
  {
    id: 802,
    country_name: "CO-N. -LOU418",
    country_code: "CO-N. -LOU418",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Lourdes",
    city_code: "418",
    code: "54418",
  },
  {
    id: 803,
    country_name: "CO-N. -MUT480",
    country_code: "CO-N. -MUT480",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Mutiscua",
    city_code: "480",
    code: "54480",
  },
  {
    id: 804,
    country_name: "CO-N. -OCA498",
    country_code: "CO-N. -OCA498",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Ocaña",
    city_code: "498",
    code: "54498",
  },
  {
    id: 805,
    country_name: "CO-N. -PAM518",
    country_code: "CO-N. -PAM518",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Pamplona",
    city_code: "518",
    code: "54518",
  },
  {
    id: 806,
    country_name: "CO-N. -PAM520",
    country_code: "CO-N. -PAM520",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Pamplonita",
    city_code: "520",
    code: "54520",
  },
  {
    id: 807,
    country_name: "CO-N. -PUE553",
    country_code: "CO-N. -PUE553",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Puerto Santander",
    city_code: "553",
    code: "54553",
  },
  {
    id: 808,
    country_name: "CO-N. -RAG599",
    country_code: "CO-N. -RAG599",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Ragonvalia",
    city_code: "599",
    code: "54599",
  },
  {
    id: 809,
    country_name: "CO-N. -SAL660",
    country_code: "CO-N. -SAL660",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Salazar",
    city_code: "660",
    code: "54660",
  },
  {
    id: 810,
    country_name: "CO-N. -SAN670",
    country_code: "CO-N. -SAN670",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "San Calixto",
    city_code: "670",
    code: "54670",
  },
  {
    id: 811,
    country_name: "CO-N. -SAN673",
    country_code: "CO-N. -SAN673",
    state_name: "Norte de Santander",
    state_code: "54",
    city_name: "San Cayetano",
    city_code: "673",
    code: "54673",
  },
  {
    id: 812,
    country_name: "CO-N. -SAN680",
    country_code: "CO-N. -SAN680",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Santiago",
    city_code: "680",
    code: "54680",
  },
  {
    id: 813,
    country_name: "CO-N. -SAR720",
    country_code: "CO-N. -SAR720",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Sardinata",
    city_code: "720",
    code: "54720",
  },
  {
    id: 814,
    country_name: "CO-N. -SIL743",
    country_code: "CO-N. -SIL743",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Silos",
    city_code: "743",
    code: "54743",
  },
  {
    id: 815,
    country_name: "CO-N. -TEO800",
    country_code: "CO-N. -TEO800",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Teorama",
    city_code: "800",
    code: "54800",
  },
  {
    id: 816,
    country_name: "CO-N. -TIB810",
    country_code: "CO-N. -TIB810",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Tibú",
    city_code: "810",
    code: "54810",
  },
  {
    id: 817,
    country_name: "CO-N. -TOL820",
    country_code: "CO-N. -TOL820",
    state_name: "Norte de Santander",
    state_code: "54",
    city_name: "Toledo",
    city_code: "820",
    code: "54820",
  },
  {
    id: 818,
    country_name: "CO-N. -VIL871",
    country_code: "CO-N. -VIL871",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Villa Caro",
    city_code: "871",
    code: "54871",
  },
  {
    id: 819,
    country_name: "CO-N. -VIL874",
    country_code: "CO-N. -VIL874",
    state_name: "Norte De Santander",
    state_code: "54",
    city_name: "Villa Del Rosario",
    city_code: "874",
    code: "54874",
  },
  {
    id: 820,
    country_name: "CO-QUI-ARM1",
    country_code: "CO-QUI-ARM1",
    state_name: "Quindío",
    state_code: "63",
    city_name: "Armenia",
    city_code: "1",
    code: "63001",
  },
  {
    id: 821,
    country_name: "CO-QUI-BUE111",
    country_code: "CO-QUI-BUE111",
    state_name: "Quindío",
    state_code: "63",
    city_name: "Buenavista",
    city_code: "111",
    code: "63111",
  },
  {
    id: 822,
    country_name: "CO-QUI-CAL130",
    country_code: "CO-QUI-CAL130",
    state_name: "Quindio",
    state_code: "63",
    city_name: "Calarcá",
    city_code: "130",
    code: "63130",
  },
  {
    id: 823,
    country_name: "CO-QUI-CIR190",
    country_code: "CO-QUI-CIR190",
    state_name: "Quindio",
    state_code: "63",
    city_name: "Circasia",
    city_code: "190",
    code: "63190",
  },
  {
    id: 824,
    country_name: "CO-QUI-COR212",
    country_code: "CO-QUI-COR212",
    state_name: "Quindío",
    state_code: "63",
    city_name: "Córdoba",
    city_code: "212",
    code: "63212",
  },
  {
    id: 825,
    country_name: "CO-QUI-FIL272",
    country_code: "CO-QUI-FIL272",
    state_name: "Quindio",
    state_code: "63",
    city_name: "Filandia",
    city_code: "272",
    code: "63272",
  },
  {
    id: 826,
    country_name: "CO-QUI-GEN302",
    country_code: "CO-QUI-GEN302",
    state_name: "Quindio",
    state_code: "63",
    city_name: "Génova",
    city_code: "302",
    code: "63302",
  },
  {
    id: 827,
    country_name: "CO-QUI-LA 401",
    country_code: "CO-QUI-LA 401",
    state_name: "Quindio",
    state_code: "63",
    city_name: "La Tebaida",
    city_code: "401",
    code: "63401",
  },
  {
    id: 828,
    country_name: "CO-QUI-MON470",
    country_code: "CO-QUI-MON470",
    state_name: "Quindio",
    state_code: "63",
    city_name: "Montenegro",
    city_code: "470",
    code: "63470",
  },
  {
    id: 829,
    country_name: "CO-QUI-PIJ548",
    country_code: "CO-QUI-PIJ548",
    state_name: "Quindio",
    state_code: "63",
    city_name: "Pijao",
    city_code: "548",
    code: "63548",
  },
  {
    id: 830,
    country_name: "CO-QUI-QUI594",
    country_code: "CO-QUI-QUI594",
    state_name: "Quindio",
    state_code: "63",
    city_name: "Quimbaya",
    city_code: "594",
    code: "63594",
  },
  {
    id: 831,
    country_name: "CO-QUI-SAL690",
    country_code: "CO-QUI-SAL690",
    state_name: "Quindio",
    state_code: "63",
    city_name: "Salento",
    city_code: "690",
    code: "63690",
  },
  {
    id: 832,
    country_name: "CO-RIS-PER1",
    country_code: "CO-RIS-PER1",
    state_name: "Risaralda",
    state_code: "66",
    city_name: "Pereira",
    city_code: "1",
    code: "66001",
  },
  {
    id: 833,
    country_name: "CO-RIS-API45",
    country_code: "CO-RIS-API45",
    state_name: "Risaralda",
    state_code: "66",
    city_name: "Apía",
    city_code: "45",
    code: "66045",
  },
  {
    id: 834,
    country_name: "CO-RIS-BAL75",
    country_code: "CO-RIS-BAL75",
    state_name: "Risaralda",
    state_code: "66",
    city_name: "Balboa",
    city_code: "75",
    code: "66075",
  },
  {
    id: 835,
    country_name: "CO-RIS-BEL88",
    country_code: "CO-RIS-BEL88",
    state_name: "Risaralda",
    state_code: "66",
    city_name: "Belén de Umbría",
    city_code: "88",
    code: "66088",
  },
  {
    id: 836,
    country_name: "CO-RIS-DOS170",
    country_code: "CO-RIS-DOS170",
    state_name: "Risaralda",
    state_code: "66",
    city_name: "Dosquebradas",
    city_code: "170",
    code: "66170",
  },
  {
    id: 837,
    country_name: "CO-RIS-GUA318",
    country_code: "CO-RIS-GUA318",
    state_name: "Risaralda",
    state_code: "66",
    city_name: "Guática",
    city_code: "318",
    code: "66318",
  },
  {
    id: 838,
    country_name: "CO-RIS-LA 383",
    country_code: "CO-RIS-LA 383",
    state_name: "Risaralda",
    state_code: "66",
    city_name: "La Celia",
    city_code: "383",
    code: "66383",
  },
  {
    id: 839,
    country_name: "CO-RIS-LA 400",
    country_code: "CO-RIS-LA 400",
    state_name: "Risaralda",
    state_code: "66",
    city_name: "La Virginia",
    city_code: "400",
    code: "66400",
  },
  {
    id: 840,
    country_name: "CO-RIS-MAR440",
    country_code: "CO-RIS-MAR440",
    state_name: "Risaralda",
    state_code: "66",
    city_name: "Marsella",
    city_code: "440",
    code: "66440",
  },
  {
    id: 841,
    country_name: "CO-RIS-MIS456",
    country_code: "CO-RIS-MIS456",
    state_name: "Risaralda",
    state_code: "66",
    city_name: "Mistrató",
    city_code: "456",
    code: "66456",
  },
  {
    id: 842,
    country_name: "CO-RIS-PUE572",
    country_code: "CO-RIS-PUE572",
    state_name: "Risaralda",
    state_code: "66",
    city_name: "Pueblo Rico",
    city_code: "572",
    code: "66572",
  },
  {
    id: 843,
    country_name: "CO-RIS-QUI594",
    country_code: "CO-RIS-QUI594",
    state_name: "Risaralda",
    state_code: "66",
    city_name: "Quinchía",
    city_code: "594",
    code: "66594",
  },
  {
    id: 844,
    country_name: "CO-RIS-SAN682",
    country_code: "CO-RIS-SAN682",
    state_name: "Risaralda",
    state_code: "66",
    city_name: "Santa Rosa De Cabal",
    city_code: "682",
    code: "66682",
  },
  {
    id: 845,
    country_name: "CO-RIS-SAN687",
    country_code: "CO-RIS-SAN687",
    state_name: "Risaralda",
    state_code: "66",
    city_name: "Santuario",
    city_code: "687",
    code: "66687",
  },
  {
    id: 846,
    country_name: "CO-SAN-BUC1",
    country_code: "CO-SAN-BUC1",
    state_name: "Santander",
    state_code: "68",
    city_name: "Bucaramanga",
    city_code: "1",
    code: "68001",
  },
  {
    id: 847,
    country_name: "CO-SAN-AGU13",
    country_code: "CO-SAN-AGU13",
    state_name: "Santander",
    state_code: "68",
    city_name: "Aguada",
    city_code: "13",
    code: "68013",
  },
  {
    id: 848,
    country_name: "CO-SAN-ALB20",
    country_code: "CO-SAN-ALB20",
    state_name: "Santander",
    state_code: "68",
    city_name: "Albania",
    city_code: "20",
    code: "68020",
  },
  {
    id: 849,
    country_name: "CO-SAN-ARA51",
    country_code: "CO-SAN-ARA51",
    state_name: "Santander",
    state_code: "68",
    city_name: "Aratoca",
    city_code: "51",
    code: "68051",
  },
  {
    id: 850,
    country_name: "CO-SAN-BAR77",
    country_code: "CO-SAN-BAR77",
    state_name: "Santander",
    state_code: "68",
    city_name: "Barbosa",
    city_code: "77",
    code: "68077",
  },
  {
    id: 851,
    country_name: "CO-SAN-BAR79",
    country_code: "CO-SAN-BAR79",
    state_name: "Santander",
    state_code: "68",
    city_name: "Barichara",
    city_code: "79",
    code: "68079",
  },
  {
    id: 852,
    country_name: "CO-SAN-BAR81",
    country_code: "CO-SAN-BAR81",
    state_name: "Santander",
    state_code: "68",
    city_name: "Barrancabermeja",
    city_code: "81",
    code: "68081",
  },
  {
    id: 853,
    country_name: "CO-SAN-BET92",
    country_code: "CO-SAN-BET92",
    state_name: "Santander",
    state_code: "68",
    city_name: "Betulia",
    city_code: "92",
    code: "68092",
  },
  {
    id: 854,
    country_name: "CO-SAN-BOL101",
    country_code: "CO-SAN-BOL101",
    state_name: "Santander",
    state_code: "68",
    city_name: "Bolívar",
    city_code: "101",
    code: "68101",
  },
  {
    id: 855,
    country_name: "CO-SAN-CAB121",
    country_code: "CO-SAN-CAB121",
    state_name: "Santander",
    state_code: "68",
    city_name: "Cabrera",
    city_code: "121",
    code: "68121",
  },
  {
    id: 856,
    country_name: "CO-SAN-CAL132",
    country_code: "CO-SAN-CAL132",
    state_name: "Santander",
    state_code: "68",
    city_name: "California",
    city_code: "132",
    code: "68132",
  },
  {
    id: 857,
    country_name: "CO-SAN-CAP147",
    country_code: "CO-SAN-CAP147",
    state_name: "Santander",
    state_code: "68",
    city_name: "Capitanejo",
    city_code: "147",
    code: "68147",
  },
  {
    id: 858,
    country_name: "CO-SAN-CAR152",
    country_code: "CO-SAN-CAR152",
    state_name: "Santander",
    state_code: "68",
    city_name: "Carcasí",
    city_code: "152",
    code: "68152",
  },
  {
    id: 859,
    country_name: "CO-SAN-CEP160",
    country_code: "CO-SAN-CEP160",
    state_name: "Santander",
    state_code: "68",
    city_name: "Cepitá",
    city_code: "160",
    code: "68160",
  },
  {
    id: 860,
    country_name: "CO-SAN-CER162",
    country_code: "CO-SAN-CER162",
    state_name: "Santander",
    state_code: "68",
    city_name: "Cerrito",
    city_code: "162",
    code: "68162",
  },
  {
    id: 861,
    country_name: "CO-SAN-CHA167",
    country_code: "CO-SAN-CHA167",
    state_name: "Santander",
    state_code: "68",
    city_name: "Charalá",
    city_code: "167",
    code: "68167",
  },
  {
    id: 862,
    country_name: "CO-SAN-CHA169",
    country_code: "CO-SAN-CHA169",
    state_name: "Santander",
    state_code: "68",
    city_name: "Charta",
    city_code: "169",
    code: "68169",
  },
  {
    id: 863,
    country_name: "CO-SAN-CHI176",
    country_code: "CO-SAN-CHI176",
    state_name: "Santander",
    state_code: "68",
    city_name: "Chima",
    city_code: "176",
    code: "68176",
  },
  {
    id: 864,
    country_name: "CO-SAN-CHI179",
    country_code: "CO-SAN-CHI179",
    state_name: "Santander",
    state_code: "68",
    city_name: "Chipatá",
    city_code: "179",
    code: "68179",
  },
  {
    id: 865,
    country_name: "CO-SAN-CIM190",
    country_code: "CO-SAN-CIM190",
    state_name: "Santander",
    state_code: "68",
    city_name: "Cimitarra",
    city_code: "190",
    code: "68190",
  },
  {
    id: 866,
    country_name: "CO-SAN-CON207",
    country_code: "CO-SAN-CON207",
    state_name: "Santander",
    state_code: "68",
    city_name: "Concepción",
    city_code: "207",
    code: "68207",
  },
  {
    id: 867,
    country_name: "CO-SAN-CON209",
    country_code: "CO-SAN-CON209",
    state_name: "Santander",
    state_code: "68",
    city_name: "Confines",
    city_code: "209",
    code: "68209",
  },
  {
    id: 868,
    country_name: "CO-SAN-CON211",
    country_code: "CO-SAN-CON211",
    state_name: "Santander",
    state_code: "68",
    city_name: "Contratación",
    city_code: "211",
    code: "68211",
  },
  {
    id: 869,
    country_name: "CO-SAN-COR217",
    country_code: "CO-SAN-COR217",
    state_name: "Santander",
    state_code: "68",
    city_name: "Coromoro",
    city_code: "217",
    code: "68217",
  },
  {
    id: 870,
    country_name: "CO-SAN-CUR229",
    country_code: "CO-SAN-CUR229",
    state_name: "Santander",
    state_code: "68",
    city_name: "Curití",
    city_code: "229",
    code: "68229",
  },
  {
    id: 871,
    country_name: "CO-SAN-EL 235",
    country_code: "CO-SAN-EL 235",
    state_name: "Santander",
    state_code: "68",
    city_name: "El Carmen De Chucurí",
    city_code: "235",
    code: "68235",
  },
  {
    id: 872,
    country_name: "CO-SAN-EL 245",
    country_code: "CO-SAN-EL 245",
    state_name: "Santander",
    state_code: "68",
    city_name: "El Guacamayo",
    city_code: "245",
    code: "68245",
  },
  {
    id: 873,
    country_name: "CO-SAN-EL 250",
    country_code: "CO-SAN-EL 250",
    state_name: "Santander",
    state_code: "68",
    city_name: "El Peñón",
    city_code: "250",
    code: "68250",
  },
  {
    id: 874,
    country_name: "CO-SAN-EL 255",
    country_code: "CO-SAN-EL 255",
    state_name: "Santander",
    state_code: "68",
    city_name: "El Playón",
    city_code: "255",
    code: "68255",
  },
  {
    id: 875,
    country_name: "CO-SAN-ENC264",
    country_code: "CO-SAN-ENC264",
    state_name: "Santander",
    state_code: "68",
    city_name: "Encino",
    city_code: "264",
    code: "68264",
  },
  {
    id: 876,
    country_name: "CO-SAN-ENC266",
    country_code: "CO-SAN-ENC266",
    state_name: "Santander",
    state_code: "68",
    city_name: "Enciso",
    city_code: "266",
    code: "68266",
  },
  {
    id: 877,
    country_name: "CO-SAN-FLO271",
    country_code: "CO-SAN-FLO271",
    state_name: "Santander",
    state_code: "68",
    city_name: "Florián",
    city_code: "271",
    code: "68271",
  },
  {
    id: 878,
    country_name: "CO-SAN-FLO276",
    country_code: "CO-SAN-FLO276",
    state_name: "Santander",
    state_code: "68",
    city_name: "Floridablanca",
    city_code: "276",
    code: "68276",
  },
  {
    id: 879,
    country_name: "CO-SAN-GAL296",
    country_code: "CO-SAN-GAL296",
    state_name: "Santander",
    state_code: "68",
    city_name: "Galán",
    city_code: "296",
    code: "68296",
  },
  {
    id: 880,
    country_name: "CO-SAN-GAM298",
    country_code: "CO-SAN-GAM298",
    state_name: "Santander",
    state_code: "68",
    city_name: "Gámbita",
    city_code: "298",
    code: "68298",
  },
  {
    id: 881,
    country_name: "CO-SAN-GIR307",
    country_code: "CO-SAN-GIR307",
    state_name: "Santander",
    state_code: "68",
    city_name: "Girón",
    city_code: "307",
    code: "68307",
  },
  {
    id: 882,
    country_name: "CO-SAN-GUA318",
    country_code: "CO-SAN-GUA318",
    state_name: "Santander",
    state_code: "68",
    city_name: "Guaca",
    city_code: "318",
    code: "68318",
  },
  {
    id: 883,
    country_name: "CO-SAN-GUA320",
    country_code: "CO-SAN-GUA320",
    state_name: "Santander",
    state_code: "68",
    city_name: "Guadalupe",
    city_code: "320",
    code: "68320",
  },
  {
    id: 884,
    country_name: "CO-SAN-GUA322",
    country_code: "CO-SAN-GUA322",
    state_name: "Santander",
    state_code: "68",
    city_name: "Guapotá",
    city_code: "322",
    code: "68322",
  },
  {
    id: 885,
    country_name: "CO-SAN-GUA324",
    country_code: "CO-SAN-GUA324",
    state_name: "Santander",
    state_code: "68",
    city_name: "Guavatá",
    city_code: "324",
    code: "68324",
  },
  {
    id: 886,
    country_name: "CO-SAN-GSE327",
    country_code: "CO-SAN-GSE327",
    state_name: "Santander",
    state_code: "68",
    city_name: "Güepsa",
    city_code: "327",
    code: "68327",
  },
  {
    id: 887,
    country_name: "CO-SAN-HAT344",
    country_code: "CO-SAN-HAT344",
    state_name: "Santander",
    state_code: "68",
    city_name: "Hato",
    city_code: "344",
    code: "68344",
  },
  {
    id: 888,
    country_name: "CO-SAN-JES368",
    country_code: "CO-SAN-JES368",
    state_name: "Santander",
    state_code: "68",
    city_name: "Jesús María",
    city_code: "368",
    code: "68368",
  },
  {
    id: 889,
    country_name: "CO-SAN-JOR370",
    country_code: "CO-SAN-JOR370",
    state_name: "Santander",
    state_code: "68",
    city_name: "Jordán",
    city_code: "370",
    code: "68370",
  },
  {
    id: 890,
    country_name: "CO-SAN-LA 377",
    country_code: "CO-SAN-LA 377",
    state_name: "Santander",
    state_code: "68",
    city_name: "La Belleza",
    city_code: "377",
    code: "68377",
  },
  {
    id: 891,
    country_name: "CO-SAN-LAN385",
    country_code: "CO-SAN-LAN385",
    state_name: "Santander",
    state_code: "68",
    city_name: "Landázuri",
    city_code: "385",
    code: "68385",
  },
  {
    id: 892,
    country_name: "CO-SAN-LA 397",
    country_code: "CO-SAN-LA 397",
    state_name: "Santander",
    state_code: "68",
    city_name: "La Paz",
    city_code: "397",
    code: "68397",
  },
  {
    id: 893,
    country_name: "CO-SAN-LEB406",
    country_code: "CO-SAN-LEB406",
    state_name: "Santander",
    state_code: "68",
    city_name: "Lebrija",
    city_code: "406",
    code: "68406",
  },
  {
    id: 894,
    country_name: "CO-SAN-LOS418",
    country_code: "CO-SAN-LOS418",
    state_name: "Santander",
    state_code: "68",
    city_name: "Los Santos",
    city_code: "418",
    code: "68418",
  },
  {
    id: 895,
    country_name: "CO-SAN-MAC425",
    country_code: "CO-SAN-MAC425",
    state_name: "Santander",
    state_code: "68",
    city_name: "Macaravita",
    city_code: "425",
    code: "68425",
  },
  {
    id: 896,
    country_name: "CO-SAN-MAL432",
    country_code: "CO-SAN-MAL432",
    state_name: "Santander",
    state_code: "68",
    city_name: "Málaga",
    city_code: "432",
    code: "68432",
  },
  {
    id: 897,
    country_name: "CO-SAN-MAT444",
    country_code: "CO-SAN-MAT444",
    state_name: "Santander",
    state_code: "68",
    city_name: "Matanza",
    city_code: "444",
    code: "68444",
  },
  {
    id: 898,
    country_name: "CO-SAN-MOG464",
    country_code: "CO-SAN-MOG464",
    state_name: "Santander",
    state_code: "68",
    city_name: "Mogotes",
    city_code: "464",
    code: "68464",
  },
  {
    id: 899,
    country_name: "CO-SAN-MOL468",
    country_code: "CO-SAN-MOL468",
    state_name: "Santander",
    state_code: "68",
    city_name: "Molagavita",
    city_code: "468",
    code: "68468",
  },
  {
    id: 900,
    country_name: "CO-SAN-OCA498",
    country_code: "CO-SAN-OCA498",
    state_name: "Santander",
    state_code: "68",
    city_name: "Ocamonte",
    city_code: "498",
    code: "68498",
  },
  {
    id: 901,
    country_name: "CO-SAN-OIB500",
    country_code: "CO-SAN-OIB500",
    state_name: "Santander",
    state_code: "68",
    city_name: "Oiba",
    city_code: "500",
    code: "68500",
  },
  {
    id: 902,
    country_name: "CO-SAN-ONZ502",
    country_code: "CO-SAN-ONZ502",
    state_name: "Santander",
    state_code: "68",
    city_name: "Onzaga",
    city_code: "502",
    code: "68502",
  },
  {
    id: 903,
    country_name: "CO-SAN-PAL522",
    country_code: "CO-SAN-PAL522",
    state_name: "Santander",
    state_code: "68",
    city_name: "Palmar",
    city_code: "522",
    code: "68522",
  },
  {
    id: 904,
    country_name: "CO-SAN-PAL524",
    country_code: "CO-SAN-PAL524",
    state_name: "Santander",
    state_code: "68",
    city_name: "Palmas Del Socorro",
    city_code: "524",
    code: "68524",
  },
  {
    id: 905,
    country_name: "CO-SAN-PAR533",
    country_code: "CO-SAN-PAR533",
    state_name: "Santander",
    state_code: "68",
    city_name: "Páramo",
    city_code: "533",
    code: "68533",
  },
  {
    id: 906,
    country_name: "CO-SAN-PIE547",
    country_code: "CO-SAN-PIE547",
    state_name: "Santander",
    state_code: "68",
    city_name: "Piedecuesta",
    city_code: "547",
    code: "68547",
  },
  {
    id: 907,
    country_name: "CO-SAN-PIN549",
    country_code: "CO-SAN-PIN549",
    state_name: "Santander",
    state_code: "68",
    city_name: "Pinchote",
    city_code: "549",
    code: "68549",
  },
  {
    id: 908,
    country_name: "CO-SAN-PUE572",
    country_code: "CO-SAN-PUE572",
    state_name: "Santander",
    state_code: "68",
    city_name: "Puente Nacional",
    city_code: "572",
    code: "68572",
  },
  {
    id: 909,
    country_name: "CO-SAN-PUE573",
    country_code: "CO-SAN-PUE573",
    state_name: "Santander",
    state_code: "68",
    city_name: "Puerto Parra",
    city_code: "573",
    code: "68573",
  },
  {
    id: 910,
    country_name: "CO-SAN-PUE575",
    country_code: "CO-SAN-PUE575",
    state_name: "Santander",
    state_code: "68",
    city_name: "Puerto Wilches",
    city_code: "575",
    code: "68575",
  },
  {
    id: 911,
    country_name: "CO-SAN-RIO615",
    country_code: "CO-SAN-RIO615",
    state_name: "Santander",
    state_code: "68",
    city_name: "Rionegro",
    city_code: "615",
    code: "68615",
  },
  {
    id: 912,
    country_name: "CO-SAN-SAB655",
    country_code: "CO-SAN-SAB655",
    state_name: "Santander",
    state_code: "68",
    city_name: "Sabana De Torres",
    city_code: "655",
    code: "68655",
  },
  {
    id: 913,
    country_name: "CO-SAN-SAN669",
    country_code: "CO-SAN-SAN669",
    state_name: "Santander",
    state_code: "68",
    city_name: "San Andrés",
    city_code: "669",
    code: "68669",
  },
  {
    id: 914,
    country_name: "CO-SAN-SAN673",
    country_code: "CO-SAN-SAN673",
    state_name: "Santander",
    state_code: "68",
    city_name: "San Benito",
    city_code: "673",
    code: "68673",
  },
  {
    id: 915,
    country_name: "CO-SAN-SAN679",
    country_code: "CO-SAN-SAN679",
    state_name: "Santander",
    state_code: "68",
    city_name: "San Gil",
    city_code: "679",
    code: "68679",
  },
  {
    id: 916,
    country_name: "CO-SAN-SAN682",
    country_code: "CO-SAN-SAN682",
    state_name: "Santander",
    state_code: "68",
    city_name: "San Joaquín",
    city_code: "682",
    code: "68682",
  },
  {
    id: 917,
    country_name: "CO-SAN-SAN684",
    country_code: "CO-SAN-SAN684",
    state_name: "Santander",
    state_code: "68",
    city_name: "San José De Miranda",
    city_code: "684",
    code: "68684",
  },
  {
    id: 918,
    country_name: "CO-SAN-SAN686",
    country_code: "CO-SAN-SAN686",
    state_name: "Santander",
    state_code: "68",
    city_name: "San Miguel",
    city_code: "686",
    code: "68686",
  },
  {
    id: 919,
    country_name: "CO-SAN-SAN689",
    country_code: "CO-SAN-SAN689",
    state_name: "Santander",
    state_code: "68",
    city_name: "San Vicente De Chucurí",
    city_code: "689",
    code: "68689",
  },
  {
    id: 920,
    country_name: "CO-SAN-SAN705",
    country_code: "CO-SAN-SAN705",
    state_name: "Santander",
    state_code: "68",
    city_name: "Santa Bárbara",
    city_code: "705",
    code: "68705",
  },
  {
    id: 921,
    country_name: "CO-SAN-SAN720",
    country_code: "CO-SAN-SAN720",
    state_name: "Santander",
    state_code: "68",
    city_name: "Santa Helena Del Opón",
    city_code: "720",
    code: "68720",
  },
  {
    id: 922,
    country_name: "CO-SAN-SIM745",
    country_code: "CO-SAN-SIM745",
    state_name: "Santander",
    state_code: "68",
    city_name: "Simacota",
    city_code: "745",
    code: "68745",
  },
  {
    id: 923,
    country_name: "CO-SAN-SOC755",
    country_code: "CO-SAN-SOC755",
    state_name: "Santander",
    state_code: "68",
    city_name: "Socorro",
    city_code: "755",
    code: "68755",
  },
  {
    id: 924,
    country_name: "CO-SAN-SUA770",
    country_code: "CO-SAN-SUA770",
    state_name: "Santander",
    state_code: "68",
    city_name: "Suaita",
    city_code: "770",
    code: "68770",
  },
  {
    id: 925,
    country_name: "CO-SAN-SUC773",
    country_code: "CO-SAN-SUC773",
    state_name: "Santander",
    state_code: "68",
    city_name: "Sucre",
    city_code: "773",
    code: "68773",
  },
  {
    id: 926,
    country_name: "CO-SAN-SUR780",
    country_code: "CO-SAN-SUR780",
    state_name: "Santander",
    state_code: "68",
    city_name: "Suratá",
    city_code: "780",
    code: "68780",
  },
  {
    id: 927,
    country_name: "CO-SAN-TON820",
    country_code: "CO-SAN-TON820",
    state_name: "Santander",
    state_code: "68",
    city_name: "Tona",
    city_code: "820",
    code: "68820",
  },
  {
    id: 928,
    country_name: "CO-SAN-VAL855",
    country_code: "CO-SAN-VAL855",
    state_name: "Santander",
    state_code: "68",
    city_name: "Valle De San José",
    city_code: "855",
    code: "68855",
  },
  {
    id: 929,
    country_name: "CO-SAN-VEL861",
    country_code: "CO-SAN-VEL861",
    state_name: "Santander",
    state_code: "68",
    city_name: "Vélez",
    city_code: "861",
    code: "68861",
  },
  {
    id: 930,
    country_name: "CO-SAN-VET867",
    country_code: "CO-SAN-VET867",
    state_name: "Santander",
    state_code: "68",
    city_name: "Vetas",
    city_code: "867",
    code: "68867",
  },
  {
    id: 931,
    country_name: "CO-SAN-VIL872",
    country_code: "CO-SAN-VIL872",
    state_name: "Santander",
    state_code: "68",
    city_name: "Villanueva",
    city_code: "872",
    code: "68872",
  },
  {
    id: 932,
    country_name: "CO-SAN-ZAP895",
    country_code: "CO-SAN-ZAP895",
    state_name: "Santander",
    state_code: "68",
    city_name: "Zapatoca",
    city_code: "895",
    code: "68895",
  },
  {
    id: 933,
    country_name: "CO-SUC-SIN1",
    country_code: "CO-SUC-SIN1",
    state_name: "Sucre",
    state_code: "70",
    city_name: "Sincelejo",
    city_code: "1",
    code: "70001",
  },
  {
    id: 934,
    country_name: "CO-SUC-BUE110",
    country_code: "CO-SUC-BUE110",
    state_name: "Sucre",
    state_code: "70",
    city_name: "Buenavista",
    city_code: "110",
    code: "70110",
  },
  {
    id: 935,
    country_name: "CO-SUC-CAI124",
    country_code: "CO-SUC-CAI124",
    state_name: "Sucre",
    state_code: "70",
    city_name: "Caimito",
    city_code: "124",
    code: "70124",
  },
  {
    id: 936,
    country_name: "CO-SUC-COL204",
    country_code: "CO-SUC-COL204",
    state_name: "Sucre",
    state_code: "70",
    city_name: "Coloso",
    city_code: "204",
    code: "70204",
  },
  {
    id: 937,
    country_name: "CO-SUC-COR215",
    country_code: "CO-SUC-COR215",
    state_name: "Sucre",
    state_code: "70",
    city_name: "Corozal",
    city_code: "215",
    code: "70215",
  },
  {
    id: 938,
    country_name: "CO-SUC-COV221",
    country_code: "CO-SUC-COV221",
    state_name: "Sucre",
    state_code: "70",
    city_name: "Coveñas",
    city_code: "221",
    code: "70221",
  },
  {
    id: 939,
    country_name: "CO-SUC-CHA230",
    country_code: "CO-SUC-CHA230",
    state_name: "Sucre",
    state_code: "70",
    city_name: "Chalán",
    city_code: "230",
    code: "70230",
  },
  {
    id: 940,
    country_name: "CO-SUC-EL 233",
    country_code: "CO-SUC-EL 233",
    state_name: "Sucre",
    state_code: "70",
    city_name: "El Roble",
    city_code: "233",
    code: "70233",
  },
  {
    id: 941,
    country_name: "CO-SUC-GAL235",
    country_code: "CO-SUC-GAL235",
    state_name: "Sucre",
    state_code: "70",
    city_name: "Galeras",
    city_code: "235",
    code: "70235",
  },
  {
    id: 942,
    country_name: "CO-SUC-GUA265",
    country_code: "CO-SUC-GUA265",
    state_name: "Sucre",
    state_code: "70",
    city_name: "Guaranda",
    city_code: "265",
    code: "70265",
  },
  {
    id: 943,
    country_name: "CO-SUC-LA 400",
    country_code: "CO-SUC-LA 400",
    state_name: "Sucre",
    state_code: "70",
    city_name: "La Unión",
    city_code: "400",
    code: "70400",
  },
  {
    id: 944,
    country_name: "CO-SUC-LOS418",
    country_code: "CO-SUC-LOS418",
    state_name: "Sucre",
    state_code: "70",
    city_name: "Los Palmitos",
    city_code: "418",
    code: "70418",
  },
  {
    id: 945,
    country_name: "CO-SUC-MAJ429",
    country_code: "CO-SUC-MAJ429",
    state_name: "Sucre",
    state_code: "70",
    city_name: "Majagual",
    city_code: "429",
    code: "70429",
  },
  {
    id: 946,
    country_name: "CO-SUC-MOR473",
    country_code: "CO-SUC-MOR473",
    state_name: "Sucre",
    state_code: "70",
    city_name: "Morroa",
    city_code: "473",
    code: "70473",
  },
  {
    id: 947,
    country_name: "CO-SUC-OVE508",
    country_code: "CO-SUC-OVE508",
    state_name: "Sucre",
    state_code: "70",
    city_name: "Ovejas",
    city_code: "508",
    code: "70508",
  },
  {
    id: 948,
    country_name: "CO-SUC-PAL523",
    country_code: "CO-SUC-PAL523",
    state_name: "Sucre",
    state_code: "70",
    city_name: "Palmito",
    city_code: "523",
    code: "70523",
  },
  {
    id: 949,
    country_name: "CO-SUC-SAM670",
    country_code: "CO-SUC-SAM670",
    state_name: "Sucre",
    state_code: "70",
    city_name: "Sampués",
    city_code: "670",
    code: "70670",
  },
  {
    id: 950,
    country_name: "CO-SUC-SAN678",
    country_code: "CO-SUC-SAN678",
    state_name: "Sucre",
    state_code: "70",
    city_name: "San Benito Abad",
    city_code: "678",
    code: "70678",
  },
  {
    id: 951,
    country_name: "CO-SUC-SAN702",
    country_code: "CO-SUC-SAN702",
    state_name: "Sucre",
    state_code: "70",
    city_name: "San Juan De Betulia",
    city_code: "702",
    code: "70702",
  },
  {
    id: 952,
    country_name: "CO-SUC-SAN708",
    country_code: "CO-SUC-SAN708",
    state_name: "Sucre",
    state_code: "70",
    city_name: "San Marcos",
    city_code: "708",
    code: "70708",
  },
  {
    id: 953,
    country_name: "CO-SUC-SAN713",
    country_code: "CO-SUC-SAN713",
    state_name: "Sucre",
    state_code: "70",
    city_name: "San Onofre",
    city_code: "713",
    code: "70713",
  },
  {
    id: 954,
    country_name: "CO-SUC-SAN717",
    country_code: "CO-SUC-SAN717",
    state_name: "Sucre",
    state_code: "70",
    city_name: "San Pedro",
    city_code: "717",
    code: "70717",
  },
  {
    id: 955,
    country_name: "CO-SUC-SAN742",
    country_code: "CO-SUC-SAN742",
    state_name: "Sucre",
    state_code: "70",
    city_name: "San Luis De Sincé",
    city_code: "742",
    code: "70742",
  },
  {
    id: 956,
    country_name: "CO-SUC-SUC771",
    country_code: "CO-SUC-SUC771",
    state_name: "Sucre",
    state_code: "70",
    city_name: "Sucre",
    city_code: "771",
    code: "70771",
  },
  {
    id: 957,
    country_name: "CO-SUC-SAN820",
    country_code: "CO-SUC-SAN820",
    state_name: "Sucre",
    state_code: "70",
    city_name: "Santiago De Tolú",
    city_code: "820",
    code: "70820",
  },
  {
    id: 958,
    country_name: "CO-SUC-TOL823",
    country_code: "CO-SUC-TOL823",
    state_name: "Sucre",
    state_code: "70",
    city_name: "Tolú Viejo",
    city_code: "823",
    code: "70823",
  },
  {
    id: 959,
    country_name: "CO-TOL-IBA1",
    country_code: "CO-TOL-IBA1",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Ibagué",
    city_code: "1",
    code: "73001",
  },
  {
    id: 960,
    country_name: "CO-TOL-ALP24",
    country_code: "CO-TOL-ALP24",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Alpujarra",
    city_code: "24",
    code: "73024",
  },
  {
    id: 961,
    country_name: "CO-TOL-ALV26",
    country_code: "CO-TOL-ALV26",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Alvarado",
    city_code: "26",
    code: "73026",
  },
  {
    id: 962,
    country_name: "CO-TOL-AMB30",
    country_code: "CO-TOL-AMB30",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Ambalema",
    city_code: "30",
    code: "73030",
  },
  {
    id: 963,
    country_name: "CO-TOL-ANZ43",
    country_code: "CO-TOL-ANZ43",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Anzoátegui",
    city_code: "43",
    code: "73043",
  },
  {
    id: 964,
    country_name: "CO-TOL-ARM55",
    country_code: "CO-TOL-ARM55",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Armero",
    city_code: "55",
    code: "73055",
  },
  {
    id: 965,
    country_name: "CO-TOL-ATA67",
    country_code: "CO-TOL-ATA67",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Ataco",
    city_code: "67",
    code: "73067",
  },
  {
    id: 966,
    country_name: "CO-TOL-CAJ124",
    country_code: "CO-TOL-CAJ124",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Cajamarca",
    city_code: "124",
    code: "73124",
  },
  {
    id: 967,
    country_name: "CO-TOL-CAR148",
    country_code: "CO-TOL-CAR148",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Carmen De Apicalá",
    city_code: "148",
    code: "73148",
  },
  {
    id: 968,
    country_name: "CO-TOL-CAS152",
    country_code: "CO-TOL-CAS152",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Casabianca",
    city_code: "152",
    code: "73152",
  },
  {
    id: 969,
    country_name: "CO-TOL-CHA168",
    country_code: "CO-TOL-CHA168",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Chaparral",
    city_code: "168",
    code: "73168",
  },
  {
    id: 970,
    country_name: "CO-TOL-COE200",
    country_code: "CO-TOL-COE200",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Coello",
    city_code: "200",
    code: "73200",
  },
  {
    id: 971,
    country_name: "CO-TOL-COY217",
    country_code: "CO-TOL-COY217",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Coyaima",
    city_code: "217",
    code: "73217",
  },
  {
    id: 972,
    country_name: "CO-TOL-CUN226",
    country_code: "CO-TOL-CUN226",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Cunday",
    city_code: "226",
    code: "73226",
  },
  {
    id: 973,
    country_name: "CO-TOL-DOL236",
    country_code: "CO-TOL-DOL236",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Dolores",
    city_code: "236",
    code: "73236",
  },
  {
    id: 974,
    country_name: "CO-TOL-ESP268",
    country_code: "CO-TOL-ESP268",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Espinal",
    city_code: "268",
    code: "73268",
  },
  {
    id: 975,
    country_name: "CO-TOL-FAL270",
    country_code: "CO-TOL-FAL270",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Falan",
    city_code: "270",
    code: "73270",
  },
  {
    id: 976,
    country_name: "CO-TOL-FLA275",
    country_code: "CO-TOL-FLA275",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Flandes",
    city_code: "275",
    code: "73275",
  },
  {
    id: 977,
    country_name: "CO-TOL-FRE283",
    country_code: "CO-TOL-FRE283",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Fresno",
    city_code: "283",
    code: "73283",
  },
  {
    id: 978,
    country_name: "CO-TOL-GUA319",
    country_code: "CO-TOL-GUA319",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Guamo",
    city_code: "319",
    code: "73319",
  },
  {
    id: 979,
    country_name: "CO-TOL-HER347",
    country_code: "CO-TOL-HER347",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Herveo",
    city_code: "347",
    code: "73347",
  },
  {
    id: 980,
    country_name: "CO-TOL-HON349",
    country_code: "CO-TOL-HON349",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Honda",
    city_code: "349",
    code: "73349",
  },
  {
    id: 981,
    country_name: "CO-TOL-ICO352",
    country_code: "CO-TOL-ICO352",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Icononzo",
    city_code: "352",
    code: "73352",
  },
  {
    id: 982,
    country_name: "CO-TOL-LER408",
    country_code: "CO-TOL-LER408",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Lérida",
    city_code: "408",
    code: "73408",
  },
  {
    id: 983,
    country_name: "CO-TOL-LIB411",
    country_code: "CO-TOL-LIB411",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Líbano",
    city_code: "411",
    code: "73411",
  },
  {
    id: 984,
    country_name: "CO-TOL-MAR443",
    country_code: "CO-TOL-MAR443",
    state_name: "Tolima",
    state_code: "73",
    city_name: "San Sebastián De Mariquita",
    city_code: "443",
    code: "73443",
  },
  {
    id: 985,
    country_name: "CO-TOL-MEL449",
    country_code: "CO-TOL-MEL449",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Melgar",
    city_code: "449",
    code: "73449",
  },
  {
    id: 986,
    country_name: "CO-TOL-MUR461",
    country_code: "CO-TOL-MUR461",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Murillo",
    city_code: "461",
    code: "73461",
  },
  {
    id: 987,
    country_name: "CO-TOL-NAT483",
    country_code: "CO-TOL-NAT483",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Natagaima",
    city_code: "483",
    code: "73483",
  },
  {
    id: 988,
    country_name: "CO-TOL-ORT504",
    country_code: "CO-TOL-ORT504",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Ortega",
    city_code: "504",
    code: "73504",
  },
  {
    id: 989,
    country_name: "CO-TOL-PAL520",
    country_code: "CO-TOL-PAL520",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Palocabildo",
    city_code: "520",
    code: "73520",
  },
  {
    id: 990,
    country_name: "CO-TOL-PIE547",
    country_code: "CO-TOL-PIE547",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Piedras",
    city_code: "547",
    code: "73547",
  },
  {
    id: 991,
    country_name: "CO-TOL-PLA555",
    country_code: "CO-TOL-PLA555",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Planadas",
    city_code: "555",
    code: "73555",
  },
  {
    id: 992,
    country_name: "CO-TOL-PRA563",
    country_code: "CO-TOL-PRA563",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Prado",
    city_code: "563",
    code: "73563",
  },
  {
    id: 993,
    country_name: "CO-TOL-PUR585",
    country_code: "CO-TOL-PUR585",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Purificación",
    city_code: "585",
    code: "73585",
  },
  {
    id: 994,
    country_name: "CO-TOL-RIO616",
    country_code: "CO-TOL-RIO616",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Rioblanco",
    city_code: "616",
    code: "73616",
  },
  {
    id: 995,
    country_name: "CO-TOL-RON622",
    country_code: "CO-TOL-RON622",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Roncesvalles",
    city_code: "622",
    code: "73622",
  },
  {
    id: 996,
    country_name: "CO-TOL-ROV624",
    country_code: "CO-TOL-ROV624",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Rovira",
    city_code: "624",
    code: "73624",
  },
  {
    id: 997,
    country_name: "CO-TOL-SAL671",
    country_code: "CO-TOL-SAL671",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Saldaña",
    city_code: "671",
    code: "73671",
  },
  {
    id: 998,
    country_name: "CO-TOL-SAN675",
    country_code: "CO-TOL-SAN675",
    state_name: "Tolima",
    state_code: "73",
    city_name: "San Antonio",
    city_code: "675",
    code: "73675",
  },
  {
    id: 999,
    country_name: "CO-TOL-SAN678",
    country_code: "CO-TOL-SAN678",
    state_name: "Tolima",
    state_code: "73",
    city_name: "San Luis",
    city_code: "678",
    code: "73678",
  },
  {
    id: 1000,
    country_name: "CO-TOL-SAN686",
    country_code: "CO-TOL-SAN686",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Santa Isabel",
    city_code: "686",
    code: "73686",
  },
  {
    id: 1001,
    country_name: "CO-TOL-SUA770",
    country_code: "CO-TOL-SUA770",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Suárez",
    city_code: "770",
    code: "73770",
  },
  {
    id: 1002,
    country_name: "CO-TOL-VAL854",
    country_code: "CO-TOL-VAL854",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Valle De San Juan",
    city_code: "854",
    code: "73854",
  },
  {
    id: 1003,
    country_name: "CO-TOL-VEN861",
    country_code: "CO-TOL-VEN861",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Venadillo",
    city_code: "861",
    code: "73861",
  },
  {
    id: 1004,
    country_name: "CO-TOL-VIL870",
    country_code: "CO-TOL-VIL870",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Villahermosa",
    city_code: "870",
    code: "73870",
  },
  {
    id: 1005,
    country_name: "CO-TOL-VIL873",
    country_code: "CO-TOL-VIL873",
    state_name: "Tolima",
    state_code: "73",
    city_name: "Villarrica",
    city_code: "873",
    code: "73873",
  },
  {
    id: 1006,
    country_name: "CO-VAL-CAL1",
    country_code: "CO-VAL-CAL1",
    state_name: "Valle del Cauca",
    state_code: "76",
    city_name: "Cali",
    city_code: "1",
    code: "76001",
  },
  {
    id: 1007,
    country_name: "CO-VAL-ALC20",
    country_code: "CO-VAL-ALC20",
    state_name: "Valle del Cauca",
    state_code: "76",
    city_name: "Alcalá",
    city_code: "20",
    code: "76020",
  },
  {
    id: 1008,
    country_name: "CO-VAL-AND36",
    country_code: "CO-VAL-AND36",
    state_name: "Valle del Cauca",
    state_code: "76",
    city_name: "Andalucía",
    city_code: "36",
    code: "76036",
  },
  {
    id: 1009,
    country_name: "CO-VAL-ANS41",
    country_code: "CO-VAL-ANS41",
    state_name: "Valle del Cauca",
    state_code: "76",
    city_name: "Ansermanuevo",
    city_code: "41",
    code: "76041",
  },
  {
    id: 1010,
    country_name: "CO-VAL-ARG54",
    country_code: "CO-VAL-ARG54",
    state_name: "Valle del Cauca",
    state_code: "76",
    city_name: "Argelia",
    city_code: "54",
    code: "76054",
  },
  {
    id: 1011,
    country_name: "CO-VAL-BOL100",
    country_code: "CO-VAL-BOL100",
    state_name: "Valle del Cauca",
    state_code: "76",
    city_name: "Bolívar",
    city_code: "100",
    code: "76100",
  },
  {
    id: 1012,
    country_name: "CO-VAL-BUE109",
    country_code: "CO-VAL-BUE109",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Buenaventura",
    city_code: "109",
    code: "76109",
  },
  {
    id: 1013,
    country_name: "CO-VAL-GUA111",
    country_code: "CO-VAL-GUA111",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Guadalajara De Buga",
    city_code: "111",
    code: "76111",
  },
  {
    id: 1014,
    country_name: "CO-VAL-BUG113",
    country_code: "CO-VAL-BUG113",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Bugalagrande",
    city_code: "113",
    code: "76113",
  },
  {
    id: 1015,
    country_name: "CO-VAL-CAI122",
    country_code: "CO-VAL-CAI122",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Caicedonia",
    city_code: "122",
    code: "76122",
  },
  {
    id: 1016,
    country_name: "CO-VAL-CAL126",
    country_code: "CO-VAL-CAL126",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Calima",
    city_code: "126",
    code: "76126",
  },
  {
    id: 1017,
    country_name: "CO-VAL-CAN130",
    country_code: "CO-VAL-CAN130",
    state_name: "Valle del Cauca",
    state_code: "76",
    city_name: "Candelaria",
    city_code: "130",
    code: "76130",
  },
  {
    id: 1018,
    country_name: "CO-VAL-CAR147",
    country_code: "CO-VAL-CAR147",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Cartago",
    city_code: "147",
    code: "76147",
  },
  {
    id: 1019,
    country_name: "CO-VAL-DAG233",
    country_code: "CO-VAL-DAG233",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Dagua",
    city_code: "233",
    code: "76233",
  },
  {
    id: 1020,
    country_name: "CO-VAL-EL 243",
    country_code: "CO-VAL-EL 243",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "El Águila",
    city_code: "243",
    code: "76243",
  },
  {
    id: 1021,
    country_name: "CO-VAL-EL 246",
    country_code: "CO-VAL-EL 246",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "El Cairo",
    city_code: "246",
    code: "76246",
  },
  {
    id: 1022,
    country_name: "CO-VAL-EL 248",
    country_code: "CO-VAL-EL 248",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "El Cerrito",
    city_code: "248",
    code: "76248",
  },
  {
    id: 1023,
    country_name: "CO-VAL-EL 250",
    country_code: "CO-VAL-EL 250",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "El Dovio",
    city_code: "250",
    code: "76250",
  },
  {
    id: 1024,
    country_name: "CO-VAL-FLO275",
    country_code: "CO-VAL-FLO275",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Florida",
    city_code: "275",
    code: "76275",
  },
  {
    id: 1025,
    country_name: "CO-VAL-GIN306",
    country_code: "CO-VAL-GIN306",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Ginebra",
    city_code: "306",
    code: "76306",
  },
  {
    id: 1026,
    country_name: "CO-VAL-GUA318",
    country_code: "CO-VAL-GUA318",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Guacarí",
    city_code: "318",
    code: "76318",
  },
  {
    id: 1027,
    country_name: "CO-VAL-JAM364",
    country_code: "CO-VAL-JAM364",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Jamundí",
    city_code: "364",
    code: "76364",
  },
  {
    id: 1028,
    country_name: "CO-VAL-LA 377",
    country_code: "CO-VAL-LA 377",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "La Cumbre",
    city_code: "377",
    code: "76377",
  },
  {
    id: 1029,
    country_name: "CO-VAL-LA 400",
    country_code: "CO-VAL-LA 400",
    state_name: "Valle del Cauca",
    state_code: "76",
    city_name: "La Unión",
    city_code: "400",
    code: "76400",
  },
  {
    id: 1030,
    country_name: "CO-VAL-LA 403",
    country_code: "CO-VAL-LA 403",
    state_name: "Valle del Cauca",
    state_code: "76",
    city_name: "La Victoria",
    city_code: "403",
    code: "76403",
  },
  {
    id: 1031,
    country_name: "CO-VAL-OBA497",
    country_code: "CO-VAL-OBA497",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Obando",
    city_code: "497",
    code: "76497",
  },
  {
    id: 1032,
    country_name: "CO-VAL-PAL520",
    country_code: "CO-VAL-PAL520",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Palmira",
    city_code: "520",
    code: "76520",
  },
  {
    id: 1033,
    country_name: "CO-VAL-PRA563",
    country_code: "CO-VAL-PRA563",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Pradera",
    city_code: "563",
    code: "76563",
  },
  {
    id: 1034,
    country_name: "CO-VAL-RES606",
    country_code: "CO-VAL-RES606",
    state_name: "Valle del Cauca",
    state_code: "76",
    city_name: "Restrepo",
    city_code: "606",
    code: "76606",
  },
  {
    id: 1035,
    country_name: "CO-VAL-RIO616",
    country_code: "CO-VAL-RIO616",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Riofrío",
    city_code: "616",
    code: "76616",
  },
  {
    id: 1036,
    country_name: "CO-VAL-ROL622",
    country_code: "CO-VAL-ROL622",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Roldanillo",
    city_code: "622",
    code: "76622",
  },
  {
    id: 1037,
    country_name: "CO-VAL-SAN670",
    country_code: "CO-VAL-SAN670",
    state_name: "Valle del Cauca",
    state_code: "76",
    city_name: "San Pedro",
    city_code: "670",
    code: "76670",
  },
  {
    id: 1038,
    country_name: "CO-VAL-SEV736",
    country_code: "CO-VAL-SEV736",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Sevilla",
    city_code: "736",
    code: "76736",
  },
  {
    id: 1039,
    country_name: "CO-VAL-TOR823",
    country_code: "CO-VAL-TOR823",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Toro",
    city_code: "823",
    code: "76823",
  },
  {
    id: 1040,
    country_name: "CO-VAL-TRU828",
    country_code: "CO-VAL-TRU828",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Trujillo",
    city_code: "828",
    code: "76828",
  },
  {
    id: 1041,
    country_name: "CO-VAL-TUL834",
    country_code: "CO-VAL-TUL834",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Tuluá",
    city_code: "834",
    code: "76834",
  },
  {
    id: 1042,
    country_name: "CO-VAL-ULL845",
    country_code: "CO-VAL-ULL845",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Ulloa",
    city_code: "845",
    code: "76845",
  },
  {
    id: 1043,
    country_name: "CO-VAL-VER863",
    country_code: "CO-VAL-VER863",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Versalles",
    city_code: "863",
    code: "76863",
  },
  {
    id: 1044,
    country_name: "CO-VAL-VIJ869",
    country_code: "CO-VAL-VIJ869",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Vijes",
    city_code: "869",
    code: "76869",
  },
  {
    id: 1045,
    country_name: "CO-VAL-YOT890",
    country_code: "CO-VAL-YOT890",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Yotoco",
    city_code: "890",
    code: "76890",
  },
  {
    id: 1046,
    country_name: "CO-VAL-YUM892",
    country_code: "CO-VAL-YUM892",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Yumbo",
    city_code: "892",
    code: "76892",
  },
  {
    id: 1047,
    country_name: "CO-VAL-ZAR895",
    country_code: "CO-VAL-ZAR895",
    state_name: "Valle Del Cauca",
    state_code: "76",
    city_name: "Zarzal",
    city_code: "895",
    code: "76895",
  },
  {
    id: 1048,
    country_name: "CO-ARA-ARA1",
    country_code: "CO-ARA-ARA1",
    state_name: "Arauca",
    state_code: "81",
    city_name: "Arauca",
    city_code: "1",
    code: "81001",
  },
  {
    id: 1049,
    country_name: "CO-ARA-ARA65",
    country_code: "CO-ARA-ARA65",
    state_name: "Arauca",
    state_code: "81",
    city_name: "Arauquita",
    city_code: "65",
    code: "81065",
  },
  {
    id: 1050,
    country_name: "CO-ARA-CRA220",
    country_code: "CO-ARA-CRA220",
    state_name: "Arauca",
    state_code: "81",
    city_name: "Cravo Norte",
    city_code: "220",
    code: "81220",
  },
  {
    id: 1051,
    country_name: "CO-ARA-FOR300",
    country_code: "CO-ARA-FOR300",
    state_name: "Arauca",
    state_code: "81",
    city_name: "Fortul",
    city_code: "300",
    code: "81300",
  },
  {
    id: 1052,
    country_name: "CO-ARA-PUE591",
    country_code: "CO-ARA-PUE591",
    state_name: "Arauca",
    state_code: "81",
    city_name: "Puerto Rondón",
    city_code: "591",
    code: "81591",
  },
  {
    id: 1053,
    country_name: "CO-ARA-SAR736",
    country_code: "CO-ARA-SAR736",
    state_name: "Arauca",
    state_code: "81",
    city_name: "Saravena",
    city_code: "736",
    code: "81736",
  },
  {
    id: 1054,
    country_name: "CO-ARA-TAM794",
    country_code: "CO-ARA-TAM794",
    state_name: "Arauca",
    state_code: "81",
    city_name: "Tame",
    city_code: "794",
    code: "81794",
  },
  {
    id: 1055,
    country_name: "CO-CAS-YOP1",
    country_code: "CO-CAS-YOP1",
    state_name: "Casanare",
    state_code: "85",
    city_name: "Yopal",
    city_code: "1",
    code: "85001",
  },
  {
    id: 1056,
    country_name: "CO-CAS-AGU10",
    country_code: "CO-CAS-AGU10",
    state_name: "Casanare",
    state_code: "85",
    city_name: "Aguazul",
    city_code: "10",
    code: "85010",
  },
  {
    id: 1057,
    country_name: "CO-CAS-CHA15",
    country_code: "CO-CAS-CHA15",
    state_name: "Casanare",
    state_code: "85",
    city_name: "Chámeza",
    city_code: "15",
    code: "85015",
  },
  {
    id: 1058,
    country_name: "CO-CAS-HAT125",
    country_code: "CO-CAS-HAT125",
    state_name: "Casanare",
    state_code: "85",
    city_name: "Hato Corozal",
    city_code: "125",
    code: "85125",
  },
  {
    id: 1059,
    country_name: "CO-CAS-LA 136",
    country_code: "CO-CAS-LA 136",
    state_name: "Casanare",
    state_code: "85",
    city_name: "La Salina",
    city_code: "136",
    code: "85136",
  },
  {
    id: 1060,
    country_name: "CO-CAS-MAN139",
    country_code: "CO-CAS-MAN139",
    state_name: "Casanare",
    state_code: "85",
    city_name: "Maní",
    city_code: "139",
    code: "85139",
  },
  {
    id: 1061,
    country_name: "CO-CAS-MON162",
    country_code: "CO-CAS-MON162",
    state_name: "Casanare",
    state_code: "85",
    city_name: "Monterrey",
    city_code: "162",
    code: "85162",
  },
  {
    id: 1062,
    country_name: "CO-CAS-NUN225",
    country_code: "CO-CAS-NUN225",
    state_name: "Casanare",
    state_code: "85",
    city_name: "Nunchía",
    city_code: "225",
    code: "85225",
  },
  {
    id: 1063,
    country_name: "CO-CAS-ORO230",
    country_code: "CO-CAS-ORO230",
    state_name: "Casanare",
    state_code: "85",
    city_name: "Orocué",
    city_code: "230",
    code: "85230",
  },
  {
    id: 1064,
    country_name: "CO-CAS-PAZ250",
    country_code: "CO-CAS-PAZ250",
    state_name: "Casanare",
    state_code: "85",
    city_name: "Paz De Ariporo",
    city_code: "250",
    code: "85250",
  },
  {
    id: 1065,
    country_name: "CO-CAS-POR263",
    country_code: "CO-CAS-POR263",
    state_name: "Casanare",
    state_code: "85",
    city_name: "Pore",
    city_code: "263",
    code: "85263",
  },
  {
    id: 1066,
    country_name: "CO-CAS-REC279",
    country_code: "CO-CAS-REC279",
    state_name: "Casanare",
    state_code: "85",
    city_name: "Recetor",
    city_code: "279",
    code: "85279",
  },
  {
    id: 1067,
    country_name: "CO-CAS-SAB300",
    country_code: "CO-CAS-SAB300",
    state_name: "Casanare",
    state_code: "85",
    city_name: "Sabanalarga",
    city_code: "300",
    code: "85300",
  },
  {
    id: 1068,
    country_name: "CO-CAS-SAC315",
    country_code: "CO-CAS-SAC315",
    state_name: "Casanare",
    state_code: "85",
    city_name: "Sácama",
    city_code: "315",
    code: "85315",
  },
  {
    id: 1069,
    country_name: "CO-CAS-SAN325",
    country_code: "CO-CAS-SAN325",
    state_name: "Casanare",
    state_code: "85",
    city_name: "San Luis De Palenque",
    city_code: "325",
    code: "85325",
  },
  {
    id: 1070,
    country_name: "CO-CAS-TAM400",
    country_code: "CO-CAS-TAM400",
    state_name: "Casanare",
    state_code: "85",
    city_name: "Támara",
    city_code: "400",
    code: "85400",
  },
  {
    id: 1071,
    country_name: "CO-CAS-TAU410",
    country_code: "CO-CAS-TAU410",
    state_name: "Casanare",
    state_code: "85",
    city_name: "Tauramena",
    city_code: "410",
    code: "85410",
  },
  {
    id: 1072,
    country_name: "CO-CAS-TRI430",
    country_code: "CO-CAS-TRI430",
    state_name: "Casanare",
    state_code: "85",
    city_name: "Trinidad",
    city_code: "430",
    code: "85430",
  },
  {
    id: 1073,
    country_name: "CO-CAS-VIL440",
    country_code: "CO-CAS-VIL440",
    state_name: "Casanare",
    state_code: "85",
    city_name: "Villanueva",
    city_code: "440",
    code: "85440",
  },
  {
    id: 1074,
    country_name: "CO-PUT-MOC1",
    country_code: "CO-PUT-MOC1",
    state_name: "Putumayo",
    state_code: "86",
    city_name: "Mocoa",
    city_code: "1",
    code: "86001",
  },
  {
    id: 1075,
    country_name: "CO-PUT-COL219",
    country_code: "CO-PUT-COL219",
    state_name: "Putumayo",
    state_code: "86",
    city_name: "Colón",
    city_code: "219",
    code: "86219",
  },
  {
    id: 1076,
    country_name: "CO-PUT-ORI320",
    country_code: "CO-PUT-ORI320",
    state_name: "Putumayo",
    state_code: "86",
    city_name: "Orito",
    city_code: "320",
    code: "86320",
  },
  {
    id: 1077,
    country_name: "CO-PUT-PUE568",
    country_code: "CO-PUT-PUE568",
    state_name: "Putumayo",
    state_code: "86",
    city_name: "Puerto Asís",
    city_code: "568",
    code: "86568",
  },
  {
    id: 1078,
    country_name: "CO-PUT-PUE569",
    country_code: "CO-PUT-PUE569",
    state_name: "Putumayo",
    state_code: "86",
    city_name: "Puerto Caicedo",
    city_code: "569",
    code: "86569",
  },
  {
    id: 1079,
    country_name: "CO-PUT-PUE571",
    country_code: "CO-PUT-PUE571",
    state_name: "Putumayo",
    state_code: "86",
    city_name: "Puerto Guzmán",
    city_code: "571",
    code: "86571",
  },
  {
    id: 1080,
    country_name: "CO-PUT-LEG573",
    country_code: "CO-PUT-LEG573",
    state_name: "Putumayo",
    state_code: "86",
    city_name: "Puerto Leguízamo",
    city_code: "573",
    code: "86573",
  },
  {
    id: 1081,
    country_name: "CO-PUT-SIB749",
    country_code: "CO-PUT-SIB749",
    state_name: "Putumayo",
    state_code: "86",
    city_name: "Sibundoy",
    city_code: "749",
    code: "86749",
  },
  {
    id: 1082,
    country_name: "CO-PUT-SAN755",
    country_code: "CO-PUT-SAN755",
    state_name: "Putumayo",
    state_code: "86",
    city_name: "San Francisco",
    city_code: "755",
    code: "86755",
  },
  {
    id: 1083,
    country_name: "CO-PUT-SAN757",
    country_code: "CO-PUT-SAN757",
    state_name: "Putumayo",
    state_code: "86",
    city_name: "San Miguel",
    city_code: "757",
    code: "86757",
  },
  {
    id: 1084,
    country_name: "CO-PUT-SAN760",
    country_code: "CO-PUT-SAN760",
    state_name: "Putumayo",
    state_code: "86",
    city_name: "Santiago",
    city_code: "760",
    code: "86760",
  },
  {
    id: 1085,
    country_name: "CO-PUT-VAL865",
    country_code: "CO-PUT-VAL865",
    state_name: "Putumayo",
    state_code: "86",
    city_name: "Valle Del Guamuez",
    city_code: "865",
    code: "86865",
  },
  {
    id: 1086,
    country_name: "CO-PUT-VIL885",
    country_code: "CO-PUT-VIL885",
    state_name: "Putumayo",
    state_code: "86",
    city_name: "Villagarzón",
    city_code: "885",
    code: "86885",
  },
  {
    id: 1087,
    country_name: "CO-SAN-SAN1",
    country_code: "CO-SAN-SAN1",
    state_name: "San Andrés",
    state_code: "88",
    city_name: "San Andrés",
    city_code: "1",
    code: "88001",
  },
  {
    id: 1088,
    country_name: "CO-SAN-PRO564",
    country_code: "CO-SAN-PRO564",
    state_name: "San Andrés",
    state_code: "88",
    city_name: "Providencia",
    city_code: "564",
    code: "88564",
  },
  {
    id: 1089,
    country_name: "CO-AMA-LET1",
    country_code: "CO-AMA-LET1",
    state_name: "Amazonas",
    state_code: "91",
    city_name: "Leticia",
    city_code: "1",
    code: "91001",
  },
  {
    id: 1090,
    country_name: "CO-AMA-EL 263",
    country_code: "CO-AMA-EL 263",
    state_name: "Amazonas",
    state_code: "91",
    city_name: "El Encanto",
    city_code: "263",
    code: "91263",
  },
  {
    id: 1091,
    country_name: "CO-AMA-LA 405",
    country_code: "CO-AMA-LA 405",
    state_name: "Amazonas",
    state_code: "91",
    city_name: "La Chorrera",
    city_code: "405",
    code: "91405",
  },
  {
    id: 1092,
    country_name: "CO-AMA-LA 407",
    country_code: "CO-AMA-LA 407",
    state_name: "Amazonas",
    state_code: "91",
    city_name: "La Pedrera",
    city_code: "407",
    code: "91407",
  },
  {
    id: 1093,
    country_name: "CO-AMA-LA 430",
    country_code: "CO-AMA-LA 430",
    state_name: "Amazonas",
    state_code: "91",
    city_name: "La Victoría",
    city_code: "430",
    code: "91430",
  },
  {
    id: 1094,
    country_name: "CO-AMA-MIR460",
    country_code: "CO-AMA-MIR460",
    state_name: "Amazonas",
    state_code: "91",
    city_name: "Mirití - Paraná",
    city_code: "460",
    code: "91460",
  },
  {
    id: 1095,
    country_name: "CO-AMA-PUE530",
    country_code: "CO-AMA-PUE530",
    state_name: "Amazonas",
    state_code: "91",
    city_name: "Puerto Alegría",
    city_code: "530",
    code: "91530",
  },
  {
    id: 1096,
    country_name: "CO-AMA-PUE536",
    country_code: "CO-AMA-PUE536",
    state_name: "Amazonas",
    state_code: "91",
    city_name: "Puerto Arica",
    city_code: "536",
    code: "91536",
  },
  {
    id: 1097,
    country_name: "CO-AMA-PUE540",
    country_code: "CO-AMA-PUE540",
    state_name: "Amazonas",
    state_code: "91",
    city_name: "Puerto Nariño",
    city_code: "540",
    code: "91540",
  },
  {
    id: 1098,
    country_name: "CO-AMA-PUE669",
    country_code: "CO-AMA-PUE669",
    state_name: "Amazonas",
    state_code: "91",
    city_name: "Puerto Santander",
    city_code: "669",
    code: "91669",
  },
  {
    id: 1099,
    country_name: "CO-AMA-TAR798",
    country_code: "CO-AMA-TAR798",
    state_name: "Amazonas",
    state_code: "91",
    city_name: "Tarapacá",
    city_code: "798",
    code: "91798",
  },
  {
    id: 1100,
    country_name: "CO-GUA-INI1",
    country_code: "CO-GUA-INI1",
    state_name: "Guainía",
    state_code: "94",
    city_name: "Inírida",
    city_code: "1",
    code: "94001",
  },
  {
    id: 1101,
    country_name: "CO-GUA-BAR343",
    country_code: "CO-GUA-BAR343",
    state_name: "Guainía",
    state_code: "94",
    city_name: "Barranco Minas",
    city_code: "343",
    code: "94343",
  },
  {
    id: 1102,
    country_name: "CO-GUA-MAP663",
    country_code: "CO-GUA-MAP663",
    state_name: "Guainía",
    state_code: "94",
    city_name: "Mapiripana",
    city_code: "663",
    code: "94663",
  },
  {
    id: 1103,
    country_name: "CO-GUA-SAN883",
    country_code: "CO-GUA-SAN883",
    state_name: "Guainía",
    state_code: "94",
    city_name: "San Felipe",
    city_code: "883",
    code: "94883",
  },
  {
    id: 1104,
    country_name: "CO-GUA-PUE884",
    country_code: "CO-GUA-PUE884",
    state_name: "Guainía",
    state_code: "94",
    city_name: "Puerto Colombia",
    city_code: "884",
    code: "94884",
  },
  {
    id: 1105,
    country_name: "CO-GUA-LA 885",
    country_code: "CO-GUA-LA 885",
    state_name: "Guainía",
    state_code: "94",
    city_name: "La Guadalupe",
    city_code: "885",
    code: "94885",
  },
  {
    id: 1106,
    country_name: "CO-GUA-CAC886",
    country_code: "CO-GUA-CAC886",
    state_name: "Guainía",
    state_code: "94",
    city_name: "Cacahual",
    city_code: "886",
    code: "94886",
  },
  {
    id: 1107,
    country_name: "CO-GUA-PAN887",
    country_code: "CO-GUA-PAN887",
    state_name: "Guainía",
    state_code: "94",
    city_name: "Pana Pana",
    city_code: "887",
    code: "94887",
  },
  {
    id: 1108,
    country_name: "CO-GUA-MOR888",
    country_code: "CO-GUA-MOR888",
    state_name: "Guainía",
    state_code: "94",
    city_name: "Morichal",
    city_code: "888",
    code: "94888",
  },
  {
    id: 1109,
    country_name: "CO-GUA-SAN1",
    country_code: "CO-GUA-SAN1",
    state_name: "Guaviare",
    state_code: "95",
    city_name: "San José del Guaviare",
    city_code: "1",
    code: "95001",
  },
  {
    id: 1110,
    country_name: "CO-GUA-CAL15",
    country_code: "CO-GUA-CAL15",
    state_name: "Guaviare",
    state_code: "95",
    city_name: "Calamar",
    city_code: "15",
    code: "95015",
  },
  {
    id: 1111,
    country_name: "CO-GUA-EL 25",
    country_code: "CO-GUA-EL 25",
    state_name: "Guaviare",
    state_code: "95",
    city_name: "El Retorno",
    city_code: "25",
    code: "95025",
  },
  {
    id: 1112,
    country_name: "CO-GUA-MIR200",
    country_code: "CO-GUA-MIR200",
    state_name: "Guaviare",
    state_code: "95",
    city_name: "Miraflores",
    city_code: "200",
    code: "95200",
  },
  {
    id: 1113,
    country_name: "CO-VAU-MIT1",
    country_code: "CO-VAU-MIT1",
    state_name: "Vaupés",
    state_code: "97",
    city_name: "Mitú",
    city_code: "1",
    code: "97001",
  },
  {
    id: 1114,
    country_name: "CO-VAU-CAR161",
    country_code: "CO-VAU-CAR161",
    state_name: "Vaupés",
    state_code: "97",
    city_name: "Carurú",
    city_code: "161",
    code: "97161",
  },
  {
    id: 1115,
    country_name: "CO-VAU-PAC511",
    country_code: "CO-VAU-PAC511",
    state_name: "Vaupés",
    state_code: "97",
    city_name: "Pacoa",
    city_code: "511",
    code: "97511",
  },
  {
    id: 1116,
    country_name: "CO-VAU-TAR666",
    country_code: "CO-VAU-TAR666",
    state_name: "Vaupés",
    state_code: "97",
    city_name: "Taraira",
    city_code: "666",
    code: "97666",
  },
  {
    id: 1117,
    country_name: "CO-VAU-PAP777",
    country_code: "CO-VAU-PAP777",
    state_name: "Vaupés",
    state_code: "97",
    city_name: "Papunaua",
    city_code: "777",
    code: "97777",
  },
  {
    id: 1118,
    country_name: "CO-VAU-YAV889",
    country_code: "CO-VAU-YAV889",
    state_name: "Vaupés",
    state_code: "97",
    city_name: "Yavaraté",
    city_code: "889",
    code: "97889",
  },
  {
    id: 1119,
    country_name: "CO-VIC-PUE1",
    country_code: "CO-VIC-PUE1",
    state_name: "Vichada",
    state_code: "99",
    city_name: "Puerto Carreño",
    city_code: "1",
    code: "99001",
  },
  {
    id: 1120,
    country_name: "CO-VIC-LA 524",
    country_code: "CO-VIC-LA 524",
    state_name: "Vichada",
    state_code: "99",
    city_name: "La Primavera",
    city_code: "524",
    code: "99524",
  },
  {
    id: 1121,
    country_name: "CO-VIC-SAN624",
    country_code: "CO-VIC-SAN624",
    state_name: "Vichada",
    state_code: "99",
    city_name: "Santa Rosalía",
    city_code: "624",
    code: "99624",
  },
  {
    id: 1122,
    country_name: "CO-VIC-CUM773",
    country_code: "CO-VIC-CUM773",
    state_name: "Vichada",
    state_code: "99",
    city_name: "Cumaribo",
    city_code: "773",
    code: "99773",
  },
  {
    id: 1123,
    country_name: "CO-CUN-BOG1",
    country_code: "CO-CUN-BOG1",
    state_name: "Bogotá",
    state_code: "11",
    city_name: "Bogotá D.C.",
    city_code: "1",
    code: "11001",
  },
];
console.log(CitiesRes);
export default CitiesRes;
