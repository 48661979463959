import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import useRecaptcha from "../../Hooks/useReCaptchaV2";
import { ContextData } from "../../Context/Context";
import fetch_retry from "../../Utils/Fetch/RetryFetch";
import Swal from "sweetalert2";

const url_verify =
  "https://apipro.agentemotor.com/seguros/utils/v1/captcha-verify";

export default function ReCaptchaV2() {
  const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha();
  const { setContext } = React.useContext(ContextData);

  useEffect(() => {
    if (!capchaToken) return;

    const verifyToken = async (token) => {
      try {
        var raw = JSON.stringify({
          body: {
            token: token,
            version: "v2",
          },
        });

        const response = await fetch_retry(
          url_verify,
          {
            method: "POST",
            body: raw,
          },
          3
        );
        const json = await response.json();
        if (json.success) {
          setContext((context) => {
            return {
              ...context,
              captchaV2Verified: true,
            };
          });
          return;
        }
      } catch (error) {
        console.error("Error verifying captcha token", error);
      }
      Swal.fire({
        title: "Oops...",
        text: "Hubo un error en la validación. Por favor, asegúrate de que no eres un robot y vuelve a intentarlo.",
        icon: "error",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "var(--company-color)",
      });
      //   .then(() => {
      //     window.location.reload();
      //   });
    };

    verifyToken(capchaToken);
  }, [capchaToken, setContext]);

  return (
    <div>
      <Row className="justify-content-center">
        <Col md={"auto"}>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LdsNMUqAAAAAJ1kC-wB937QElkPpJirGoLqfdfc"
            onChange={handleRecaptcha}
          />
        </Col>
      </Row>
    </div>
  );
}
